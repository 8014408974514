import React from 'react';

interface ImageProps {
    width?: string;
    height?: string;
    src: string;
    alt?: string;
    customStyle?: string;
}

export const Images: React.FunctionComponent<ImageProps> = (props) => {
    return (
        <div className={` ${props.customStyle}`}>
            <img
                className={`${props?.width || 'w-5'} ${props?.height || 'h-full'}`}
                src={`${props?.src}`}
                alt={`${props?.alt}` || ''}
            />
        </div>
    );
};
