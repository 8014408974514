export function getOrderedCategories(categoryOrder, categories) {
    if (categoryOrder) {
        const categoryOrderList = categoryOrder?.split(',').map(Number);
        const ordered = categoryOrderList?.reduce((r, v, i) => ((r[v] = i), r), {});
        const orderedCategories = categories
            ?.slice()
            .sort((a, b) => ordered[a['id']] - ordered[b['id']]);

        const sortedCategories = orderedCategories.filter((x) =>
            categoryOrderList.includes(Number(x.id)),
        );
        const unsortedCategories = orderedCategories.filter(
            (x) => !categoryOrderList.includes(Number(x.id)),
        );
        if (unsortedCategories) {
            unsortedCategories.sort((a, b) => {
                return Number(a.id) - Number(b.id);
            });
            return unsortedCategories.concat(sortedCategories);
        }

        return orderedCategories;
    } else {
        return categories;
    }
}

export function getCategoryOrderString(categories) {
    if (categories) {
        const result = categories
            ?.map((category) => {
                return category?.id;
            })
            .join(',');
        return result;
    }
}
