/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useState } from 'react';
import S3 from 'react-aws-s3';
import { s3Config } from '../../../constants/config/constants';

import ImageCropModal from '../../../utils/ImageCropper';
import { Images } from '../../ui/atoms/Images';
import NormalLoader from '../../../utils/normalLoader';

interface Props {
    isEdit: boolean;
    width?: string;
    height: string;
    placeholder?: string;
    isThumbNail?: boolean;
    inputId?: string;
    setImage?: any;
    imageProp?: any;
    disabled?: boolean;
    isThumbnailImage?: boolean;
}

export default function UploadCategoryImage({
    isEdit,
    width,
    height,
    placeholder,
    isThumbNail,
    inputId,
    setImage,
    imageProp,
    disabled,
    isThumbnailImage,
}: Props) {
    const [imageError, setImageError] = useState<string>('');
    const [cropModalVisible, setCropModalVisible] = useState(false);
    const [zoom, setZoom] = useState(1);
    const newFileName = Date.now().toString();
    const [hover, setHover] = useState(false);
    const [categoryImage, setCategoryImage] = useState<File>(null);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [imageSrch, setImageSrc] = useState<string>('');
    const [isUploading, setIsUploading] = useState(false);
    const [aspectRatioHeight, setAspectRatioHeight] = useState(0);
    const [aspectRatioWidth, setAspectRatioWidth] = useState(0);
    const [productLocation, setProductImageLocation] = useState('');

    const uploadImageToS3 = async (resultedImage: any) => {
        setIsUploading(true);
        s3Config.dirName = `product/${newFileName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(resultedImage[0], newFileName)
            .then((data) => {
                const locationUrl = data?.location?.replace(/\+/, '%2B');
                setProductImageLocation(
                    resultedImage[0].type === 'image/svg+xml' ? locationUrl : data?.location,
                );
                setImage(resultedImage[0].type === 'image/svg+xml' ? locationUrl : data?.location);
                setIsUploading(false);
            })
            .catch((err) => {
                console.error(err);
                setIsUploading(false);
            });
    };

    const handleImage = (e) => {
        const _URL = window.URL || window.webkitURL;
        let file, img;
        if ((file = e.target.files[0])) {
            img = new Image();
            img.onload = function () {
                const width = this.naturalWidth;
                const height = this.naturalHeight;
                setAspectRatioHeight(height);
                setAspectRatioWidth(width);
                console.log(
                    'setting image dimensions to natural width: ' + width + ' height: ' + height,
                );

                // svg images are scalable by definition and therefore checking for image resolution doesn't make sense
                if (file.type === 'image/svg+xml' || (width <= 2048 && height <= 2048)) {
                    setCategoryImage(file);

                    const newFile = new File([file], file.name, {
                        lastModified: file.lastModified,
                        type: file.type,
                    });

                    uploadImageToS3([newFile]);
                    setZoom(1);

                    setImageError(null);
                } else {
                    setImageError('Image resolution is too bigger');
                }
            };
            img.onerror = function () {
                setImageError('Not a valid file: ' + file.type);
            };
            img.src = _URL.createObjectURL(file);
        }
    };

    const handleImageInput = (image) => {
        return (
            <>
                <input
                    className={`invisible focus:outline-none w-full pb-3 border-b-2 pl-2 focus:border-blue-900`}
                    id={inputId}
                    type="file"
                    accept={isThumbnailImage ? '.svg' : 'image/png, image/jpeg, .svg'}
                    disabled={disabled ? true : false}
                    onChange={(e) => {
                        handleImage(e);
                        e.target.value = null;
                    }}
                />
                <label
                    className=" justify-center cursor-pointer underline  flex"
                    htmlFor={isEdit ? inputId : null}
                >
                    {image && hover ? (
                        <>
                            <div className=" z-20 -mt-16 font-light text-gray-600 opacity-100 ">
                                {disabled ? null : (
                                    <div
                                        className={`underline font-poppins text-purple-500 mt-2 text-lg`}
                                    >
                                        Change Image
                                    </div>
                                )}
                            </div>
                        </>
                    ) : null}
                </label>
            </>
        );
    };

    const handleProfilePic = () => {
        if (productLocation) {
            return (
                <>
                    <div className={`${width} ${' '} ${height}  rounded-lg flex`}>
                        <div
                            className="text-center flex flex-col w-full"
                            onMouseOver={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <div className={`m-auto ${width} ${' '} ${height}`}>
                                {isUploading ? (
                                    <div className="m-auto mx-auto flex flex-col w-full">
                                        <NormalLoader />
                                    </div>
                                ) : (
                                    <>
                                        <img
                                            className={`object-contain w-full h-full ${
                                                hover ? ' opacity-100 ' : null
                                            } inset-0 bg-cover bg-center z-0`}
                                            src={productLocation}
                                            alt={'product'}
                                        />
                                        {handleImageInput(productLocation)}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (!categoryImage && imageProp) {
            return (
                <>
                    <div className={`${width} ${' '}  ${height}  rounded-lg flex`}>
                        <div
                            className="text-center flex flex-col w-full"
                            onMouseOver={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <div className="m-auto">
                                {isUploading ? (
                                    <div className="m-auto mx-auto flex flex-col w-full">
                                        <NormalLoader />
                                    </div>
                                ) : (
                                    <>
                                        <img
                                            className={`object-contain rounded-lg inset-0 bg-cover bg-center z-0 ${height}`}
                                            src={imageProp}
                                            alt={'product'}
                                        />
                                        {handleImageInput(imageProp)}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (imageProp) {
            return (
                <>
                    <div className={`${width} ${' '}  ${height}  rounded-lg flex`}>
                        <div
                            className="text-center flex flex-col w-full"
                            onMouseOver={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <div className="m-auto">
                                {isUploading ? (
                                    <div className="m-auto mx-auto flex flex-col w-full">
                                        <NormalLoader />
                                    </div>
                                ) : (
                                    <>
                                        <img
                                            className=" object-contain rounded-lg inset-0 bg-cover bg-center z-0"
                                            src={imageProp}
                                            alt={'product'}
                                        />
                                        {handleImageInput(imageProp)}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div
                        className={`${width} ${
                            disabled && !imageProp ? 'bg-gray-100' : 'bg-gray-100'
                        } ${height}  rounded-lg flex`}
                    >
                        <div className="text-center flex flex-col w-full m-auto">
                            {isUploading ? (
                                <div className="m-auto mx-auto flex flex-col w-full">
                                    <NormalLoader />
                                </div>
                            ) : (
                                <>
                                    {!disabled ? (
                                        <>
                                            <div className={`m-auto`}>
                                                <Images
                                                    width={`'w-20`}
                                                    height={`'h-20`}
                                                    src="/images/icons/upload.png"
                                                    alt="upload"
                                                />
                                            </div>
                                            {placeholder ? (
                                                <div className=" font-light text-gray-600  text-lg">
                                                    {placeholder}
                                                </div>
                                            ) : null}
                                            <label
                                                className=" justify-center cursor-pointer underline flex"
                                                htmlFor={isEdit ? inputId : null}
                                            >
                                                <div className="flex flex-col">
                                                    <div
                                                        className={`'text-lg' font-light text-purple-600  text-lg`}
                                                    >
                                                        Upload Image
                                                    </div>
                                                </div>
                                            </label>
                                        </>
                                    ) : (
                                        'No image added'
                                    )}

                                    <input
                                        className={`invisible focus:outline-none w-full pb-3 border-b-2 pl-2 focus:border-blue-900`}
                                        id={inputId}
                                        type="file"
                                        accept={
                                            isThumbnailImage
                                                ? '.svg'
                                                : 'image/png, image/jpeg, .svg'
                                        }
                                        disabled={disabled ? true : false}
                                        onChange={(e) => {
                                            handleImage(e);
                                            e.target.value = null;
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </>
            );
        }
    };

    return (
        <div className="">
            <div className="flex flex-row">{handleProfilePic()}</div>

            {cropModalVisible ? (
                <>
                    {isThumbNail ? (
                        <ImageCropModal
                            modalVisible={cropModalVisible}
                            setModalVisible={setCropModalVisible}
                            uploadImageToS3={uploadImageToS3}
                            setImageUrl={setImageSrc}
                            uploadedImageUrl={categoryImage && URL.createObjectURL(categoryImage)}
                            uploadedImage={categoryImage}
                            title="Crop image and upload"
                            btnText="Set a thumbnail image"
                            zoom={zoom}
                            cropRect={true}
                            setZoom={setZoom}
                            aspectRatio={aspectRatioWidth / aspectRatioHeight}
                        />
                    ) : (
                        <ImageCropModal
                            modalVisible={cropModalVisible}
                            setModalVisible={setCropModalVisible}
                            uploadImageToS3={uploadImageToS3}
                            setImageUrl={setImageSrc}
                            uploadedImageUrl={categoryImage && URL.createObjectURL(categoryImage)}
                            uploadedImage={categoryImage}
                            title="Crop image and upload"
                            btnText="Set a category image"
                            zoom={zoom}
                            cropRect={true}
                            setZoom={setZoom}
                            aspectRatio={aspectRatioWidth / aspectRatioHeight}
                        />
                    )}
                </>
            ) : null}
            <div className="text-red-400 flex justify-center -ml-4">{imageError}</div>
        </div>
    );
}
