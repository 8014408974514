import { SET_ORDER_LIST_SORT_STATE } from '../../enums/redux/redux-enum';

export interface orderListSortState {
    sortState: sortStateType;
}

export interface sortStateType {
    sortField: string;
    isColumnSorted: boolean;
}

export const orderListInitialState: orderListSortState = {
    sortState: {
        sortField: 'none',
        isColumnSorted: false,
    },
};

const orderListSortReducer = (state = orderListInitialState, action: any): orderListSortState => {
    switch (action.type) {
        case SET_ORDER_LIST_SORT_STATE:
            return { ...state, sortState: action.payload };
        default:
            return state;
    }
};

export default orderListSortReducer;
