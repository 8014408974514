import { SET_ITEM_ID } from '../../enums/redux/redux-enum';

export interface itemIDState {
    itemID: string | null;
}

export const itemIDInitialState: itemIDState = {
    itemID: null,
};

const itemIDReducer = (state = itemIDInitialState, action: any): itemIDState => {
    switch (action.type) {
        case SET_ITEM_ID:
            return { ...state, itemID: action.payload };
        default:
            return state;
    }
};

export default itemIDReducer;
