interface Props {
    bgColor: any;
    completed?: any;
    text?: any;
}

const ProgressBar = ({ bgColor, completed, text }: Props) => {
    const containerStyles = {
        height: 12,
        width: '100%',
        backgroundColor: '#e0e0de',
        borderRadius: 6,
    };

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgColor,
        borderRadius: 'inherit',
    };

    return (
        <>
            <div className="flex flex-col h-20 w-full pt-4 pb-2 px-4">
                <div className="flex text-xs font-poppins font-medium pb-1">{`${text} ${completed}%`}</div>
                <div className="flex w-full" style={containerStyles}>
                    <div style={fillerStyles}></div>
                </div>
            </div>
        </>
    );
};

export default ProgressBar;
