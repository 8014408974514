export const INSTANT_VIEW = 'INSTANT_VIEW';
export const INSTANT_TRASH = 'INSTANT_TRASH';
export const INSTANT_RESTORE = 'INSTANT_RESTORE';
export const SUCCESS_CSV_UPLOAD = 'Successfully uploaded csv';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const VALIDATION_ERROR = 'There are validation errors, errors are listed below';
export const ERROR_MESSAGE = 'SORRY, SOMETHING WENT WRONG';
export const SUCCESS_CSV_CONFIRM = 'Successfully uploaded the products';
export const ERROR_CSV_UPLOAD_VALIDATION = 'There are draft products, please cancel or confirm it';
export const DELETE = 'Are you sure?';

export const RESTORE_SUPPLIER_TITLE = 'Restore Supplier';

export const RESTORE_SUPPLIER_BODY = 'Do you want to restore this supplier?';

export const DELETE_SUPPLIER_TITLE = 'Trash Supplier';

export const DELETE_SUPPLIER_BODY = 'Are you sure you want to trash this supplier?';

export const ALREADY_DELETED_WARNING_TITLE = 'SUPPLIER IS ALREADY DELETED';

export const UPDATE_SUPPLIER_TITLE = 'Cannot update supplier type';

export const ALREADY_DELETED_WARNING_BODY =
    'The supplier you are trying to delete has already been deleted.';

export const DELETE_PRODUCT_BODY = 'Do you want to delete this product?';

export const DELETE_VOUCHER_BODY = 'Do you want to delete this voucher?';

export const DELETE_VOUCHERS_BODY = 'Do you want to delete these vouchers?';

export const SUCCESS_MESSAGE_DELETE_PRODUCT = 'Successfully deleted the product';

export const SUCCESS_MESSAGE_DELETE_FILE = 'Successfully deleted the file';

export const SUCCESS_MESSAGE_DELETE_VOUCHER = 'Successfully deleted the voucher';

export const DELETE_FILE_BODY = 'Do you want to delete this file?';

export const DELETE_FILES_BODY = 'Do you want to delete these files?';

export const ERROR_CODE_HEADERS = 1026;

export const SUCCESS_MESSAGE_DELETE_PRODUCT_UNPUBLISHED = `The item(s) that attached to this product will be trashed.  \n Are you sure you want to continue? `;

export const SUCCESS_MESSAGE_DELETE_FILE_UNPUBLISHED = `The item(s) that attached to this file will be un-published.  \n Are you sure you want to continue? `;

export const VOUCHER_STATUS_AVAILABLE = 'AVAILABLE';

export const INSTANT_EDIT = 'INSTANT_EDIT';

export const CSV_VOUCHERS = 'CSV_VOUCHERS';

export const ALLSERV_EPIN_API = 'ALLSERV_EPIN_API';

export const CSV = 'CSV';

export const SEAGM = 'SEAGM';

export const UNIPIN = 'UNIPIN';

export const ALLSERV = 'ALLSERV';

export const API = 'API';
export const SUCCESS_MESSAGE_PRODUCT_UPDATE = 'Successfully updated products';

export const getSupplierProductsWarningMessage = (count) => {
    return `There is/are ${count} product/s associated with this supplier and trashing this supplier would trash all those products. Are you sure you want to continue?`;
};

export const getEditSupplierProductsWarningMessage = (count) => {
    return `There is/are ${count} product/s associated with this supplier. You cannot make changes to the supplier type`;
};
