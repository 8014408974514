import { GET_GEO_LOCATION } from './../../enums/redux/redux-enum';
import axios from 'axios';
import { getErrorMessage } from '../../utils/axiosErrorHandler';

export const getGeoLocation = () => async (dispatch: any) => {
    try {
        const URL = process.env.REACT_APP_GEO_LOCATION_API;
        const response = await axios.get(URL);
        dispatch({ type: GET_GEO_LOCATION, payload: response?.data });
    } catch (error) {
        dispatch({ type: GET_GEO_LOCATION, payload: getErrorMessage(error) });
    }
};
