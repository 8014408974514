/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router';
import { Dropdown } from 'semantic-ui-react';
import {
    UPDATE_ADMIN_PROFILE,
    FETCH_ADMIN_ONE_PROFILE,
} from '../../../queries/AdminProfileQueries';
import { RootState } from '../../../redux/rootReducer';
import { countryOptions } from '../../../utils/countries';
import Loader from '../../../utils/loader';
import { Buttons } from '../../ui/atoms/Button';
import { InputField } from '../../ui/atoms/InputField';
import UserAddedSuccessModal from '../../templates/modals/UserAddedSuccessModal';
import { Images } from '../../ui/atoms/Images';
import Confirmation from '../../templates/modals/ConfirmationModalWithMessage';
import {
    USER_ADD_EXIT_CONFIRM_MESSAGE,
    USER_ADD_EXIT_CONFIRM_TITLE,
} from '../../../constants/users';
import UnsaveChangesWarning from '../../templates/modals/UnsaveChangesWarning';
import NormalLoader from '../../../utils/normalLoader';

export default function AddNewUserPersonalDetails() {
    const userDetailsSaved = useRef(null);
    const history = useHistory();
    const [country, setCountry] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [dbLoading, setDbLoading] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [showAddNewUserSuccess, setShowAddNewUserSuccess] = useState(false);
    const [selectedCode, setSelectedCode] = useState(null);
    const [addPersonalDetailsError, setAddPersonalDetailsError] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const [updateUser, { loading: queryLoading, error: queryError }] = useMutation(
        UPDATE_ADMIN_PROFILE,
        {
            refetchQueries: [FETCH_ADMIN_ONE_PROFILE],
            onCompleted() {
                setDbLoading(false);
                userDetailsSaved.current = true;
            },
            onError(error) {
                setAddPersonalDetailsError(true);
            },
        },
    );

    const createdUser = useSelector((state: RootState) => state.auth.createdUser);

    useEffect(() => {
        let code;
        if (country) {
            setCountryError(false);
            code = countryOptions?.filter((countryOption: any) => {
                return countryOption?.text === country;
            });

            setCountryCode(code[0]?.value);
            setSelectedCode(code);
        }
    }, [country]);

    const validationSchema = Yup.object({
        firstName: Yup.string().trim().required('First name is required'),
        lastName: Yup.string().trim().required('Last name is required'),
        phoneNumber: Yup.string()
            .required('Phone number cannot be blank')
            .min(7, 'The number of digits cannot be less than 7')
            .matches(/^[0-9]+$/, 'Enter a valid phone number')
            .max(15, 'The number of digits cannot exceed 15'),
    });
    return (
        <>
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={({ firstName, lastName, phoneNumber }) => {
                    setIsDirty(false);
                    setDbLoading(true);
                    setTimeout(() => {
                        if (createdUser) {
                            setCountryError(false);
                            updateUser({
                                variables: {
                                    userName: createdUser[0].username,
                                    user: {
                                        firstName: firstName,
                                        lastName: lastName,
                                        profilePic: '',
                                        contact: {
                                            primaryEmail: createdUser[0].email,
                                            recoveryEmail: createdUser[0].email,
                                            countryCode: countryCode,
                                            phoneNumber: phoneNumber,
                                        },
                                    },
                                },
                            });
                        }
                        setShowAddNewUserSuccess(true);
                    }, 4000);
                }}
            >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                    <div className="h-full flex flex-col  justify-evens">
                        <div className="text-lg font-poppins font-bold px-8">Personal details</div>
                        <div className="grid grid-cols-8 gap-4 mt-8 px-8">
                            <div className="col-start-1 col-end-4">
                                <InputField
                                    id="firstName"
                                    placeHolder={'John'}
                                    name="First name"
                                    labelWidth="w-21"
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                    }}
                                    value={values.firstName}
                                />
                                {errors.firstName && touched.firstName ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.firstName}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-end-7 col-span-3">
                                <InputField
                                    id="lastName"
                                    placeHolder="Walker"
                                    name=" Last name"
                                    labelWidth="w-21"
                                    value={values.lastName}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                    }}
                                />
                                {errors.lastName && touched.lastName ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.lastName}
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-start-1 col-end-4 mt-1">
                                <div className="flex flex-wrap flex-col relative lg:mb-5 z-50 ">
                                    <div className="text-gray-500 w-28 text-center font-poppins text-sm lg:text-sm h-max bg-white z-50 -mb-3 ml-4 border-opacity-20">
                                        Select country
                                    </div>

                                    <Dropdown
                                        className="dropdownRed"
                                        placeholder="Select Country"
                                        id="countryCode"
                                        fluid
                                        key={'countryCode'}
                                        wrapSelection
                                        selection
                                        onChange={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );

                                            setIsDirty(true);
                                        }}
                                        onKeyDown={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                            setIsDirty(true);
                                        }}
                                        onSearchChange={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                            setIsDirty(true);
                                        }}
                                        onKeyUp={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                            setIsDirty(true);
                                        }}
                                        options={countryOptions}
                                    />
                                </div>
                                {countryError ? (
                                    <div className="text-red-500 text-xs -mt-3 md:text-sm">
                                        {'Country should not be empty'}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-start-1 col-span-1 lg:col-end-5 lg:col-span-1 ">
                                <div className="flex flex-wrap flex-col relative mt-3">
                                    <select
                                        className="p-4 lg:p-3  rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                        disabled={true}
                                        id="code"
                                        value={
                                            selectedCode?.length
                                                ? selectedCode[0]?.dial_value
                                                : null
                                        }
                                    >
                                        {countryOptions.map((country: any, index: any) => {
                                            return (
                                                <option value={country.dial_value} key={index}>
                                                    {country.dial_value}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className=" col-start-3 col-span-4  lg:col-end-7 lg:col-span-2 mt-0.5">
                                <InputField
                                    id="phoneNumber"
                                    placeHolder="412 456 2412"
                                    labelWidth="w-32"
                                    name="Contact number"
                                    value={values?.phoneNumber}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                    }}
                                />
                                {errors.phoneNumber && touched.phoneNumber ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.phoneNumber}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {addPersonalDetailsError && (
                            <div className="flex flex-1 flex-col align-middle items-center justify-center px-8 lg:mt-32 md:mt-28 sm:mt-16">
                                <div className="h-full align-middle my-2">
                                    <Images
                                        width="w-16"
                                        height="h-16"
                                        src="/images/icons/error-svg.svg"
                                        alt="success"
                                    />
                                </div>
                                <div className="h-full align-middle my-4 text-center font-poppins text-base text-red-500">
                                    Oops something went wrong
                                </div>
                                <div
                                    role="presentation"
                                    className="h-full align-middle my-4 text-center font-poppins text-base text-purple-500 underline cursor-pointer"
                                    onClick={() => {
                                        if (!country) setCountryError(true);
                                        handleSubmit();
                                    }}
                                >
                                    Try again
                                </div>
                            </div>
                        )}
                        <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0">
                            <Buttons
                                name="Cancel"
                                type="button"
                                buttonType="secondary-border-black"
                                id="Cancel"
                                size="e-small"
                                other="mr-3"
                                onclick={() => {
                                    history.push('/users');
                                }}
                            />
                            <Buttons
                                name={dbLoading ? <NormalLoader /> : 'Add new user'}
                                type="submit"
                                buttonType="primary"
                                id="update"
                                size="e-small"
                                onclick={() => {
                                    if (!country) setCountryError(true);
                                    handleSubmit();
                                }}
                            />
                        </div>
                        <UserAddedSuccessModal
                            setShowAddNewUserSuccess={setShowAddNewUserSuccess}
                            showAddNewUserSuccess={showAddNewUserSuccess}
                        />
                        <UnsaveChangesWarning
                            contentText={USER_ADD_EXIT_CONFIRM_MESSAGE}
                            messageTitle={USER_ADD_EXIT_CONFIRM_TITLE}
                            when={isDirty || window.onbeforeunload ? true : false}
                            navigate={(path) => history.push(path)}
                            shouldBlockNavigation={(location) => {
                                if (isDirty) {
                                    return true;
                                }
                                return false;
                            }}
                            displayIcon={true}
                            displayIconName={'clarity_error-standard-line-svg.svg'}
                        />
                    </div>
                )}
            </Formik>
        </>
    );
}
