import { gql } from '@apollo/client';

export const SAVE_CSV = gql`
    mutation ($supplierId: ID!, $fileUrl: String, $fileName: String) {
        saveCSVFile(supplierId: $supplierId, fileUrl: $fileUrl, fileName: $fileName) {
            id
            fileUrl
            fileStatus
        }
    }
`;

export const PROCESS_CSV = gql`
    mutation ($fileId: ID!) {
        processCSVFile(fileId: $fileId) {
            id
            publisherName
            voucherCode
            voucherExpiry
            voucherPin
            productName
            voucherSerial
            currency
            costPrice
            additionalData
            totalStock
            availableStock
            sku
            productCode
            productType
        }
    }
`;

export const UPLOAD_CSV = gql`
    mutation ($fileId: ID!) {
        uploadDraftProducts(fileId: $fileId) {
            id
            manufacturerId
        }
    }
`;

export const FETCH_SUPPLIER_FILES = gql`
    query (
        $filter: FilterInput
        $offset: Int!
        $limit: Int!
        $searchText: String
        $sort: String
        $order: SortOrder
    ) {
        csvFiles(
            filter: $filter
            offset: $offset
            limit: $limit
            searchText: $searchText
            sort: $sort
            order: $order
        ) {
            files {
                id
                fileName
                fileUrl
                fileStatus
                createdBy
                createdDate
                totalProducts
                totalVouchers
                availableProducts
                availableVouchers
            }
            currentPage
            totalFiles
            totalPages
        }
    }
`;
