import { gql } from '@apollo/client';

export const FETCH_ADMIN_PROFILE = gql`
    query {
        users {
            userId
            firstName
            lastName
            userName
            createdDate
            isActive
            profilePic
            contact {
                primaryEmail
                recoveryEmail
                countryCode
                phoneNumber
                primaryEmailVerified
            }
        }
    }
`;

export const FETCH_ADMIN_ONE_PROFILE = gql`
    query ($userName: ID!) {
        user(userName: $userName) {
            firstName
            lastName
            userName
            createdDate
            profilePic
            contact {
                primaryEmail
                recoveryEmail
                countryCode
                phoneNumber
                primaryEmailVerified
            }
            isOutOfStockEmailEnable
            isRunningOutOfStockEmailEnable
        }
    }
`;

export const FETCH_ADMIN_ONE_PROFILE_ID = gql`
    query ($userName: ID!) {
        user(userName: $userName) {
            userId
            userName
            firstName
            lastName
            contact {
                primaryEmail
            }
            profilePic
            isActive
            createdDate
            partner {
                partnerId
            }
        }
    }
`;

export const UPDATE_ADMIN_PROFILE = gql`
    mutation ($userName: ID!, $user: UserInput!) {
        updateUser(userName: $userName, user: $user) {
            userId
            userName
            firstName
            lastName
            contact {
                primaryEmail
                recoveryEmail
                countryCode
                phoneNumber
                primaryEmailVerified
            }
            profilePic
            isActive
            createdDate
            partner {
                id
                partnerId
                partnerName
                partnerAgreements {
                    id
                    url
                    fileName
                    fileSize
                    fileType
                    isActive
                }
            }
            isOutOfStockEmailEnable
            isRunningOutOfStockEmailEnable
        }
    }
`;

export const SEND_VERIFICATION_EMAIL = gql`
    mutation ($email: String!) {
        sendEmailVerification(email: $email)
    }
`;

export const CONFIRM_EMAIL = gql`
    query ($token: String!) {
        confirmEmailVerification(token: $token)
    }
`;

export const ACTIVITY_LOG = gql`
    query (
        $userName: ID!
        $filter: ActivityLogsFilterInput
        $offset: Int!
        $limit: Int!
        $sort: String
        $order: SortOrder
    ) {
        userActivityLogs(
            userName: $userName
            filter: $filter
            offset: $offset
            limit: $limit
            sort: $sort
            order: $order
        ) {
            logs {
                activityId
                activityName
                action
                createdDate
            }
            currentPage
            totalActivityLogs
            totalPages
        }
    }
`;

export const SAVE_ACTIVITY_LOGS = gql`
    mutation ($userName: ID!, $activity: ActivityInput!) {
        saveActivity(userName: $userName, activity: $activity) {
            action
        }
    }
`;

export const UPDATE_USER_LOGIN_DATA = gql`
    mutation ($userName: ID, $userLogin: UserLoginInput!) {
        updateUserLogin(userName: $userName, userLogin: $userLogin) {
            sessionId
        }
    }
`;

export const GET_LOGIN_SESSIONS_DATA = gql`
    query ($userName: ID!, $status: UserLoginStatus) {
        userLoginSessions(userName: $userName, status: $status) {
            sessionId
            device
            deviceType
            location
            status
            ipAddress
            createdDate
        }
    }
`;

export const GET_LOGIN_SESSIONS_DATA_BY_ID = gql`
    query ($userName: ID!, $sessionId: String!) {
        userLoginSessionById(userName: $userName, sessionId: $sessionId) {
            sessionId
            device
            deviceType
            location
            status
            createdDate
        }
    }
`;

export const GET_PARTNER_DATA = gql`
    query ($partnerId: ID!) {
        partner(partnerId: $partnerId) {
            id
            partnerId
            partnerName
            partnerUrl
            creditLimit
            isActive
            createdBy
            createdDate
            modifiedBy
            modifiedDate
            partnerAgreements {
                id
                url
                fileName
                fileSize
                fileType
                isActive
            }
            catalog {
                catalogId
                catalogName
                createdBy
                createdDate
            }
        }
    }
`;
