/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { FETCH_PARTNER_DETAILS_BY_ID } from '../../../../queries/PartnerQueries';
import DataTable from '../../../ui/organisms/DataTable';
export default function ViewPartnerAgreement() {
    const partnerId = localStorage.getItem('partnerId');
    const [partnerDataDetails, setPartnerDataDetails] = useState(null);
    const [selectedItem, setSelectedItems] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    function convertToMB(fileSize) {
        return `${(fileSize / (1024 * 1024)).toFixed(2)} MB`;
    }

    const { loading: partnerDataLoading, error: partnerDataFetchError } = useQuery(
        FETCH_PARTNER_DETAILS_BY_ID,
        {
            variables: { partnerId: partnerId },
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                setPartnerDataDetails(data?.partner);
            },
        },
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Name',
                accessor: 'fileName',
            },
            {
                Header: 'File type',
                accessor: 'fileType',
            },
            {
                Header: 'File size',
                accessor: 'fileSize',
                Cell: function size({ value }) {
                    return <span>{convertToMB(value)}</span>;
                },
            },
        ],
        [],
    );
    return (
        <div className="h-full flex flex-col  justify-evens px-8">
            <div className="text-lg font-poppins font-bold mb-5">Agreements</div>
            <div className="border-2 border-gray-300 rounded-lg  mb-10s">
                <div className="w-full">
                    <div className="m-auto flex flex-col">
                        <div className="text-lg font-poppins font-bold py-3 px-2">
                            Uploaded documents
                        </div>
                        <div className="rounded-md">
                            {partnerDataDetails?.partnerAgreements?.length ? (
                                <DataTable
                                    columns={columns}
                                    data={partnerDataDetails?.partnerAgreements}
                                    pageCount={1}
                                    setSelectedItems={setSelectedItems}
                                    setPageIndex={setPageIndex}
                                    setDefaultPageSize={setPageSize}
                                    hidePagination={true}
                                    hideSelection={true}
                                />
                            ) : (
                                <>
                                    <div className="m-auto flex flex-col ">
                                        <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                            {columns?.map((column, i) => {
                                                return (
                                                    <div
                                                        className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                        key={i}
                                                    >
                                                        {column?.Header}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="m-auto p-10">No data found.</div>
                                        <div className="p-10 bg-purple-100"></div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
