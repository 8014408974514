import { useDispatch } from 'react-redux';
import { DEVICE_TYPE } from '../../../enums/device';
import { logoutFromSessions, refreshAuthToken } from '../../../redux/rootActions';
import { Images } from '../atoms/Images';

interface LoginSessionProps {
    ip: string;
    country: string;
    created: string;
    devices: string;
    deviceType: string;
    id: string;
    isCurrent?: boolean;
    setLogout?: (value: boolean) => void;
    setSelectedSession?: (value: any) => void;
}

export const LoginSession: React.FunctionComponent<LoginSessionProps> = (props) => {
    const createdDate = new Date(props?.created).toString();
    const refreshToken = localStorage.getItem('refresh_token');
    const dispatch = useDispatch();
    const getDeviceType = () => {
        if (props.deviceType === DEVICE_TYPE.BROWSER) {
            return (
                <Images
                    height="h-full "
                    width="w-full"
                    src="/images/icons/desktop-svg.svg"
                    alt="pc"
                />
            );
        } else if (props.deviceType === DEVICE_TYPE.MOBILE) {
            return (
                <Images
                    height="h-full"
                    width="w-full"
                    src="/images/icons/mobile-svg.svg"
                    alt="mobile"
                    customStyle="ml-6"
                />
            );
        } else if (props.deviceType === DEVICE_TYPE.TABLET) {
            return (
                <Images
                    height="h-full "
                    width="w-full"
                    src="/images/icons/laptop-svg.svg"
                    alt="laptop"
                    customStyle="-ml-1"
                />
            );
        }
    };
    return (
        <div className="flex justify-between">
            <div className="mb-10 flex">
                <div className="my-auto mx-24">{getDeviceType()}</div>
                <div className="font-poppins text-xs px-2 text-gray-400 flex justify-between">
                    <div className="flex flex-col">
                        <div className="flex mb-3">
                            <div className="mr-3" id="ip_address">
                                IP -{' '}
                            </div>
                            <div className="text-black font-bold">{props.ip}</div>
                        </div>
                        <div className="flex mb-3">
                            <div className="mr-3" id="session_country">
                                Country -{' '}
                            </div>
                            <div className="text-black font-bold">{props.country}</div>
                        </div>
                        <div className="flex mb-3">
                            <div className="mr-3" id="session-created">
                                Created -{' '}
                            </div>
                            <div className="text-black font-bold">{createdDate}</div>
                        </div>
                        <div className="flex mb-3">
                            <div className="mr-3" id="session-devices">
                                Devices -{' '}
                            </div>
                            <div className="text-black font-bold">{props.devices}</div>
                            {props.isCurrent && (
                                <div className="text-green-600 ml-2">Currently</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="my-10 pr-20 underline text-purple-500"
                role="button"
                tabIndex={0}
                id="session-logoutall"
                onClick={() => {
                    props.setLogout(true);
                    props.setSelectedSession(props);
                    setTimeout(() => {
                        dispatch(logoutFromSessions(props?.id));
                        dispatch(refreshAuthToken(refreshToken));
                    }, 5000);
                }}
                onKeyDown={() => {
                    props.setLogout(true);
                    props.setSelectedSession(props);
                    setTimeout(() => {
                        dispatch(logoutFromSessions(props?.id));
                    }, 5000);
                }}
            >
                logout
            </div>
        </div>
    );
};
