import React from 'react';
import { Images } from '../../ui/atoms/Images';

export default function DropDownCustomReportsSort() {
    return (
        <div>
            <div className="border-purple-100 h-10 w-49  rounded-md border-2 bg-purple-100">
                <div className="flex">
                    <div className="p-1 m-auto text-base ml-5 font-poppins">View Weekly</div>
                    <div className="p-3">
                        <Images
                            height="h-full "
                            width="w-full"
                            src="/images/icons/arrowdown.svg"
                            alt="pc"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
