import React from 'react';
import TabItem from '../../../interfaces/ui';
import TextWithCount from '../molecules/TextWithCount';

interface Props {
    tabItemsList: TabItem[];
    selectedTab?: number;
    setSelectedTab?: (value: number) => void;
}

const TabViewWithCounts = ({ tabItemsList, selectedTab, setSelectedTab }: Props) => {
    return (
        <div className="flex flex-row mr-8">
            <div className={`flex mr-0`}>
                {tabItemsList?.map((tabItem, index) => (
                    <div
                        key={tabItem?.tabId}
                        className={`flex relative cursor-pointer border-b-4 ${
                            index === 0 ? 'pr-4' : 'px-6'
                        } py-2 ${
                            selectedTab === tabItem?.tabId ? `border-purple-500` : 'border-snow-500'
                        }`}
                        role="presentation"
                        onClick={() => setSelectedTab(tabItem?.tabId)}
                    >
                        <TextWithCount
                            text={tabItem?.text}
                            count={tabItem?.count}
                            selected={selectedTab === tabItem?.tabId}
                        />
                    </div>
                ))}
            </div>
            <div className={`flex flex-grow mr-0 border-b-4 h-12 lg:h-14`}></div>
        </div>
    );
};

export default TabViewWithCounts;
