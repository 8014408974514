/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Buttons } from '../../ui/atoms/Button';
import { InputField } from '../../ui/atoms/InputField';
import * as Yup from 'yup';
import '../../../styles/mutliselect.css';
import { useHistory } from 'react-router';
import UploadCategoryImage from './UploadCategoryImage';
import { useMutation, useQuery } from '@apollo/client';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { ADD_CATEGORIES, FETCH_CATEGORIES } from '../../../queries/CategoryQueries';
import { ERROR_ADD_CATEGORY } from '../../../constants/category';
import Toast from '../../ui/atoms/Toast';
import { Tooltip } from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { FETCH_CATALOG } from '../../../queries/catalogQueries';
import Multiselect from 'multiselect-react-dropdown';
import NormalLoader from '../../../utils/normalLoader';
import UnsaveChangesWarning from '../../templates/modals/UnsaveChangesWarning';
import {
    PRODUCT_ADD_EXIT_CONFIRM_MESSAGE,
    PRODUCT_ADD_EXIT_CONFIRM_TITLE,
} from '../../../constants/product';
import { useDispatch } from 'react-redux';
import { RESET_SELECTED_ITEMS_DATA_TABLE } from '../../../enums/redux/redux-enum';
import CategoryItemSelectionView from './categoryItems/CategoryItemSelectionView';
import Loader from '../../../utils/loader';

export default function AddNewCategory() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [thumbNail, setThumbNail] = useState('');
    const [addCategoryLoading, setAddCategoryLoading] = useState(false);
    const [catalogError, setCatalogError] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [coverImage, setCoverImage] = useState('');
    const [descriptionLong, setDescriptionLong] = useState('');
    const [categoryData, setCategoryData] = useState([]);
    const [selectedCatalogIds, setSelectedCatalogIds] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showToolTip, setShowToolTip] = useState(false);
    const [catalogData, setCatalogData] = useState([]);
    const [selectedCatalogList, setSelectedCatalogList] = useState([]);
    const [selectedItemsCatalogs, setSelectedItemsCatalogs] = useState([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [previousSelectedItems, setPreviousSelectedItems] = useState([]);
    const [itemCatalogs, setItemCatalogs] = useState([]);

    const { data: categoryQueryData } = useQuery(FETCH_CATEGORIES, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setCategoryData(categoryQueryData?.categories);
        },
    });

    const [saveCategory] = useMutation(ADD_CATEGORIES, {
        context: { clientName: ClientsEnum.STORE },
        refetchQueries: [FETCH_CATEGORIES],
        fetchPolicy: 'network-only',
        onCompleted: () => {
            setTimeout(() => {
                setShowToast(false);
                setAddCategoryLoading(false);
                history.push('/products/categories');
            }, 2000);
        },
        onError: () => {
            setMessage(ERROR_ADD_CATEGORY);
            setShowToast(true);
            setError(true);
        },
    });

    const { loading: catalogLoading } = useQuery(FETCH_CATALOG, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setCatalogData(data?.catalogs?.filter((cat) => cat?.partner?.isActive === true));
        },
    });

    const activeCategories = categoryData?.filter((category) => {
        return category?.isDeleted === false;
    });

    const categoriesDropDownList = activeCategories?.map((category) => ({
        text: category.categoryName,
        value: category.categoryId,
    }));

    const catalogsListMultiple = catalogData?.map((catalog) => ({
        cat: catalog.catalogId,
        key: catalog.catalogName,
    }));

    const selectedCategoryId = categoryData?.filter((category) => {
        if (selectedCategory) {
            return category.categoryId === selectedCategory;
        }
    });

    const validationSchema = Yup.object({
        categoryName: Yup.string().trim().required('Category title is missing'),
        categoryDescription: Yup.string().trim().required('Category description is missing'),
    });

    const onSelectCatalogs = (selectedList) => {
        const addedCatalog = selectedList?.find(
            (selectedItem) => !selectedCatalogIds?.includes(selectedItem.cat),
        );
        const itemCatalogsTemp = [
            ...itemCatalogs.map((itemCatalog) => ({
                ...itemCatalog,
                catalogs: [
                    ...itemCatalog.catalogs,
                    { value: addedCatalog.cat, label: addedCatalog.key },
                ],
            })),
        ];
        setItemCatalogs(itemCatalogsTemp);
        const itemsCatalogsFinalList = [];

        itemCatalogsTemp?.forEach((itemCatalog) => {
            itemCatalog?.catalogs?.forEach((catalog) => {
                itemsCatalogsFinalList?.push({
                    itemId: itemCatalog?.itemId,
                    catalogId: catalog?.value,
                });
            });
        });
        setSelectedItemsCatalogs(itemsCatalogsFinalList);

        const selectedCatalogIdsList = selectedList?.map((list) => list?.cat);
        setSelectedCatalogIds(selectedCatalogIdsList);
    };

    const onRemoveCatalogs = (removedList) => {
        const removedCatalogIds = removedList?.map((list) => list?.cat);
        const onListCatalogs = selectedCatalogIds?.length
            ? selectedCatalogIds?.filter((catalog) =>
                  removedCatalogIds?.some((cat) => {
                      return cat === catalog;
                  }),
              )
            : [];

        const deletedCatalog = selectedCatalogIds?.find(
            (selectedCatalogId) => !onListCatalogs.includes(selectedCatalogId),
        );

        const itemCatalogsTemp = itemCatalogs.map((itemCatalog) => {
            return {
                itemId: itemCatalog.itemId,
                catalogs: itemCatalog.catalogs.filter((catalog) => catalog.value != deletedCatalog),
            };
        });
        setItemCatalogs(itemCatalogsTemp);

        const itemsCatalogsFinalList = [];

        itemCatalogsTemp?.forEach((itemCatalog) => {
            itemCatalog?.catalogs?.forEach((catalog) => {
                itemsCatalogsFinalList?.push({
                    itemId: itemCatalog?.itemId,
                    catalogId: catalog?.value,
                });
            });
        });
        setSelectedItemsCatalogs(itemsCatalogsFinalList);
        setSelectedCatalogIds(onListCatalogs);
    };

    const theme = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    color: 'white',
                    backgroundColor: '#9f7aea',
                },
                arrow: {
                    color: '#9f7aea',
                },
            },
        },
    });

    useEffect(() => {
        if (selectedCatalogIds) {
            const selectedCatalogs = catalogData?.filter((catalog) =>
                selectedCatalogIds?.includes(catalog?.catalogId),
            );
            const resultantCatalogs = selectedCatalogs?.map((catalog) => ({
                value: catalog?.catalogId,
                label: catalog?.catalogName,
            }));
            setSelectedCatalogList(resultantCatalogs);
        }
    }, [selectedCatalogIds, catalogData, setSelectedCatalogList]);

    useEffect(() => {
        if (!catalogData?.length) {
            setSelectedCatalogIds(null);
        }
    }, [catalogData?.length]);

    useEffect(() => {
        setIsDirty(true);
    }, []);

    useEffect(() => {
        if (!selectedCatalogIds?.length && clicked) {
            setCatalogError(true);
        } else if (selectedCatalogIds?.length && clicked) {
            setCatalogError(false);
        } else {
            setCatalogError(false);
        }
    }, [selectedCatalogIds, clicked]);

    useEffect(() => {
        const emptyCatalogs = selectedItemsCatalogs?.filter(
            (catalogItem) => !catalogItem?.catalogs && catalogItem?.catalogs?.length === 0,
        );
        if (!selectedItemsCatalogs?.length && clicked && emptyCatalogs?.length) {
            setError(true);
            setMessage('Product catalogs should not be empty');
            setShowToast(true);

            setClicked(false);

            setTimeout(() => {
                setShowToast(false);
                setError(true);
            }, 4000);
        } else if (emptyCatalogs?.length === 0) {
            setError(false);
        }
    }, [selectedItemsCatalogs, clicked, setError]);

    useEffect(() => {
        return () => {
            dispatch({ type: RESET_SELECTED_ITEMS_DATA_TABLE });
        };
    }, []);

    if (catalogLoading) return <Loader />;

    return (
        <Formik
            initialValues={{
                categoryName: '',
                categoryDescription: '',
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={({ categoryName }) => {
                if (categoryName && descriptionLong && !error) {
                    setAddCategoryLoading(true);
                    const itemsCatalogsFinalList = [];

                    itemCatalogs?.forEach((itemCatalog) => {
                        itemCatalog?.catalogs?.forEach((catalog) => {
                            itemsCatalogsFinalList?.push({
                                itemId: itemCatalog?.itemId,
                                catalogId: catalog?.value,
                            });
                        });
                    });

                    saveCategory({
                        variables: {
                            category: {
                                parentCategory: selectedCategoryId[0]?.categoryId || null,
                                categoryName: categoryName,
                                categoryDescription: descriptionLong,
                                categoryImage: thumbNail,
                                categoryCoverImage: coverImage,
                                categoryIcon: thumbNail,
                                isActive: true,
                                isDeleted: false,
                                itemCatalogCategory: itemsCatalogsFinalList?.map((item) => ({
                                    catalogId: item?.catalogId,
                                    itemId: item?.itemId,
                                })),
                            },
                        },
                    });
                }
            }}
        >
            {({ handleSubmit, handleChange, values, errors, touched }) => (
                <>
                    {showToast && (
                        <Toast
                            setShowToast={setShowToast}
                            message={message}
                            width="w-10/12"
                            margin="ml-1"
                            error={error}
                        />
                    )}
                    <div className="pr-44 -ml-4">
                        <div className="grid grid-cols-8 gap-4">
                            <div className="col-start-1 col-end-5 mt-3 px-4">
                                <InputField
                                    id="categoryName"
                                    placeHolder="Enter Category title"
                                    name="Category title"
                                    labelWidth="w-25"
                                    maxLength={255}
                                    onChange={handleChange('categoryName')}
                                    value={values.categoryName}
                                />
                                <div className="w-full py-0.5 justify-end  flex  pl-4">
                                    <div className="font-poppins text-gray-400 mr-5">
                                        255 characters limited
                                    </div>
                                </div>
                                {errors.categoryName && touched.categoryName ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.categoryName}
                                    </div>
                                ) : null}
                            </div>

                            <UnsaveChangesWarning
                                contentText={PRODUCT_ADD_EXIT_CONFIRM_MESSAGE}
                                messageTitle={PRODUCT_ADD_EXIT_CONFIRM_TITLE}
                                when={isDirty || window.onbeforeunload ? true : false}
                                navigate={(path) => history.push(path)}
                                shouldBlockNavigation={() => {
                                    if (isDirty) {
                                        return true;
                                    }
                                    return false;
                                }}
                                displayIcon={true}
                                displayIconName={'clarity_error-standard-line-svg.svg'}
                            />

                            <div className="col-start-1 col-end-9 mt-5 flex flex-wrap relative px-4">
                                <div
                                    className={`text-gray-500  w-58 text-center font-poppins text-sm lg:text-sm h-max bg-white z-40 -mb-3 ml-4 border-opacity-20`}
                                >
                                    Category description (Detailed)
                                </div>
                                <textarea
                                    className="p-4 z-0 min-w-full rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                    id="categoryDescription"
                                    name="categoryDescription"
                                    rows={3}
                                    cols={138}
                                    draggable={false}
                                    maxLength={300}
                                    onInput={handleChange('categoryDescription')}
                                    onChange={(e) => {
                                        setDescriptionLong(e.target.value);
                                        setIsDirty(true);
                                    }}
                                    value={values?.categoryDescription}
                                    placeholder="Enter category Description"
                                />
                                <div className="w-full py-0.5 justify-end  flex  pl-4">
                                    <div className="font-poppins text-gray-400 mr-5">
                                        300 characters limited
                                    </div>
                                </div>
                                {errors.categoryDescription && touched.categoryDescription ? (
                                    <div className="text-red-500 text-xs -mt-4 md:text-sm">
                                        {errors.categoryDescription}
                                    </div>
                                ) : null}
                                {errors.categoryDescription && touched.categoryDescription
                                    ? window.scrollTo({ top: 0, behavior: 'smooth' })
                                    : null}
                            </div>

                            <div className="col-start-1 col-end-5 ">
                                <div className="flex order-1 flex-wrap flex-col relative mb-2 px-4">
                                    <div className="text-gray-500 w-44 text-center font-poppins z-30 text-sm lg:text-sm h-max bg-white -mb-3 ml-4 border-opacity-20">
                                        Select partner catalog
                                    </div>
                                    <div className="z-20">
                                        <Multiselect
                                            displayValue="key"
                                            className="e-multiselect"
                                            style={{
                                                chips: {
                                                    background: '#c084fc',
                                                    '&:hover': {
                                                        borderColor: 'red',
                                                        color: 'red',
                                                    },
                                                },
                                                multiselectContainer: {
                                                    color: 'black',
                                                    '&:hover': {
                                                        borderColor: 'red',
                                                        color: 'red',
                                                    },
                                                },
                                                searchBox: {
                                                    border: '1px solid black',
                                                    'border-radius': '10px',
                                                    padding: '10px',
                                                    '&:hover': {
                                                        borderColor: 'red',
                                                        color: 'red',
                                                    },
                                                },
                                            }}
                                            options={catalogsListMultiple}
                                            avoidHighlightFirstOption={true}
                                            onSelect={onSelectCatalogs}
                                            onRemove={onRemoveCatalogs}
                                            hidePlaceholder={true}
                                            showCheckbox
                                        />
                                    </div>
                                </div>
                                {catalogError ? (
                                    <div className="text-red-500 text-xs mt-2 ml-6 md:text-sm">
                                        {'Catalogs are missing'}
                                    </div>
                                ) : null}
                            </div>

                            <div className="lg:col-start-1 lg:col-end-5">
                                <div className="flex order-1 flex-wrap flex-col relative mt-3  px-4">
                                    <div className="text-gray-500 w-49 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Parent category(optional)
                                    </div>
                                    <select
                                        className="p-4 lg:p-3  rounded-xl outline-none border-2  bg-white border-gray-400 hover:border-purple-500"
                                        id="code"
                                        onChange={(e) => {
                                            setSelectedCategory(e.target.value);
                                            setIsDirty(true);
                                        }}
                                        onBlur={(e) => {
                                            setSelectedCategory(e.target.value);
                                            setIsDirty(true);
                                        }}
                                    >
                                        <option value="" disabled selected>
                                            Select parent category
                                        </option>
                                        <option value={0} key={3}>
                                            None
                                        </option>
                                        {categoriesDropDownList?.map((option: any, index) => {
                                            return (
                                                <option value={option.value} key={index}>
                                                    {option.text}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div
                                    className="w-full order-2 py-0.5 justify-end  flex ml-4 -mt-10 pl-4"
                                    onMouseOver={() => setShowToolTip(true)}
                                    onMouseLeave={() => setShowToolTip(false)}
                                >
                                    <img
                                        className={`w-5 h-6 ${showToolTip ? 'hidden' : 'block'}`}
                                        src="/images/icons/info.png"
                                        alt="question"
                                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => setShowToolTip(true)}
                                        onKeyDown={() => setShowToolTip(true)}
                                    />
                                    {showToolTip ? (
                                        <MuiThemeProvider theme={theme}>
                                            <Tooltip
                                                key={1}
                                                title={
                                                    'Assign a parent category to create a hierarchy of category'
                                                }
                                                arrow
                                                placement="right-start"
                                            >
                                                <img
                                                    className="w-5 h-6"
                                                    src="/images/icons/info.png"
                                                    alt="question"
                                                />
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-5 flex-col xl:flex-row pl-4 pr-64">
                            <div className="w-full">
                                <div className="flex my-3">
                                    <div className="mr-1 font-poppins">
                                        Category thumbnail(optional)
                                    </div>
                                    <div className="text-gray-400">(Max 100X 100)</div>
                                </div>

                                <UploadCategoryImage
                                    width={'w-5/6'}
                                    height={'h-80'}
                                    isEdit={true}
                                    inputId="thumbnail"
                                    isThumbNail={true}
                                    setImage={setThumbNail}
                                    isThumbnailImage={true}
                                />

                                <div className="text-gray-400 mt-5 flex mb-5">
                                    What can I upload?
                                    <div
                                        className=" py-0.5   pl-4"
                                        onMouseOver={() => setShowToolTip(true)}
                                        onMouseLeave={() => setShowToolTip(false)}
                                    >
                                        <img
                                            className={`w-5 h-6 ${
                                                showToolTip ? 'hidden' : 'block'
                                            }`}
                                            src="/images/icons/question2.png"
                                            alt="question"
                                            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => setShowToolTip(true)}
                                            onKeyDown={() => setShowToolTip(true)}
                                        />
                                        {showToolTip ? (
                                            <MuiThemeProvider theme={theme}>
                                                <Tooltip
                                                    key={2}
                                                    title={'Upload  (SVG)'}
                                                    arrow
                                                    placement="right-start"
                                                >
                                                    <img
                                                        className="w-5 h-6"
                                                        src="/images/icons/question2.png"
                                                        alt="question"
                                                    />
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <div className="flex my-3">
                                    <div className="mr-1 font-poppins">
                                        Category cover image (optional)
                                    </div>
                                    <div className="text-gray-400"> (Max 2048 X 2048)</div>
                                </div>

                                <UploadCategoryImage
                                    width={'w-5/6'}
                                    height={'h-80'}
                                    isEdit={true}
                                    inputId="coverImage"
                                    setImage={setCoverImage}
                                    isThumbnailImage={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="border-2 border-gray-300 rounded-lg mr-5">
                        <CategoryItemSelectionView
                            selectedCatalogs={selectedCatalogList}
                            setSelectedItemsCatalogs={setSelectedItemsCatalogs}
                            setPreviousSelectedItems={setPreviousSelectedItems}
                            itemCatalogs={itemCatalogs}
                            setItemCatalogs={setItemCatalogs}
                        />
                    </div>

                    <div className="w-full py-4 justify-end bg-gray-100 flex mt-3 pr-8">
                        <Buttons
                            name="Cancel"
                            type="button"
                            buttonType="secondary-border-black"
                            id="Cancel"
                            size="e-small"
                            other="mr-3"
                            onclick={() => {
                                history.push('/products/categories');
                            }}
                        />
                        <Buttons
                            name={addCategoryLoading ? <NormalLoader /> : 'Save'}
                            type="submit"
                            buttonType="primary"
                            id="update"
                            size="e-small"
                            disabled={selectedItemsCatalogs?.length ? false : true}
                            onclick={() => {
                                setClicked(true);
                                setIsDirty(false);

                                if (!addCategoryLoading && !error) {
                                    handleSubmit();
                                }
                            }}
                        />
                    </div>
                </>
            )}
        </Formik>
    );
}
