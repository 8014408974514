import SideBar from '../../templates/SideBar';

export default function NotFound() {
    return (
        <div className="container w-screen h-screen">
            <div className="flex flex-row w-screen">
                <div className="container relative bg-black w-1/3 lg:w-1/4 xl:w-1/6">
                    <div className="fixed w-1/3 lg:w-1/4 xl:w-1/6">
                        <SideBar />
                    </div>
                </div>

                <div className=" w-2/3 lg:w-3/4 xl:w-5/6">
                    <div className="pl-6 pt-20 w-full">
                        <div className="ml-10 w-11/12 border-2 border-red-700 pr-10 p-2 bg-red-100  rounded-md mr-20">
                            <div className="flex ">
                                <div className=" pt-1 text-lg text-red-700 font-poppins">
                                    Oops the page you are looking for is not available!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
