export const INSTANT_EDIT_OPTION_VIEW_USER = 'VIEW';

export const INSTANT_EDIT_OPTION_EDIT_USER = 'EDIT';

export const ERROR = 'Email already exist';

export const USER_TYPE_INVITED = 'USER_TYPE_INVITED';

export const USER_TYPE_EXISTING = 'USER_TYPE_EXISTING';

export const USER_ADD_EXIT_CONFIRM_MESSAGE = 'Are you sure you want to cancel adding a new user?';

export const USER_ADD_EXIT_CONFIRM_TITLE = 'Are you sure';

export const USER_CHANGE_PASSWORD_SUCCESS_MESSAGE = 'Successfully updated password';
