import { useState, useEffect } from 'react';
import { InputField } from '../../ui/atoms/InputField';

interface Props {
    config: any;
    setResultantConfig: (value) => void;
    configInputFields?: any;
    setconfigInputFields?: (value) => void;
    configInputValid?: () => boolean;
    initialConfigInputFields?: any;
    setInitialConfigInputFields?: (value) => void;
    disableInputs: boolean;
}

export default function ConfigurationInputView({
    config,
    setResultantConfig,
    configInputFields,
    setconfigInputFields,
    configInputValid,
    initialConfigInputFields,
    setInitialConfigInputFields,
    disableInputs,
}: Props) {
    const [errorMsg, setErrorMsg] = useState<string>(null);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        if (config) {
            const result = Object.keys(config)?.map((confKey) => {
                return {
                    key: confKey,
                    value: config[confKey],
                    error: false,
                };
            });
            setconfigInputFields(result);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, setconfigInputFields]);

    useEffect(() => {
        if (config && initialConfigInputFields === null && setInitialConfigInputFields) {
            const result = Object.keys(config)?.map((confKey) => {
                return {
                    key: confKey,
                    value: config[confKey],
                    error: false,
                };
            });
            setInitialConfigInputFields(result);
        }
    }, [config, initialConfigInputFields, setInitialConfigInputFields]);

    useEffect(() => {
        if (configInputFields !== null && configInputFields?.length > 0) {
            const lastField = configInputFields.at(-1);

            if (lastField?.key !== '' && lastField?.value !== '') {
                setErrorMsg(null);
                setError(false);
            }
        }
    }, [configInputFields]);

    useEffect(() => {
        const configObject = {};
        if (configInputFields != null && configInputFields?.length > 0) {
            configInputFields?.map((field) => (configObject[field?.key] = field?.value));
        }
        setResultantConfig(configObject);
    }, [configInputFields, setResultantConfig]);

    const handleKeyChange = (index, event, error) => {
        const data = [...configInputFields];
        data[index].key = event.target.value;
        setconfigInputFields(data);
        if (error === true) {
            configInputValid();
        }
    };

    const handleValueChange = (index, event, error) => {
        const data = [...configInputFields];
        data[index].value = event.target.value;
        setconfigInputFields(data);
        if (error === true) {
            configInputValid();
        }
    };

    const addFields = () => {
        const lastField = configInputFields?.at(-1);
        if (lastField?.key !== '' && lastField?.value !== '') {
            const newField = { key: '', value: '', error: false };
            setconfigInputFields([...configInputFields, newField]);
        } else {
            setErrorMsg('Please fill the empty fields to continue');
            setError(true);
        }
    };

    return (
        <>
            <div className="grid grid-cols-8 gap-4 p-4 w-full">
                {configInputFields?.map((input, index) => (
                    <>
                        <div className="col-start-1 col-end-4 w-full" key={index}>
                            <div className="flex flex-wrap flex-col relative w-full">
                                <InputField
                                    id={`key-${index + 1}`}
                                    name="key"
                                    labelWidth="w-28"
                                    onChange={(e) => {
                                        handleKeyChange(index, e, input?.error);
                                    }}
                                    value={input?.key}
                                    readonly={disableInputs}
                                />
                            </div>
                        </div>
                        <div className="col-start-4 col-end-7 w-full" key={index + 1}>
                            <div className="flex flex-wrap flex-col relative w-full">
                                <InputField
                                    id={`value-${index + 1}`}
                                    name="value"
                                    labelWidth="w-28"
                                    onChange={(e) => {
                                        handleValueChange(index, e, input?.error);
                                    }}
                                    value={input?.value}
                                    readonly={disableInputs}
                                />
                            </div>
                        </div>
                        {input.error && (
                            <div className="col-start-1 col-end-4 w-full text-red-500 text-xs mt-1 md:text-sm">
                                Please fill the above fields
                            </div>
                        )}
                    </>
                ))}
            </div>
            {error && errorMsg ? (
                <div className="grid grid-cols-8 gap-4 p-4 w-full">
                    <div className="col-start-1 col-end-4 w-full font-poppins text-red-500">
                        {errorMsg}
                    </div>
                </div>
            ) : null}
            <div className="grid grid-cols-8 gap-4 p-4 w-full">
                <div className="col-start-1 col-end-3 w-full justify-center">
                    <button
                        className={`${
                            disableInputs ? 'bg-gray-400 cursor-not-allowed' : 'bg-purple-500'
                        } text-white py-2 px-4 rounded-md`}
                        onClick={() => {
                            addFields();
                        }}
                        disabled={disableInputs}
                    >
                        Add more configs
                    </button>
                </div>
            </div>
        </>
    );
}
