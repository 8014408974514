import React from 'react';
import { Images } from '../../ui/atoms/Images';

interface ButtonProps {
    print?: boolean;
}

export default function CustomButton({ print }: ButtonProps) {
    return (
        <div>
            <div className="border-purple-100 h-10 w-10 rounded-md border-2 bg-purple-100">
                <div className="flex">
                    <div className="m-auto flex py-2">
                        <Images
                            height="h-full "
                            width="w-full"
                            src={`/images/icons/${print ? 'printer' : 'dowload'}.svg`}
                            alt="pc"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
