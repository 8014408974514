/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';

interface FilterDropdownOption {
    value: string;
    text: string;
    isSeparator?: boolean;
}

interface Props {
    dropDownName: string;
    valueList: FilterDropdownOption[];
    setSelectedValue?: (value: any) => void;
    widthClass?: string;
}

export default function FilterDropdownReports({
    dropDownName,
    valueList,
    setSelectedValue,
    widthClass,
}: Props) {
    return (
        <div className="w-full ">
            <div className="flex flex-wrap flex-col relative">
                <div
                    className={`text-gray-600 ${
                        widthClass ?? 'w-32'
                    } text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20`}
                >
                    {dropDownName}
                </div>
                <select
                    className="px-4 py-3 z-0 rounded-lg outline-none border bg-white border-gray-400 hover:border-purple-500"
                    id="filter_orders_report"
                    placeholder="actions"
                    onChange={(e) => {
                        setSelectedValue(e.target.value);
                    }}
                    disabled={valueList?.length <= 0}
                >
                    <option className={'font-medium'} value="" disabled selected>
                        {`Select an option`}
                    </option>
                    {valueList?.map((option, index) => {
                        if (option.isSeparator) {
                            return (
                                <option
                                    value={option.value}
                                    className={'font-medium bg-gray-100'}
                                    key={index}
                                    disabled
                                >
                                    {option.text?.slice(0, 50)}
                                </option>
                            );
                        } else {
                            return (
                                <option value={option.value} className={'font-medium'} key={index}>
                                    {option.text?.slice(0, 50)}
                                </option>
                            );
                        }
                    })}
                </select>
            </div>
        </div>
    );
}
