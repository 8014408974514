import { useEffect, useState } from 'react';
import TableTabView from '../../productScreens/TableTabView';
import AvailableItemsListView from './AvailableItemsListView';
import SelectedItemsView from './SelectedItemsView';

interface Props {
    selectedCatalogs?;
    setSelectedItemsCatalogs?: (value) => void;
    previousSelectedItems?;
    setSelectedTabId?: (value) => void;
    setPreviousSelectedItems?: (value) => void;
    previousSelectedItemCatalogs?;
    setItemCatalogs?: (value) => void;
    itemCatalogs?;
    initialItemCatalogs?;
    setInitialItemCatalogs?: (value) => void;
    activeCatalogs?;
}

export default function CategoryItemSelectionView({
    selectedCatalogs,
    setSelectedItemsCatalogs,
    previousSelectedItems,
    setSelectedTabId,
    setPreviousSelectedItems,
    previousSelectedItemCatalogs,
    setItemCatalogs,
    itemCatalogs,
    initialItemCatalogs,
    setInitialItemCatalogs,
    activeCatalogs,
}: Props) {
    const [selectedTab, setSelectedTab] = useState(1);
    const [categoryProductsCounts, setCategoryProductsCounts] = useState({
        all: 0,
        selected: 0,
    });
    const [selectedItems, setSelectedItems] = useState([]);
    const [availableItemsCount, setAvailableItemsCount] = useState<number>(0);
    // To visualize categoryItems in selected items component
    const [categoryItems, setCategoryItems] = useState([]);
    const [isSelectedCatalogsUpdated, setIsSelectedCatalogsUpdated] = useState(false);
    const [checkedRowIds, setCheckedRowIds] = useState(null);
    let deletedIds = [];
    let changedCatalogItemIds = [];
    const changedItemCatalogs = [];

    useEffect(() => {
        if (availableItemsCount) {
            setCategoryProductsCounts({
                all: availableItemsCount,
                selected: categoryItems?.length,
            });
        }
    }, [availableItemsCount, categoryItems]);

    useEffect(() => {
        if (previousSelectedItems) {
            const allItemsList = [...selectedItems];

            previousSelectedItems?.forEach((previousItem) => {
                const exists = allItemsList?.some((item) => item?.id === previousItem?.id);
                if (!exists) allItemsList?.push(previousItem);
            });

            setSelectedItems(allItemsList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousSelectedItems, setSelectedItems]);

    useEffect(() => {
        if (selectedItems) {
            const items = [];
            selectedItems?.map((selectedItem) => {
                items.push(selectedItem);
            });
            setCategoryItems(items);
        }
    }, [selectedItems]);

    const orderProducts = (orderedList) => {
        const tempItemCatelogs = [...itemCatalogs];
        let sortedCatelogs = [];

        orderedList.forEach((item) => {
            sortedCatelogs = [
                ...sortedCatelogs,
                tempItemCatelogs.find((x) => x.itemId === item.id),
            ];
        });

        setItemCatalogs(sortedCatelogs);
        setCategoryItems(orderedList);
        pushChangesToParentView(sortedCatelogs);
    };

    const removeDeletedItems = (deletedId) => {
        if (setInitialItemCatalogs) {
            setInitialItemCatalogs([
                ...initialItemCatalogs.filter((itemCatalog) => itemCatalog.itemId != deletedId),
            ]);
        }

        const itemCatalogsTemp = [
            ...itemCatalogs.filter((itemCatalog) => itemCatalog.itemId != deletedId),
        ];
        setItemCatalogs(itemCatalogsTemp);

        if (!deletedIds.includes(deletedId)) {
            const updateDeletedItemIds = [...deletedIds, deletedId];
            deletedIds = updateDeletedItemIds;
        }

        const itemsList = [...selectedItems];
        const filteredList = itemsList?.filter((item) => !deletedIds.includes(item?.id));

        setSelectedItems(filteredList);
        setPreviousSelectedItems(filteredList);

        pushChangesToParentView(itemCatalogsTemp);
    };

    const addCheckedItems = (checkedList) => {
        const itemList = [...selectedItems];
        let ItemCatalogsTemp = [];
        checkedList?.forEach((checkedItem) => {
            const exists = itemList?.some((item) => item?.id === checkedItem?.id);
            if (!exists) {
                itemList?.push(checkedItem?.original);
                ItemCatalogsTemp = [
                    ...itemCatalogs.filter((itemCatalog) => itemCatalog.itemId != checkedItem?.id),
                    { itemId: checkedItem?.id, catalogs: selectedCatalogs },
                ];
                setItemCatalogs(ItemCatalogsTemp);
            }
        });

        setSelectedItems(itemList);

        pushChangesToParentView(ItemCatalogsTemp);
    };

    useEffect(() => {
        if (checkedRowIds) {
            const itemList = [...selectedItems];
            let removedItemId = null;
            const filteredList = itemList?.filter((item) => {
                const isItemRemoved = checkedRowIds[item?.id] !== undefined;
                if (!isItemRemoved) {
                    removedItemId = item?.id;
                }
                return isItemRemoved;
            });

            if (removedItemId && setInitialItemCatalogs) {
                setInitialItemCatalogs([
                    ...initialItemCatalogs.filter(
                        (initialItemCatalog) => initialItemCatalog.itemId != removedItemId,
                    ),
                ]);
            }

            const itemCatalogsTemp = [
                ...itemCatalogs.filter((itemCatalog) => itemCatalog.itemId != removedItemId),
            ];
            setItemCatalogs(itemCatalogsTemp);
            setSelectedItems(filteredList);
            pushChangesToParentView(itemCatalogsTemp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedRowIds, setSelectedItems]);

    const handleCatalogsChange = (changedItemCatalog) => {
        if (!changedCatalogItemIds.includes(changedItemCatalog?.itemId)) {
            const updatedItemIds = [...changedCatalogItemIds, changedItemCatalog?.itemId];
            changedCatalogItemIds = updatedItemIds;
            changedItemCatalogs?.push(changedItemCatalog);
        }

        const itemCatalogList = [...itemCatalogs];
        itemCatalogList?.forEach((itemCatalog) => {
            const exists = changedCatalogItemIds?.includes(itemCatalog?.itemId);
            if (exists) {
                const foundMappedCatalogs = changedItemCatalogs?.find(
                    (changedItem) => changedItem?.itemId === itemCatalog?.itemId,
                );
                itemCatalog.catalogs = foundMappedCatalogs?.catalogs;
            }
        });

        pushChangesToParentView(itemCatalogList);
    };

    const pushChangesToParentView = (itemCatalogsTemp?: any) => {
        const itemsCatalogsFinalList = [];
        if (itemCatalogsTemp) {
            itemCatalogsTemp?.forEach((itemCatalog) => {
                itemCatalog?.catalogs?.forEach((catalog) => {
                    itemsCatalogsFinalList?.push({
                        itemId: itemCatalog?.itemId,
                        catalogId: catalog?.value,
                    });
                });
            });
        } else {
            itemCatalogs?.forEach((itemCatalog) => {
                itemCatalog?.catalogs?.forEach((catalog) => {
                    itemsCatalogsFinalList?.push({
                        itemId: itemCatalog?.itemId,
                        catalogId: catalog?.value,
                    });
                });
            });
        }

        setSelectedItemsCatalogs(itemsCatalogsFinalList);
    };

    useEffect(() => {
        if (!isSelectedCatalogsUpdated && itemCatalogs?.length == 0) {
            const itemCatalogList = [...itemCatalogs];
            if (selectedItems) {
                selectedItems?.forEach((selectedItem) => {
                    let catalogs = [];

                    if (selectedCatalogs) {
                        const catalogList = [];

                        if (catalogs?.length > selectedCatalogs?.length) {
                            const filteredCatalogs = catalogs?.filter((catalog) =>
                                selectedCatalogs?.some(
                                    (selected) => catalog?.value === selected?.value,
                                ),
                            );
                            catalogList.push(filteredCatalogs);
                        } else {
                            selectedCatalogs?.forEach((catalog) => {
                                if (!catalogs?.includes(catalog)) {
                                    catalogList?.push(catalog);
                                }
                            });
                        }

                        catalogs = catalogList;
                    }

                    const previousCatalogs = previousSelectedItemCatalogs?.find(
                        (catalog) => catalog?.itemId === selectedItem?.id,
                    );

                    if (previousCatalogs) {
                        catalogs = previousCatalogs?.catalogs?.map((catalog) => {
                            return { value: catalog?.catalogId, label: catalog?.catalogName };
                        });
                    }

                    const exists = itemCatalogList?.some(
                        (itemCatalog) => itemCatalog?.itemId === selectedItem?.id,
                    );
                    if (!exists) {
                        const catalogsObject = {
                            itemId: selectedItem?.id,
                            catalogs: activeCatalogs
                                ? catalogs.filter((catalog) =>
                                      activeCatalogs.includes(catalog.value),
                                  )
                                : catalogs,
                        };
                        itemCatalogList?.push(catalogsObject);
                    } else {
                        itemCatalogList?.forEach((itemCatalog) => {
                            if (itemCatalog?.itemId === selectedItem?.id) {
                                itemCatalog.catalogs = catalogs;
                            }
                        });
                    }
                });

                const filteredCatalogs = itemCatalogList?.filter((itemCatalog) =>
                    selectedItems?.some((selectedItem) => itemCatalog?.itemId === selectedItem?.id),
                );
                if (filteredCatalogs?.length > 0 && setInitialItemCatalogs) {
                    setIsSelectedCatalogsUpdated(true);
                    setInitialItemCatalogs(filteredCatalogs);
                }
                setItemCatalogs(filteredCatalogs);

                pushChangesToParentView();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        previousSelectedItemCatalogs,
        selectedCatalogs,
        selectedItems,
        setItemCatalogs,
        selectedTab,
    ]);

    useEffect(() => {
        if (selectedTab && setSelectedTabId) {
            setSelectedTabId(selectedTab);
        }
    }, [selectedTab, setSelectedTabId]);

    return (
        <>
            <div className="w-full">
                <div className={`w-full ml-4 py-4 `}>
                    <TableTabView
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        categoryProductsCounts={categoryProductsCounts}
                    />
                </div>
                <div className="w-full">
                    {selectedTab === 1 ? (
                        <AvailableItemsListView
                            selectedTab={selectedTab}
                            selectedItems={selectedItems}
                            addCheckedItems={addCheckedItems}
                            setAvaialbleItemsCount={setAvailableItemsCount}
                            setCheckedRowIds={setCheckedRowIds}
                        />
                    ) : (
                        <SelectedItemsView
                            categoryItems={categoryItems}
                            handleCatalogsChange={handleCatalogsChange}
                            removeDeletedItems={removeDeletedItems}
                            orderProducts={orderProducts}
                            selectedCatalogs={selectedCatalogs}
                            itemsCatalogsList={itemCatalogs}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
