/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import '../../../styles/toggle.css';

const AntSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 38,
            height: 18,
            padding: 0,
        },
        switchBase: {
            padding: 3,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(20px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: '#6B37FC',
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `2px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }),
)(Switch);

export default function CustomizedSwitches() {
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <div className="-mt-2">
            <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
            </label>
        </div>
    );
}
