import React from 'react';
import { useState } from 'react';
import TableTabView from '../productScreens/TableTabView';
import { BarCharts } from './chart/BarChart';
import CustomButton from './CustomButton';
import DropDownCustomReportsSort from './DropDownCustomReportsSort';
import OverviewSubsections from './overview/OverviewSubsections';
import ReportsTilesView from './ReportsTilesView';

export default function ReportsOverView() {
    const [selectedTab, setSelectedTab] = useState(0);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Products',
                accessor: 'supplierId',
            },
            {
                Header: 'Product type',
                accessor: 'supplierName',
            },

            {
                Header: 'Stock remaining',
                accessor: 'count',
            },

            {
                Header: 'Sales',
                accessor: 'createdDate',
            },
            {
                Header: 'No of orders',
                accessor: 'createdDate',
            },
        ],
        [],
    );

    return (
        <div className="h-full flex flex-col  justify-evens">
            <div className="flex justify-between pr-10">
                <div className="text-lg font-poppins font-bold my-auto">Overview</div>
                <div className="flex m-1 p-1">
                    <div className="text-lg font-poppins font-bold mr-2">
                        <DropDownCustomReportsSort />
                    </div>
                    <div className="text-lg font-poppins font-bold mr-2">
                        <CustomButton print={true} />
                    </div>
                    <div className="text-lg font-poppins font-bold">
                        <CustomButton print={false} />
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <ReportsTilesView />
            </div>
            <div className="mt-5">
                <div className="pl-20">
                    <TableTabView
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        reportsView={true}
                    />
                </div>
            </div>
            <div className="mt-5 flex  flex-col justify-center pr-10">
                <div className="text-lg font-poppins flex justify-end font-bold mr-2 ">
                    <DropDownCustomReportsSort />
                </div>
                <div className="w-full bg-purple-50 rounded-md my-4 p-1">
                    <BarCharts
                        width={1000}
                        height={500}
                        labelsData={[
                            'Week 01',
                            'Week 02',
                            'Week 03',
                            'Week 04',
                            'Week 05',
                            'Week 06',
                            'Week 07',
                            'Currently week',
                        ]}
                        dataArray={[123, 149, 53, 65, 27, 38, 100, 128]}
                    />
                </div>
                <div className="flex flex-col lg:flex-row justify-between  mt-10">
                    <div className="w-full bg-purple-100 rounded-md p-1 mr-2">
                        <div className="text-lg font-poppins font-bold">Top Partners</div>
                        <div className="text-sm font-poppins text-gray-400">
                            Lastest update 2021/09/04
                        </div>
                        <div className="mt-6">
                            <BarCharts
                                width={300}
                                height={300}
                                labelsData={[
                                    'Hubber',
                                    'D11',
                                    'SLT E-sports',
                                    'Paythem',
                                    'Top partners',
                                    'Top partners',
                                ]}
                                dataArray={[123, 149, 53, 65, 27, 38]}
                            />
                        </div>
                    </div>

                    <div className="w-full bg-purple-100 rounded-md p-1">
                        <div className="text-lg font-poppins font-bold">Top Suppliers</div>
                        <div className="text-sm font-poppins text-gray-400">
                            Lastest update 2021/09/04
                        </div>
                        <div className="mt-6">
                            <BarCharts
                                width={300}
                                height={300}
                                labelsData={[
                                    'Codashop',
                                    'SEAGM',
                                    'Xshop',
                                    'PayThem',
                                    'Suppliers',
                                    'Suppliers',
                                ]}
                                dataArray={[123, 149, 53, 65, 27, 38]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <div className="h-full flex flex-col  justify-evens pr-8">
                    <div className="border-2 border-gray-300 rounded-lg  mb-10">
                        <div className="w-full">
                            <div className="m-auto flex flex-col">
                                <div className="text-lg font-poppins font-bold m-5">
                                    Top Selling Products
                                </div>
                                <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                    {columns?.map((column, i) => {
                                        return (
                                            <div
                                                className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                key={i}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10">No data found.</div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <OverviewSubsections />
            </div>
        </div>
    );
}
