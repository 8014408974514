import { gql } from '@apollo/client';

export const FETCH_POINTS_DATA = gql`
    query {
        globalPointsList {
            id
            partner {
                id
                partnerId
                creditLimit
                isActive
            }
            supplier {
                id
                supplierId
                supplierName
                creditLimit
                endpointUrl
                isActive
            }
            defaultValue
            overriddenValue
        }
    }
`;

export const ADD_POINTS = gql`
    mutation ($points: PointsInput!) {
        saveGlobalPoints(globalPoints: $points) {
            id
            overriddenValue
            supplier {
                supplierId
            }
        }
    }
`;

export const FETCH_GLOBAL_POINTS_LIST = gql`
    query {
        globalPointsList {
            id
            partner {
                id
                partnerId
                creditLimit
                isActive
            }
            supplier {
                id
                supplierId
                supplierName
                creditLimit
                endpointUrl
                isActive
            }
            defaultValue
            overriddenValue
        }
    }
`;

export const POINTS_OVERRIDE = gql`
    mutation ($globalPoints: PointsInput!) {
        saveGlobalPoints(globalPoints: $globalPoints) {
            id
        }
    }
`;

export const SAVE_PARTNER_POINTS = gql`
    mutation ($saveItemPoints: ItemPointsInput!) {
        saveItemPoints(itemPoints: $saveItemPoints) {
            id
            partner {
                id
                partnerId
                partnerName
                localCurrency
            }
            item {
                id
            }
            markedUpPrice
            pointsConversionRate
            pointsValue
            localCurrencyConversionRate
        }
    }
`;
