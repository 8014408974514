import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PRODUCT_ADD_EXIT_CONFIRM_MESSAGE, UN_SAVED_CHANGES } from '../../../constants/product';
import { VOUCHER_STATUS_AVAILABLE } from '../../../constants/supplier';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { ADD_PRODUCTS } from '../../../queries/ItemQueries';
import Loader from '../../../utils/loader';
import UnsaveChangesWarning from '../../templates/modals/UnsaveChangesWarning';
import { Buttons } from '../../ui/atoms/Button';
import Toast from '../../ui/atoms/Toast';
import NormalLoader from '../../../utils/normalLoader';
import { currencyAndCountryCodes } from '../../../utils/currencies';
import { FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID } from '../../../queries/ProductQueries';
import DataTableAlter from '../../ui/organisms/DataTableAlter';

export default function AddPricingDetails() {
    const history = useHistory();
    const [partnerConversionsData, setPartnerConversionsData] = useState([]);
    // const [selectedPartners, setSelectedPartners] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const conversionRateValue = 1;
    const [unitPriceValue, setUnitPriceValue] = useState(null);
    const [message, setMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [error, setError] = useState(false);
    const [voucherInventories, setVoucherInventories] = useState([]);
    const [voucherLastInventories, setVoucherLastInventories] = useState([]);
    const [selectedProductType, setSelectedProductType] = useState('');

    const location = useLocation<any>();
    const data = location?.state?.item;

    useEffect(() => {
        const arrayOfVoucher = data?.saveItem?.product?.voucherInventories?.filter((voucher) => {
            return voucher?.status === VOUCHER_STATUS_AVAILABLE;
        });
        const arrayOfIds = arrayOfVoucher.map((voc) => voc?.id);
        if (arrayOfIds?.length) {
            const firstVoucherID = arrayOfIds?.length > 1 ? Math.min(...arrayOfIds) : arrayOfIds[0];
            const firstVoucher = data?.saveItem?.product?.voucherInventories?.find(
                (voucher) => voucher?.id === firstVoucherID.toString(),
            );
            setVoucherInventories(firstVoucher);
        }
        localStorage.setItem('iId', data?.saveItem?.id);
    }, [data]);

    useEffect(() => {
        const availableVoucher = data?.saveItem?.product?.voucherInventories;
        const availableIds = availableVoucher?.map((voc) => voc?.id);

        if (availableIds?.length) {
            const firstVoucherID =
                availableIds?.length > 1 ? Math.max(...availableIds) : availableIds[0];
            const firstVoucher = data?.saveItem?.product?.voucherInventories?.find(
                (voucher) => voucher?.id === firstVoucherID.toString(),
            );
            setVoucherLastInventories(firstVoucher);
        }
    }, [data]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'PID',
                accessor: 'partnerId',
            },
            {
                Header: 'Partner name',
                accessor: 'partnerName',
            },
            {
                Header: 'Marked-up Percentage (DCB Currency)',
                accessor: 'markedUpPercentageForCurrency',
                Cell: function dispayMarkedUpPercentageForCurrency({ value }) {
                    return <span>{value || 'N/A'}</span>;
                },
            },
            {
                Header: 'Marked-up Percentage (Points)',
                accessor: 'markedUpPercentage',
                Cell: function dispayMarkedUpPercentage({ value }) {
                    return <span>{value ? value : 'N/A'}</span>;
                },
            },
            {
                Header: 'Marked-up Percentage (PSP Currency)',
                accessor: 'markedUpPercentageForPsp',
                Cell: function dispayMarkedUpPercentageForPsp({ value }) {
                    return <span>{value || 'N/A'}</span>;
                },
            },
            {
                id: 'markupPrice',
                Header: 'Marked-up Price (DCB Local)',
                accessor: 'markedUpPriceForCurrency',
                Cell: function dispayMarkedUpPrice({ value }) {
                    return <span>{value ? value : 'N/A'}</span>;
                },
            },
            {
                Header: 'Marked-up Price (PSP Local)',
                accessor: 'markedUpPriceForPsp',
                Cell: function displayMarkedUpPriceForPsp({ value }) {
                    return <span>{value || 'N/A'}</span>;
                },
            },
            {
                Header: 'Local currency',
                accessor: 'localCurrency',
                Cell: function dispayLocalCurrency({ value }) {
                    return <span>{value ? value : 'N/A'}</span>;
                },
            },
            {
                id: 'pointsConversionRate',
                Header: 'Points conversion rate',
                accessor: 'localCurrencyConversionRate',
                Cell: function dispayPointsConversionPoints({ value }) {
                    return <span>{value ? value : 'N/A'}</span>;
                },
            },
            {
                id: 'itemPoints',
                Header: 'Retail Price in \n Points(Rounded)',
                accessor: 'finalRoundedPointsValue',
                Cell: function dispayFinalRoundedPointsValue({ value }) {
                    return <span>{value ? value : 'N/A'}</span>;
                },
            },
            {
                id: 'expander',
                accessor: '',
                disableSortBy: true,
                width: 5,
                // eslint-disable-next-line react/display-name
                Cell: () => (
                    <span>
                        <button
                            disabled={true}
                            className="text-gray-500 underline cursor-not-allowed"
                        >
                            Edit
                        </button>
                    </span>
                ),
            },
        ],
        [],
    );

    const [saveItem, { loading: queryLoading }] = useMutation(ADD_PRODUCTS, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        onCompleted: () => {
            setShowToast(true);
            setError(false);
            setMessage('Successfully created the product');
            setTimeout(() => {
                setShowToast(false);
                history.push({
                    pathname: '/products',
                    state: { setToast: true, addProduct: true },
                });
            }, 2000);
            clearStorage();
        },
        onError: (error) => {
            console.error(error);
            setShowToast(true);
            setError(true);
            setMessage('Failed to created the product');
        },
    });

    const { loading: partnerConversionsDataLoading } = useQuery(
        FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID,
        {
            variables: { itemId: data?.saveItem?.id },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                setPartnerConversionsData(data?.partnerConversions);
            },
        },
    );

    const clearStorage = () => {
        localStorage.removeItem('product-name');
        localStorage.removeItem('currency');
        localStorage.removeItem('cPrice');
        localStorage.removeItem('iId');
    };

    const handleOnClickSave = () => {
        saveItem({
            variables: {
                item: {
                    id: data?.saveItem?.id,
                    itemId: data?.saveItem?.itemId,
                    itemName: data?.saveItem?.itemName,
                    itemType: data?.saveItem?.itemType,
                    itemDescBrief: data?.saveItem?.itemDescBrief,
                    itemImageUrl: data?.saveItem?.itemImageUrl,
                    itemGalleryUrls: data?.saveItem?.itemGalleryUrls,
                    itemDescDetail: data?.saveItem?.itemDescDetail,
                    deliveryMethod: data?.saveItem?.deliveryMethod,
                    isDeleted: data?.saveItem?.isDeleted,
                    currencyConversionRate: conversionRateValue,
                    unitPrice: unitPriceValue || voucherInventories['unitPrice'],
                    isActive: data?.saveItem?.isActive,
                    createdBy: data?.saveItem?.createdBy,
                    itemStatus: 'PUBLISHED',
                    stock: data?.saveItem?.stock,
                    product: {
                        id: data?.saveItem?.product?.id,
                    },
                    contentType: selectedProductType || productTypeOptions[0].value,
                },
            },
        });
    };

    useEffect(() => {
        if (voucherInventories['unitPrice'] && voucherInventories['currency']) {
            localStorage.setItem('cPrice', voucherInventories['unitPrice']);
            localStorage.setItem('currency', voucherInventories['currency']);
        } else if (voucherLastInventories['unitPrice'] && voucherLastInventories['currency']) {
            localStorage.setItem('cPrice', voucherLastInventories['unitPrice']);
            localStorage.setItem('currency', voucherLastInventories['currency']);
        }
    }, [voucherInventories, voucherLastInventories]);

    useEffect(() => {
        if (conversionRateValue && voucherInventories['unitPrice']) {
            const result = parseFloat(
                (voucherInventories['unitPrice'] * conversionRateValue).toString(),
            ).toFixed(2);
            setUnitPriceValue(result);
        } else if (
            conversionRateValue &&
            !voucherInventories['unitPrice'] &&
            voucherLastInventories['unitPrice']
        ) {
            const result = parseFloat(
                (voucherLastInventories['unitPrice'] * conversionRateValue).toString(),
            ).toFixed(2);
            setUnitPriceValue(result);
        } else if (!conversionRateValue) {
            setUnitPriceValue(
                voucherLastInventories['unitPrice']
                    ? voucherLastInventories['unitPrice'].toFixed(2)
                    : voucherLastInventories['unitPrice']
                    ? voucherLastInventories['unitPrice'].toFixed(2)
                    : 0,
            );
        }
    }, [conversionRateValue, setUnitPriceValue, voucherInventories, voucherLastInventories]);

    useEffect(() => {
        setIsDirty(true);
    }, []);

    if (partnerConversionsDataLoading) return <Loader />;

    const productTypeOptions = [
        {
            label: 'NORMAL',
            value: 'NORMAL',
        },
        {
            label: 'PREMIUM',
            value: 'PREMIUM',
        },
    ];

    return (
        <>
            <div className="px-8 flex flex-col h-full justify-between">
                {showToast && (
                    <Toast
                        setShowToast={setShowToast}
                        message={message}
                        width="w-8/12"
                        error={error}
                        selfDestruct={true}
                        selfDestructTimer={5000}
                    />
                )}
                <div className="text-lg font-poppins font-bold ">Pricing</div>
                {/* <div className="grid grid-cols-8 gap-4 mt-8 mb-10 ">
                    <div className="col-start-1 col-end-4 -mt-1">
                        <InputField
                            id="prod_cost"
                            placeHolder="Price of the product in USD"
                            name={`Product Cost ${
                                voucherInventories['currency']
                                    ? '(' + voucherInventories['currency'] + ')'
                                    : ''
                            }`}
                            defaultValue={
                                voucherInventories['unitPrice']
                                    ? voucherInventories['unitPrice'].toFixed(2)
                                    : voucherLastInventories['unitPrice']
                                    ? voucherLastInventories['unitPrice'].toFixed(2)
                                    : 0
                            }
                            readonly
                        />
                    </div>
                    <div className="col-start-4 col-end-7 -mt-1">
                        <InputField
                            id="conversion_rate"
                            placeHolder={`${
                                voucherInventories['currency'] ? voucherInventories['currency'] : ''
                            } to USD conversion rate`}
                            name={`Conversion rate ${
                                voucherInventories['currency']
                                    ? '(' + voucherInventories['currency'] + ' to USD)'
                                    : ''
                            }`}
                            onChange={(e) => {
                                setIsDirty(true);
                                dispatch(setConversionRate(e?.target.value));
                                setConversionRateValue(e.target.value);
                            }}
                            defaultValue={conversionRateValue}
                            type="number"
                            min="0"
                        />
                    </div>

                    <div className="col-start-7 col-end-9 -mt-1">
                        <InputField
                            id="converted_product_value"
                            placeHolder="Unit price in USD"
                            name={`Converted product value (USD)`}
                            onChange={(e) => {
                                setIsDirty(true);
                                dispatch(setUnitPrice(JSON.parse(e?.target.value)));
                            }}
                            value={unitPriceValue}
                            type="number"
                            readonly
                        />
                    </div>
                </div> */}
                <div className="grid grid-cols-8 gap-4 mt-8 mb-10">
                    <div className="col-start-1 col-end-4 -mt-1">
                        <div className="font-poppins font-semibold text-lg">
                            Product cost & Currency
                        </div>
                        <div className="flex flex-row pt-2 font-poppins font-semibold text-gray-500 text-base mt-1">
                            <div className="mr-1">
                                <i
                                    className={`${currencyAndCountryCodes
                                        .find((x) => x.currency === voucherInventories['currency'])
                                        ?.country.toLowerCase()} flag px-1 pb-0.5`}
                                ></i>
                            </div>
                            <div>
                                {voucherInventories['currency']
                                    ? voucherInventories['currency']
                                    : voucherLastInventories['currency']
                                    ? voucherLastInventories['currency']
                                    : ''}{' '}
                                {voucherInventories['unitPrice']
                                    ? voucherInventories['unitPrice'].toFixed(2)
                                    : voucherLastInventories['unitPrice']
                                    ? voucherLastInventories['unitPrice'].toFixed(2)
                                    : 0}
                            </div>
                        </div>
                    </div>
                    <div className="col-start-6 col-end-6">
                        <div className="flex flex-wrap flex-col relative w-full">
                            <div className="text-gray-500 w-24 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                Content Type
                            </div>
                            <select
                                className="px-8 py-3 rounded-xl outline-none border-2  bg-white border-gray-400 hover:border-purple-500"
                                id="addProduct_type"
                                onChange={(e) => {
                                    setSelectedProductType(e.target.value);
                                }}
                                onBlur={(e) => {
                                    setSelectedProductType(e.target.value);
                                }}
                                value={selectedProductType}
                                defaultValue={productTypeOptions[0].value}
                            >
                                {productTypeOptions?.map((option: any, index) => {
                                    return (
                                        <option
                                            value={option.value}
                                            className={'font-medium'}
                                            key={index}
                                        >
                                            {option.label}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <UnsaveChangesWarning
                    contentText={PRODUCT_ADD_EXIT_CONFIRM_MESSAGE}
                    messageTitle={UN_SAVED_CHANGES}
                    when={isDirty}
                    navigate={(path) => history.push(path)}
                    shouldBlockNavigation={() => {
                        if (isDirty) {
                            return true;
                        }
                        return false;
                    }}
                    displayIcon={true}
                    displayIconName={'un-saved-changes.svg'}
                    yesButtonName="Yes"
                    noButtonName="No"
                />
                <div className="w-full  mb-20">
                    <div className=" border-2 rounded-md">
                        <div className="text-md font-poppins font-bold py-5 px-4 ">
                            Product - Partner conversions
                        </div>
                        <div className="text-md font-poppins font-normal mb-3 px-4 ">
                            The item is not included into any catalog yet. Once the item is included
                            into one or more catalogs this table will be editable{' '}
                        </div>
                        <div className="w-full h-full p-4">
                            <div className="border-2 rounded-md w-10/12">
                                {partnerConversionsData?.length ? (
                                    <DataTableAlter
                                        columns={columns}
                                        data={partnerConversionsData}
                                        pageCount={1}
                                        // setSelectedItems={setSelectedPartners}
                                        // setPageIndex={setPageIndex}
                                        // setDefaultPageSize={setPageSize}
                                        customizedPageSize={partnerConversionsData?.length}
                                        hidePagination={true}
                                        rowsDisabled={true}
                                    />
                                ) : (
                                    <div className="m-auto flex flex-col ">
                                        <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                            {columns?.map((column, index) => {
                                                return (
                                                    <div
                                                        className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                        key={index}
                                                    >
                                                        {column?.Header}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="m-auto p-10">No data found.</div>
                                        <div className="p-10 bg-purple-100"></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 mt-10 justify-end bg-gray-100 fixed bottom-0 right-0">
                    <Buttons
                        name="Cancel"
                        type="submit"
                        buttonType="secondary-main-grey"
                        id="cancel"
                        size="e-small"
                        onclick={() => history.push('/products')}
                        other="ml-5"
                    />

                    <Buttons
                        name={
                            queryLoading || partnerConversionsDataLoading ? (
                                <NormalLoader />
                            ) : (
                                'Save'
                            )
                        }
                        type="submit"
                        buttonType="primary"
                        id="save"
                        size="e-small"
                        onclick={() => {
                            setIsDirty(false);
                            handleOnClickSave();
                        }}
                    />
                </div>
            </div>
        </>
    );
}
