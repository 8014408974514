import { Cookies } from 'react-cookie';

const cookie = new Cookies();

export function getCookie(key: string) {
    return cookie.get(key);
}

export function setCookie(key: string, value: string, options: any) {
    cookie.set(key, value, options);
}
export function removeCookie(key: string) {
    cookie.remove(key, { path: '/' });
}
