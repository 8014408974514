import React, { ReactElement } from 'react';
import { Modal } from 'react-responsive-modal';
import { Buttons } from '../../ui/atoms/Button';
import { Images } from '../../ui/atoms/Images';

interface Props {
    showUnSavedModal: boolean;
    setShowUnSavedModal: (value: boolean) => void;
}

export default function UnSavedProduct({
    showUnSavedModal,
    setShowUnSavedModal,
}: Props): ReactElement {
    return (
        <Modal
            open={showUnSavedModal}
            onClose={() => {
                setShowUnSavedModal(false);
            }}
            center={true}
            styles={{ modal: { borderRadius: 8, maxWidth: '806px', width: '80%' } }}
            focusTrapped={false}
        >
            <>
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-poppins  font-semibold flex m-auto">
                        Unsaved Changes
                    </h3>
                </div>

                <div className="relative p-6  flex flex-col">
                    <div className="flex m-auto mb-4">
                        <Images
                            width="w-16"
                            height="h-full"
                            src="/images/icons/unsaved.png"
                            alt="success"
                        />
                    </div>
                    <div className="text-gray-400 text-lg flex mb-3 m-auto">
                        There are unsaved changes to your product
                    </div>
                    <div className="text-gray-400 text-lg flex mb-3 m-auto">
                        Do you wish to save this product as a unpublish or discard
                    </div>
                    <div className="text-gray-400 text-lg flex mb-3 m-auto">the changes?</div>
                </div>

                <div className="flex flex-row mt-6 justify-end m-4">
                    <Buttons
                        name="cancel"
                        type="submit"
                        buttonType="secondary-main"
                        id="okay"
                        size="small"
                        padding="p-1"
                        onclick={() => {
                            setShowUnSavedModal(false);
                        }}
                    />
                    <Buttons
                        name="Save to unpublish"
                        type="submit"
                        buttonType="primary"
                        id="okay"
                        size="small"
                        onclick={() => {
                            setShowUnSavedModal(false);
                        }}
                    />
                </div>
            </>
        </Modal>
    );
}
