import { useEffect, useState } from 'react';
import { API, CSV, UNIPIN, ALLSERV } from '../../../constants/supplier';
import ConfigurationInputView from './ConfigurationInputView';

interface Props {
    setSupplierConfiguration: (value) => void;
    previouslySelectedConfiguration?: any;
    configInputFields?: any;
    setconfigInputFields?: (value) => void;
    configInputValid?: () => boolean;
    selectedSupplierType: string;
    setSelectedSupplierType?: (value) => void;
    initialConfigInputFields?: any;
    setInitialConfigInputFields?: (value) => void;
    isSupplierCredentialsDisabled: boolean;
}

export default function SupplierConfigurations({
    setSupplierConfiguration,
    previouslySelectedConfiguration,
    configInputFields,
    setconfigInputFields,
    configInputValid,
    selectedSupplierType,
    setSelectedSupplierType,
    initialConfigInputFields,
    setInitialConfigInputFields,
    isSupplierCredentialsDisabled,
}: Props) {
    const [selectedConfigurationProperties, setSelectedConfigurationProperties] = useState(null);
    const [resultantConfig, setResultantConfig] = useState(null);

    const supplierType = [
        {
            label: 'CSV',
            value: 'CSV',
        },
        {
            label: 'API',
            value: 'API',
        },
        {
            label: 'AllServ',
            value: 'ALLSERV',
        },
    ];

    useEffect(() => {
        if (previouslySelectedConfiguration) {
            if (previouslySelectedConfiguration?.supplierType !== CSV) {
                setSelectedConfigurationProperties(previouslySelectedConfiguration?.config);
                if (selectedSupplierType !== ALLSERV) {
                    setSelectedSupplierType(API);
                }
            }
        }
    }, [
        previouslySelectedConfiguration,
        selectedSupplierType,
        setSelectedConfigurationProperties,
        setSelectedSupplierType,
    ]);

    useEffect(() => {
        const previousConf = previouslySelectedConfiguration;
        if (resultantConfig) {
            if (previousConf && previousConf?.supplierType !== CSV) {
                const resultantSupplierConfiguration = { ...previousConf, config: resultantConfig };
                setSupplierConfiguration(resultantSupplierConfiguration);
            } else {
                const newConfiguration = {
                    config: resultantConfig,
                    supplierType: selectedSupplierType === ALLSERV ? ALLSERV : UNIPIN,
                    name: selectedSupplierType === ALLSERV ? ALLSERV : UNIPIN,
                };
                setSupplierConfiguration(newConfiguration);
            }
        }
    }, [
        selectedSupplierType,
        previouslySelectedConfiguration,
        resultantConfig,
        setSupplierConfiguration,
    ]);

    useEffect(() => {
        if (selectedSupplierType === CSV) {
            setSupplierConfiguration(null);
        }
    }, [selectedSupplierType, setSupplierConfiguration]);

    return (
        <>
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-8 xl:gap-4 p-4 w-full">
                <div className="xl:col-start-1 xl:col-end-4 w-full ">
                    <div className="flex flex-wrap flex-col relative w-full">
                        <div className="text-gray-500 w-28 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                            Supplier Type
                        </div>
                        <select
                            className={`px-4 py-3 rounded-xl outline-none border-2 bg-white border-gray-400 ${
                                !isSupplierCredentialsDisabled && 'hover:border-purple-500'
                            }`}
                            id="supplier_type"
                            onChange={(e) => {
                                setSelectedSupplierType(e.target.value);
                            }}
                            onBlur={(e) => {
                                setSelectedSupplierType(e.target.value);
                            }}
                            value={selectedSupplierType}
                            disabled={isSupplierCredentialsDisabled}
                        >
                            {supplierType.map((option: any, index) => {
                                return (
                                    <option
                                        id={option.value}
                                        value={option.value}
                                        className={'font-medium'}
                                        key={index}
                                    >
                                        {option.label}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>
            {selectedSupplierType !== CSV ? (
                <>
                    <div className="font-poppins px-4 font-bold text-lg">Configurations</div>
                    <div className="overflow-y-scroll max-h-80" style={{ marginBottom: '5%' }}>
                        <ConfigurationInputView
                            config={selectedConfigurationProperties}
                            setResultantConfig={setResultantConfig}
                            configInputFields={configInputFields}
                            setconfigInputFields={setconfigInputFields}
                            configInputValid={configInputValid}
                            initialConfigInputFields={initialConfigInputFields}
                            setInitialConfigInputFields={setInitialConfigInputFields}
                            disableInputs={isSupplierCredentialsDisabled}
                        />
                    </div>
                </>
            ) : null}
        </>
    );
}
