import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface Props {
    options: any;
    handleChange: any;
    placeHolder: string;
    noOptionsText?: any;
    id: string;
}

export default function SearchBar({
    options,
    handleChange,
    placeHolder,
    noOptionsText,
    id,
}: Props) {
    return (
        <div className="mt-0.5">
            <Autocomplete
                id={id.toLowerCase()}
                autoComplete
                clearOnBlur={false}
                clearOnEscape
                disableClearable
                options={options}
                openOnFocus={false}
                disableListWrap={true}
                noOptionsText={noOptionsText}
                onChange={(e) => handleChange(e)}
                onSelect={(e) => handleChange(e)}
                renderInput={(params) => (
                    <TextField
                        id={`${id.toLowerCase()}-text`}
                        {...params}
                        placeholder={placeHolder}
                        variant="outlined"
                        label="Search"
                        margin="none"
                        size="small"
                    />
                )}
            />
        </div>
    );
}
