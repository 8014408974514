/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@apollo/client';
import React from 'react';
import { useState } from 'react';
import { SWAR_MVP_HIDE_FEATURE } from '../../../constants/config/constants';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { FETCH_CHART_DETAILS, FETCH_DASHBOARD_DATA } from '../../../queries/DashboardQueries';
import Loader from '../../../utils/loader';
import DataTable from '../../ui/organisms/DataTable';
import TableTabView from '../productScreens/TableTabView';
import { BarCharts } from '../reports/chart/BarChart';
import DropDownCustomReportsSort from '../reports/DropDownCustomReportsSort';
import OverViewDashboard from './subComponents/OverViewDashboard';

export default function DashBoardScreen() {
    const [selectedTab, setSelectedTab] = useState(1);
    const [selectedTopSellingProducts, setSelectedTopSellingProducts] = useState([]);
    const [topSellingProductPageIndex, setTopSellingProductPageIndex] = useState(0);
    const [topSellingProductPageSize, setTopSellingProductPageSize] = useState(10);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Products',
                accessor: 'item.itemName',
            },
            {
                Header: 'Product type',
                accessor: 'supplierName',
            },

            {
                Header: 'Stock remaining',
                accessor: 'item.stock',
            },

            {
                Header: 'Sales',
                accessor: 'createdDate',
                Cell: function date({ value }) {
                    return <span>{value?.slice(0, 16)}</span>;
                },
            },
            {
                Header: 'No of orders',
                accessor: 'noOfOrders',
            },
        ],
        [],
    );

    const partnerColumns = React.useMemo(
        () => [
            {
                Header: 'Partners',
                accessor: 'partner.partnerName',
            },
            {
                Header: 'Country/Region',
                accessor: 'partner.user.contact.countryCode',
            },
            {
                Header: 'Sales',
                accessor: 'sales',
            },
        ],
        [],
    );
    const supplierColumns = React.useMemo(
        () => [
            {
                Header: 'Suppliers',
                accessor: 'supplier.supplierName',
            },
            {
                Header: 'Country/Region',
                accessor: 'supplier.contact.countryCode',
            },
            {
                Header: 'Sales',
                accessor: 'sales',
            },
        ],
        [],
    );

    return (
        <div>
            {!SWAR_MVP_HIDE_FEATURE ? (
                <div className="h-full flex flex-col w-full justify-evens">
                    <div className="flex justify-between pr-10 pl-4">
                        <div className="text-lg font-poppins font-bold my-auto">OverView</div>
                        <div className="flex m-1 p-1">
                            <div className="text-lg font-poppins font-bold mr-2">
                                <DropDownCustomReportsSort />
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full pr-10 mt-12">
                        {/* <OverViewDashboard
                            overViewData={globalDashboardData?.dashboard?.overviewSummary}
                        /> */}
                    </div>
                    <div className="mt-5">
                        <div className="pl-4 pr-4">
                            <TableTabView
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                reportsView={true}
                            />
                        </div>
                    </div>
                    <div className="mt-5 flex  flex-col justify-center pl-2 pr-10">
                        <div className="text-lg font-poppins flex justify-end font-bold mr-2 ">
                            <DropDownCustomReportsSort />
                        </div>
                        <div className="w-full bg-purple-50 rounded-md my-4 px-4">
                            {/* <BarCharts
                                width={1000}
                                height={500}
                                labelsData={
                                    globalDashboardChartData?.overviewChart?.pointsSoldChart?.labels
                                        ?.length
                                        ? globalDashboardChartData?.overviewChart?.pointsSoldChart
                                              ?.labels
                                        : [
                                              'Week 01',
                                              'Week 02',
                                              'Week 03',
                                              'Week 04',
                                              'Week 05',
                                              'Week 06',
                                              'Week 07',
                                              'Currently week',
                                          ]
                                }
                                dataArray={
                                    globalDashboardChartData?.overviewChart?.pointsSoldChart?.values
                                        ?.length
                                        ? globalDashboardChartData?.overviewChart?.pointsSoldChart
                                              ?.values
                                        : [123, 149, 53, 65, 27, 38, 100, 128]
                                }
                            /> */}
                        </div>
                        <div className="flex flex-col lg:flex-row justify-between  mt-10">
                            <div className="w-full bg-purple-100 rounded-md p-1 mr-2">
                                <div className="text-lg font-poppins font-bold">Top Partners</div>
                                <div className="text-sm font-poppins text-gray-400">
                                    Lastest update 2021/09/04
                                </div>
                                <div className="mt-6">
                                    <BarCharts
                                        width={300}
                                        height={300}
                                        labelsData={[
                                            'Hubber',
                                            'D11',
                                            'SLT E-sports',
                                            'Paythem',
                                            'Top partners',
                                            'Top partners',
                                        ]}
                                        dataArray={[123, 149, 53, 65, 27, 38]}
                                    />
                                </div>
                            </div>

                            <div className="w-full bg-purple-100 rounded-md p-1">
                                <div className="text-lg font-poppins font-bold">Top Suppliers</div>
                                <div className="text-sm font-poppins text-gray-400">
                                    Lastest update 2021/09/04
                                </div>
                                <div className="mt-6">
                                    <BarCharts
                                        width={300}
                                        height={300}
                                        labelsData={[
                                            'Codashop',
                                            'SEAGM',
                                            'Xshop',
                                            'PayThem',
                                            'Suppliers',
                                            'Suppliers',
                                        ]}
                                        dataArray={[123, 149, 53, 65, 27, 38]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <div className="h-full flex flex-col  justify-evens">
                                <div className="border-2 border-gray-300 rounded-lg  mb-10">
                                    <div className="w-full">
                                        <div className="m-auto flex flex-col">
                                            <div className="text-lg font-poppins font-bold m-5">
                                                Top Selling Products
                                            </div>
                                            {/* <div className="pt-4">
                                                {globalDashboardData?.dashboard?.topSellingItems ? (
                                                    <DataTable
                                                        columns={columns}
                                                        data={
                                                            globalDashboardData?.dashboard
                                                                ?.topSellingItems
                                                        }
                                                        pageCount={1}
                                                        setSelectedItems={
                                                            setSelectedTopSellingProducts
                                                        }
                                                        setPageIndex={setTopSellingProductPageIndex}
                                                        setDefaultPageSize={
                                                            setTopSellingProductPageSize
                                                        }
                                                        hidePagination={true}
                                                        hideSelection={true}
                                                    />
                                                ) : (
                                                    <div className="m-auto flex flex-col ">
                                                        <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                                            {columns?.map((column) => {
                                                                return (
                                                                    <div
                                                                        key={column.accessor}
                                                                        className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                                    >
                                                                        {column?.Header}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                        <div className="m-auto p-10">
                                                           No data found.
                                                        </div>
                                                        <div className="p-10 bg-purple-100"></div>
                                                    </div>
                                                )}
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <div className="h-full flex flex-col  justify-evens">
                                <div className="border-2 border-gray-300 rounded-lg  mb-10">
                                    <div className="w-full">
                                        <div className="flex flex-col">
                                            <div className="text-lg font-poppins font-bold m-5">
                                                Top Partners
                                            </div>
                                            {/* <div className="pt-4">
                                                {globalDashboardData?.dashboard?.topPartners ? (
                                                    <DataTable
                                                        columns={partnerColumns}
                                                        data={
                                                            globalDashboardData?.dashboard
                                                                ?.topPartners
                                                        }
                                                        pageCount={1}
                                                        setSelectedItems={
                                                            setSelectedTopSellingProducts
                                                        }
                                                        setPageIndex={setTopSellingProductPageIndex}
                                                        setDefaultPageSize={
                                                            setTopSellingProductPageSize
                                                        }
                                                        hidePagination={true}
                                                        hideSelection={true}
                                                    />
                                                ) : (
                                                    <div className="m-auto flex flex-col ">
                                                        <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                                            {partnerColumns?.map((column) => {
                                                                return (
                                                                    <div
                                                                        key={column.accessor}
                                                                        className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                                    >
                                                                        {column?.Header}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                        <div className="m-auto p-10">
                                                           No data found.
                                                        </div>
                                                        <div className="p-10 bg-purple-100"></div>
                                                    </div>
                                                )}
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <div className="h-full flex flex-col  justify-evens">
                                <div className="border-2 border-gray-300 rounded-lg  mb-10">
                                    <div className="w-full">
                                        <div className="m-auto flex flex-col">
                                            <div className="text-lg font-poppins font-bold m-5">
                                                Top Suppliers
                                            </div>
                                            {/* <div className="pt-4">
                                                {globalDashboardData?.dashboard?.topSuppliers ? (
                                                    <DataTable
                                                        columns={supplierColumns}
                                                        data={
                                                            globalDashboardData?.dashboard
                                                                ?.topSuppliers
                                                        }
                                                        pageCount={1}
                                                        setSelectedItems={
                                                            setSelectedTopSellingProducts
                                                        }
                                                        setPageIndex={setTopSellingProductPageIndex}
                                                        setDefaultPageSize={
                                                            setTopSellingProductPageSize
                                                        }
                                                        hidePagination={true}
                                                        hideSelection={true}
                                                    />
                                                ) : (
                                                    <div className="m-auto flex flex-col ">
                                                        <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                                            {supplierColumns?.map((column) => {
                                                                return (
                                                                    <div
                                                                        key={column.accessor}
                                                                        className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                                    >
                                                                        {column?.Header}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                        <div className="m-auto p-10">
                                                            No data found.
                                                        </div>
                                                        <div className="p-10 bg-purple-100"></div>
                                                    </div>
                                                )}
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>Overview, planned for future use.</div>
            )}
        </div>
    );
}
