import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { logout, updateNewUserPassword } from '../../../redux/rootActions';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import jwt from 'jwt-decode';
import passwordGenerator from '../../../service/encryption/passwordGenerator';
import { Buttons } from '../../ui/atoms/Button';
import NormalLoader from '../../../utils/normalLoader';
import { getCookie } from '../../../utils/cookiesService';

export default function NewPassword() {
    const history = useHistory();

    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const location = useLocation();
    const [password, setPassword] = useState('');
    const [loader, setLoader] = useState(false);
    const [passwordChange, setPasswordChange] = useState('');
    const [strong, setStrong] = useState(false);
    const [dirty, setDirty] = useState(false);
    const passwordShow = () => {
        setShowPassword(!showPassword);
    };
    const authToken = getCookie('access_token');

    const token = location?.search.slice(5);
    let tokenDetails: any;
    if (token) {
        tokenDetails = jwt(token);
    }

    const validationSchema = Yup.object({
        password: Yup.string()
            .required('Please Enter your password')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*\\+\\=])(?=.{12,})/,
                'Your password should contain at least twelve characters with upper and lower case letters, numbers and symbols like !@#$%^',
            ),
    });

    useEffect(() => {
        const generatedPassword = passwordGenerator();
        setPassword(generatedPassword);
    }, []);

    useEffect(() => {
        if (
            passwordChange?.length > 10 &&
            passwordChange
                ?.toString()
                ?.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#])(?=.{12,})/)
        ) {
            setStrong(true);
        } else {
            setStrong(false);
        }
    }, [passwordChange]);

    useEffect(() => {
        if (
            password?.length > 10 &&
            password?.toString()?.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#])(?=.{12,})/)
        ) {
            setStrong(true);
        } else {
            setStrong(false);
        }
    }, [password]);

    return (
        <div className="container h-screen w-screen">
            <div
                className="flex bg-cover bg-center   h-full w-full  md:h-screen md:w-screen  bg-no-repeat"
                style={{ backgroundImage: `url(/images/background.svg)` }}
            >
                <div className="container flex  bg-white flex-wrap flex-col max-w-2xl sm:m-auto  md:rounded-md md:mt-36 lg:mt-20 xl:mt-28 2xl:mt-auto ">
                    <div className="mb-7 md:mb-8 min-h-6 lg:mx-12 max-w-2xl">
                        <img className="w-28 mx-auto  mt-14" src="/images/favicon.png" alt=""></img>

                        <div className=" flex flex-col flex-wrap justify-center -mt-4">
                            <div className="m-auto font-extrabold lg:text-2xl font-poppins sm:text-xl  justify-center mb-4">
                                {" WELCOME LET'S GET STARTED"}
                            </div>
                            <img
                                className=" mx-auto my-2 mb-4"
                                src="/images/icons/lockSVG.svg"
                                alt=""
                            />

                            <div className="m-auto p-0 text-gray-400 font-poppins flex flex-col text-xs md:text-sm mb-8 md:mb-3 px-16">
                                To ensure you use a strong password, you are required to change your
                                password before you login for the first time
                            </div>
                        </div>

                        <Formik
                            initialValues={{
                                password: password,
                            }}
                            enableReinitialize
                            validationSchema={validationSchema}
                            onSubmit={async ({ password }) => {
                                if (strong) {
                                    setLoader(true);
                                    if (passwordChange?.length || password?.length) {
                                        dispatch(
                                            updateNewUserPassword(
                                                dirty ? passwordChange : password,
                                                tokenDetails?.sub,
                                            ),
                                        );
                                        if (authToken) {
                                            dispatch(logout());
                                        }

                                        setTimeout(() => {
                                            setLoader(false);
                                            history.push('/');
                                        }, 2000);
                                    }
                                }
                            }}
                        >
                            {({ handleSubmit }) => (
                                <form
                                    onSubmit={(event) => {
                                        event.preventDefault();
                                        handleSubmit();
                                    }}
                                >
                                    <div className="flex flex-col flex-wrap mt-5 md:mt-1 mx-10">
                                        <div className="flex flex-col mt-4 mb-1">
                                            <div className="text-gray-500 text-sm px-2 w-max font-poppins  h-max bg-white z-30 ml-4">
                                                Enter your password
                                            </div>

                                            <div className="relative">
                                                <input
                                                    className="p-3 pt-3 md:p:3  w-full rounded-xl outline-none -mt-3 font-poppins bg-gray-1f00  border-2 border-gray-400  hover:border-purple-500"
                                                    id="password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder="Admin123"
                                                    onChange={(e) => {
                                                        setDirty(true);
                                                        setPasswordChange(e?.target.value);
                                                    }}
                                                    defaultValue={password}
                                                />

                                                <div className="absolute inset-y-0 right-0 pr-3 flex text-base item-center">
                                                    <img
                                                        src="/images/icons/eye 1.png"
                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                            showPassword ? 'block' : 'hidden'
                                                        }`}
                                                        onClick={passwordShow}
                                                        alt="show-password"
                                                        role="presentation"
                                                        draggable="false"
                                                    />

                                                    <img
                                                        src="/images/icons/eye-hide.png"
                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                            showPassword ? 'hidden' : 'block'
                                                        }`}
                                                        alt="hide-password"
                                                        role="presentation"
                                                        onClick={passwordShow}
                                                        draggable="false"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            {strong ? (
                                                <div className="text-green-400 ml-1 ">
                                                    Strong password
                                                </div>
                                            ) : (
                                                <div className="text-yellow-600 ml-1 ">
                                                    Weak password
                                                </div>
                                            )}
                                        </div>

                                        <div className="pt-5 flex align-middle justify-center xl:mb-10 mt-10">
                                            <Buttons
                                                name={loader ? <NormalLoader /> : 'Set Password'}
                                                type="submit"
                                                buttonType="primary"
                                                id="resetPassword"
                                                size="large"
                                                padding="py-2"
                                                onclick={() => handleSubmit()}
                                            />
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
