/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { FETCH_CATEGORIES } from '../../../queries/CategoryQueries';
import { FETCH_PRODUCTS_BY_ID } from '../../../queries/ItemQueries';
import { InputField } from '../../ui/atoms/InputField';
import UploadProductPic from './UplaodProductImage';
import Loader from '../../../utils/loader';
import { Buttons } from '../../ui/atoms/Button';
import { useHistory } from 'react-router';
import { FAILURE_MESSAGE, SUCCESS_MESSAGE_UPDATED_PRODUCT } from '../../../constants/product';
import { ItemStatus } from '../../../enums/item';
import { useDispatch, useSelector } from 'react-redux';
import { saveProductName, setItemID } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { useParams } from 'react-router-dom';
import { CSV, CSV_VOUCHERS } from '../../../constants/supplier';

interface Props {
    props: any;
}
export default function ViewProductScreen({ props }: Props) {
    const [productImage, setProductImage] = useState('');
    const [outOfStockProductImage, setOutOfStockProductImage] = useState('');
    const [categoryData, setCategoryData] = useState([]);
    const [descriptionShort, setDescriptionShort] = useState('');
    const [descriptionLong, setDescriptionLong] = useState('');
    const [selectedProductType, setSelectedProductType] = useState('');
    const [safetyStockThreshold, setSafetyStockThreshold] = useState(50);
    const [orderStrategy, setOrderStrategy] = useState('');

    const [gallery1, setGallery1Image] = useState('');
    const [gallery2, setGallery2Image] = useState('');
    const [gallery3, setGallery3Image] = useState('');
    const [gallery4, setGallery4Image] = useState('');
    const [gallery5, setGallery5Image] = useState('');
    const [gallery6, setGallery6Image] = useState('');

    const parameters = useParams();

    const { data: categoryQueryData, loading: categoryLoading } = useQuery(FETCH_CATEGORIES, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setCategoryData(categoryQueryData?.categories);
        },
    });

    const history = useHistory();
    const dispatch = useDispatch();
    const selectedProduct = parameters['id'];

    const { data: itemData, loading } = useQuery(FETCH_PRODUCTS_BY_ID, {
        variables: { itemId: selectedProduct },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
        onCompleted: () => {
            dispatch(saveProductName(itemData?.item?.itemName));
            setSelectedProductType(itemData?.item?.itemType);
            setDescriptionLong(itemData?.item?.itemDescDetail);
            setDescriptionShort(itemData?.item?.itemDescBrief);
            setSafetyStockThreshold(itemData?.item?.safetyStockThreshold ?? safetyStockThreshold);
            setOrderStrategy(itemData?.item?.product?.orderStrategy);
            setGallery1Image(itemData?.item?.itemGalleryUrls[0]);
            setGallery2Image(itemData?.item?.itemGalleryUrls[1]);
            setGallery3Image(itemData?.item?.itemGalleryUrls[2]);
            setGallery4Image(itemData?.item?.itemGalleryUrls[3]);
            setGallery5Image(itemData?.item?.itemGalleryUrls[4]);
            setGallery6Image(itemData?.item?.itemGalleryUrls[5]);
        },
    });
    const product = itemData?.item;

    const galleryImages = [];

    galleryImages.push(gallery1, gallery2, gallery3, gallery4, gallery5, gallery6);

    const productTypeOptions = [
        {
            text: 'Physical',
            value: 'PHYSICAL',
        },
        {
            text: 'Virtual',
            value: 'VIRTUAL',
        },
    ];

    const defaultStatus = productTypeOptions?.filter((option: any) => {
        return option?.value === product?.itemType;
    });

    if (loading) return <Loader />;

    return (
        <Formik
            initialValues={{
                itemCode: '',
                itemName: product?.itemName,
                itemType: '',
                itemDescBrief: product?.itemDescBrief,
                itemImageUrl: '',
                itemGalleryUrls: [],
                itemDescDetail: product?.itemDescDetail,
                deliveryMethod: '',
                safetyStockThreshold: safetyStockThreshold,
            }}
            enableReinitialize
            onSubmit={() => {
                history.push('/products');
            }}
        >
            {({ handleSubmit, values }) => (
                <>
                    <div className="px-14">
                        <div className="text-xl font-poppins font-bold px-4 ">General</div>

                        <div className="grid grid-cols-8 gap-4">
                            <div className="lg:col-start-1 lg:col-end-5 ">
                                <div className="flex flex-wrap flex-col relative mt-3  px-4">
                                    <div className="text-gray-500 w-25 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Product type
                                    </div>
                                    <select
                                        className="p-4 lg:p-3  rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                        id="code"
                                        disabled={true}
                                        defaultValue={defaultStatus[0]?.value}
                                    >
                                        {productTypeOptions?.map((option: any, index) => {
                                            return (
                                                <option value={option.value} key={index}>
                                                    {option.text}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="col-start-1 col-end-5 mt-3 px-4">
                                <InputField
                                    id="itemName"
                                    placeHolder="Product title"
                                    name="Product title"
                                    labelWidth="w-24"
                                    readonly={true}
                                    value={values.itemName}
                                />
                            </div>
                            <div className="col-start-1 col-end-9 mt-5 flex flex-wrap relative px-4">
                                <div
                                    className={`text-gray-500  w-49 text-center font-poppins text-sm lg:text-sm h-max bg-white z-40 -mb-3 ml-4 border-opacity-20`}
                                >
                                    Product Description(small)
                                </div>
                                <textarea
                                    className="p-4 z-0 min-w-full rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                    id="itemDescBrief"
                                    name="itemDescBrief"
                                    rows={2}
                                    cols={138}
                                    draggable={false}
                                    maxLength={150}
                                    readOnly={true}
                                    placeholder={values.itemDescBrief}
                                />
                                <div className="w-full py-0.5 justify-end  flex  pl-4">
                                    <div className="font-poppins text-gray-400 mr-5">
                                        150 characters limited
                                    </div>
                                </div>
                            </div>
                            <div className="col-start-1 col-end-9 mt-5 flex flex-wrap relative px-4">
                                <div
                                    className={`text-gray-500  w-54 text-center font-poppins text-sm lg:text-sm h-max bg-white z-40 -mb-3 ml-4 border-opacity-20`}
                                >
                                    Product description(detailed)
                                </div>
                                <textarea
                                    className="p-4 z-0 min-w-full rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                    id="itemDescDetail"
                                    name="itemDescDetail"
                                    rows={3}
                                    cols={138}
                                    draggable={false}
                                    maxLength={600}
                                    readOnly={true}
                                    placeholder={values.itemDescDetail}
                                />
                                <div className="w-full py-0.5 justify-end  flex  pl-4">
                                    <div className="font-poppins text-gray-400 mr-5">
                                        600 characters limited
                                    </div>
                                </div>
                            </div>
                            {orderStrategy == CSV_VOUCHERS ? (
                                <div className="col-start-1 col-end-5 mt-3 px-4">
                                    <InputField
                                        id="safetyStockThreshold"
                                        name="Safety Stock Threshold"
                                        labelWidth="w-24"
                                        readonly={true}
                                        value={values.safetyStockThreshold}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="flex mt-5 flex-col xl:flex-row px-4">
                            <div className="w-full">
                                <div className="flex my-3">
                                    <div className="mr-1 font-poppins">Product image</div>
                                </div>

                                <UploadProductPic
                                    width={'w-full'}
                                    height={'h-80'}
                                    isEdit={true}
                                    placeholder={'Add a product image in edit view'}
                                    disabled={true}
                                    inputId="productImage"
                                    setGalleryImage={setProductImage}
                                    imageProp={product?.itemImageUrl}
                                />

                                <div className="text-gray-400  flex mb-5">What can I upload?</div>
                            </div>

                            <div className="w-full ml-9">
                                <div className="flex my-3">
                                    <div className="justify-between font-poppins">
                                        Product Gallery
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex">
                                        <UploadProductPic
                                            width={'w-36 mr-10'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            disabled={true}
                                            setGalleryImage={setGallery1Image}
                                            imageProp={gallery1}
                                        />
                                        <UploadProductPic
                                            width={'w-36 mr-10'}
                                            height={'h-36'}
                                            isEdit={true}
                                            disabled={true}
                                            isGallery={true}
                                            setGalleryImage={setGallery2Image}
                                            imageProp={gallery2}
                                        />
                                        <UploadProductPic
                                            width={'w-36'}
                                            height={'h-36'}
                                            isEdit={true}
                                            disabled={true}
                                            isGallery={true}
                                            setGalleryImage={setGallery3Image}
                                            imageProp={gallery3}
                                        />
                                    </div>
                                    <div className="flex mt-8">
                                        <UploadProductPic
                                            width={'w-36 mr-10'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            disabled={true}
                                            setGalleryImage={setGallery4Image}
                                            imageProp={gallery4}
                                        />
                                        <UploadProductPic
                                            width={'w-36 mr-10'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            disabled={true}
                                            setGalleryImage={setGallery5Image}
                                            imageProp={gallery5}
                                        />
                                        <UploadProductPic
                                            width={'w-36'}
                                            height={'h-36'}
                                            isEdit={true}
                                            isGallery={true}
                                            disabled={true}
                                            setGalleryImage={setGallery6Image}
                                            imageProp={gallery6}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full mb-10 px-4">
                            <div className="flex my-4">
                                <div className="mr-1 font-poppins">Out of stock image</div>
                            </div>

                            <UploadProductPic
                                width={'w-6/12'}
                                height={'h-80'}
                                isEdit={true}
                                placeholder={'Add a out of stock image in edit view'}
                                disabled={true}
                                inputId="out_of_stock"
                                setGalleryImage={setOutOfStockProductImage}
                                imageProp={product?.outOfStockImageUrl}
                            />
                        </div>
                        <div className="absolute w-10/12 right-0">
                            <div className={`flex flex-row  bg-gray-100 p-4 justify-end`}>
                                {product?.itemStatus === ItemStatus.DELETED ? null : (
                                    <Buttons
                                        name="Edit"
                                        type="button"
                                        buttonType="secondary-border-black"
                                        id="edit-button"
                                        size="e-small"
                                        other="mr-3"
                                        onclick={() => {
                                            history.push(`/products/general-edit/${product?.id}`);
                                        }}
                                    />
                                )}
                                <Buttons
                                    name="Close"
                                    type="submit"
                                    buttonType="primary"
                                    id="close-button"
                                    size="e-small"
                                    onclick={() => {
                                        handleSubmit();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
