/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { useState } from 'react';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { FETCH_CATEGORIES } from '../../../queries/CategoryQueries';
import { ADD_PRODUCTS, FETCH_PRODUCTS, FETCH_PRODUCTS_BY_ID } from '../../../queries/ItemQueries';
import { InputField } from '../../ui/atoms/InputField';
import { useHistory } from 'react-router';
import { FETCH_SUPPLIERS } from '../../../queries/SupplierQueries';
import {
    FAILURE_MESSAGE,
    SUCCESS_MESSAGE_PUBLISHED_PRODUCT_INSTANT_EDIT,
} from '../../../constants/product';
import Loader from '../../../utils/loader';
import { RootState } from '../../../redux/rootReducer';
import { useSelector } from 'react-redux';

export default function ViewProductInventory() {
    const history = useHistory();
    const [complete, setComplete] = useState(false);

    const [categoryData, setCategoryData] = useState([]);

    const { data: categoryQueryData } = useQuery(FETCH_CATEGORIES, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setCategoryData(categoryQueryData?.categories);
        },
    });
    const selectedProduct = useSelector((state: RootState) => state.itemIDState.itemID);

    const { data: itemData, loading: itemDataLoading } = useQuery(FETCH_PRODUCTS_BY_ID, {
        variables: { itemId: selectedProduct },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
    });
    const product = itemData?.item;

    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState('');

    const [saveItem, { loading: queryLoading, error: queryError, data: queryData }] = useMutation(
        ADD_PRODUCTS,
        {
            context: { clientName: ClientsEnum.STORE },
            refetchQueries: [FETCH_PRODUCTS],
            fetchPolicy: 'network-only',
            onCompleted: () => {
                setError(false);
                setMessage(SUCCESS_MESSAGE_PUBLISHED_PRODUCT_INSTANT_EDIT);
                setShowToast(true);
                setComplete(true);
                localStorage.removeItem('added_product');
            },
            onError: () => {
                setMessage(queryError?.message || FAILURE_MESSAGE);
                setShowToast(true);
                setError(true);
            },
        },
    );

    const { data: supplierData, loading } = useQuery(FETCH_SUPPLIERS, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
    });

    const selectedSupplierID = supplierData?.suppliers?.find((supplier) => {
        if (selectedSupplier) {
            return supplier?.supplierId === selectedSupplier;
        }
    });

    const defaultSupplier = supplierData?.suppliers?.filter((option: any) => {
        return option?.id === product?.supplier?.id;
    });

    const productTypeOptions = [
        {
            text: 'Physical',
            value: 'PHYSICAL',
        },
        {
            text: 'Virtual',
            value: 'VIRTUAL',
        },
    ];

    const StockStatus = [
        {
            text: 'AVAILABLE',
            value: 'AVAILABLE',
        },
        {
            text: 'UNAVAILABLE',
            value: 'UNAVAILABLE',
        },
    ];

    const categoriesDropDownList = categoryData?.map((category) => ({
        text: category.categoryName,
        value: category.categoryId,
    }));

    if (loading) return <Loader />;

    if (itemDataLoading) return <Loader />;

    if (!defaultSupplier[0]?.supplierId) return <Loader />;

    return (
        <Formik
            initialValues={{
                itemCode: '',
                itemName: '',
                itemType: '',
                itemDescBrief: '',
                itemImageUrl: '',
                itemGalleryUrls: [],
                itemDescDetail: '',
                deliveryMethod: '',
                stock: product?.stock,
            }}
            enableReinitialize
            onSubmit={() => {
                setError(false);
            }}
        >
            {({ values }) => (
                <>
                    <div className="w-full h-full flex-col justify-between">
                        <div className="text-xl font-poppins font-bold px-10">Inventory</div>

                        <div className="grid grid-cols-1 gap-4  xl:grid-cols-8 xl:gap-4 p-8 w-full px-10">
                            <div className="xl:col-start-1 xl:col-end-5 w-full ">
                                <div className="flex flex-wrap flex-col relative w-full">
                                    <div className="text-gray-500 w-16 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Supplier
                                    </div>
                                    <select
                                        className="px-4 py-3 rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                        id="code"
                                        disabled={true}
                                        defaultValue={defaultSupplier[0]?.supplierId}
                                    >
                                        {supplierData?.suppliers?.map((option: any, index) => {
                                            return (
                                                <option value={option.supplierId} key={index}>
                                                    {option.supplierId}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="xl:col-start-5 xl:col-span-4 ">
                                <InputField
                                    id="product_id"
                                    readonly={true}
                                    placeHolder="Product ID"
                                    name="Product Id"
                                    labelWidth="w-20"
                                    value={product?.itemId}
                                    opacity="bg-opacity-20"
                                />
                            </div>

                            <div className="xl:col-start-1 xl:col-span-4">
                                <InputField
                                    id="stock"
                                    placeHolder="Initial number In Stock"
                                    name="Initial Number In Stock"
                                    labelWidth="w-42"
                                    readonly={true}
                                    value={values.stock}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
