/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Dropdown, Icon } from 'semantic-ui-react';
import {
    UPDATE_ADMIN_PROFILE,
    FETCH_ADMIN_ONE_PROFILE_ID,
} from '../../../../queries/AdminProfileQueries';
import { countryOptions } from '../../../../utils/countries';
import Loader from '../../../../utils/loader';
import { Buttons } from '../../../ui/atoms/Button';
import { InputField } from '../../../ui/atoms/InputField';
import { Images } from '../../../ui/atoms/Images';
import UnsaveChangesWarning from '../../../templates/modals/UnsaveChangesWarning';
import { getCookie } from '../../../../utils/cookiesService';
import jwtDecode from 'jwt-decode';

import { FETCH_PARTNER_DETAILS_BY_ID } from '../../../../queries/PartnerQueries';
import NormalLoader from '../../../../utils/normalLoader';
import {
    PRODUCT_ADD_EXIT_CONFIRM_MESSAGE,
    PRODUCT_ADD_EXIT_CONFIRM_TITLE,
} from '../../../../constants/product';
import { useDispatch, useSelector } from 'react-redux';
import { savePartnerName } from '../../../../redux/rootActions';
import { EXPORT_PARTNER_ITEMS } from '../../../../queries/ItemQueries';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import { ItemStatus } from '../../../../enums/item';
import { awsSDKConfig } from '../../../../constants/config/constants';
import AWS from 'aws-sdk';
import Toast from '../../../ui/atoms/Toast';
import { SORT_ASC } from '../../../../constants/common';
import { FETCH_POINTS_DATA } from '../../../../queries/PointsQueries';
import { FETCH_SUPPLIERS } from '../../../../queries/SupplierQueries';
import { RootState } from '../../../../redux/rootReducer';
import SuccessMessageModal from '../../../templates/modals/SuccessMessageModal';
import { PARTNER_ADD_SUCCESS_TITLE, PARTNER_EDIT_SUCCESS } from '../../../../constants/partner';

export default function EditPartnerGeneral() {
    const userDetailsSaved = useRef(null);
    const history = useHistory();
    const [selectedCode, setSelectedCode] = useState({});
    const [country, setCountry] = useState('');
    const [countryError, setCountryError] = useState(false);
    const [addPartnerError, setAddPartnerError] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [dbLoading, setDbLoading] = useState(false);
    const [downloadProductsLoading, setDownloadProductsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [error, setError] = useState(false);
    const [pointsData, setPointsData] = useState([]);
    const [showAddNewPartnerSuccess, setShowAddNewPartnerSuccess] = useState(false);
    const [processFinishedClicked, setProcessFinishedClicked] = useState(false);

    const token = getCookie('access_token');
    const tokenDetails: any = token ? jwtDecode(token) : null;

    const pointsOverrideSuccessData = useSelector((state: RootState) => state.store.updatedPoints);

    const { data: loggedInUserData } = useQuery(FETCH_ADMIN_ONE_PROFILE_ID, {
        fetchPolicy: 'network-only',
        variables: {
            userName: tokenDetails?.preferred_username,
        },
    });

    const { data: globalPointsData, loading: globalLoading } = useQuery(FETCH_POINTS_DATA, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
    });

    const { data: supplierData, loading: supplierLoading } = useQuery(FETCH_SUPPLIERS, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
    });

    const partnerId = localStorage.getItem('partnerId');
    const [partnerData, setPartnerData] = useState(null);

    const dispatch = useDispatch();

    const { loading: partnerDataLoading, error: partnerDataFetchError } = useQuery(
        FETCH_PARTNER_DETAILS_BY_ID,
        {
            variables: { partnerId: partnerId },
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                setPartnerData(data?.partner);
                dispatch(savePartnerName(data?.partner?.partnerName));
            },
        },
    );

    const [savePartner, { loading: queryLoading, error: queryError }] = useMutation(
        UPDATE_ADMIN_PROFILE,
        {
            onCompleted(data) {
                userDetailsSaved.current = true;
                setDbLoading(false);
                setIsDirty(false);
                setShowAddNewPartnerSuccess(true);
            },
            onError(error) {
                setAddPartnerError(true);
            },
        },
    );

    const [getPartnerItemsCSV, { loading: exportFileLoading }] = useLazyQuery(
        EXPORT_PARTNER_ITEMS,
        {
            context: { clientName: ClientsEnum.STORE },
            variables: {
                filter: {
                    partner: partnerId ? partnerId : null,
                    itemStatus: ItemStatus.PUBLISHED,
                },
                offset: 0,
                limit: null,
                order: SORT_ASC,
            },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                handleDownload(data?.exportItems?.filePath);
            },
            onError: (error) => {
                setError(true);
                setMessage(error?.message);
                setShowToast(true);
            },
        },
    );

    const handleDownload = (key) => {
        setDownloadProductsLoading(true);
        const s3 = new AWS.S3(awsSDKConfig);

        const params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: key,
        };

        function downloadBlob(blob, name = `${key}.csv`) {
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = name;
            document.body.appendChild(link);
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                }),
            );
            document.body.removeChild(link);
        }

        s3.getObject(params, (err, data) => {
            if (err) {
                console.error(err, err.stack);
            } else {
                const csvBlob = new Blob([data.Body.toString()], {
                    type: 'text/csv;charset=utf-8;',
                });
                const fileName = key?.split('/')[1];
                downloadBlob(csvBlob, `${fileName}`);
                setDownloadProductsLoading(false);
            }
        });
    };

    const validationSchema = Yup.object({
        partnerName: Yup.string().trim().required('Partner name is required'),
        phoneNumber: Yup.string()
            .required('Phone number cannot be blank')
            .min(7, 'The number of digits cannot be less than 7')
            .matches(/^[0-9]+$/, 'Enter a valid phone number')
            .max(15, 'The number of digits cannot exceed 15'),
        partnerEmail: Yup.string()
            .trim()
            .email('Email must be a valid email')
            .required('Email address is required'),
        markedUpPercentageForCurrency: Yup.number()
            .positive()
            .required('Marked up percentage for currency required')
            .label('Marked up percentage for currency')
            .moreThan(0)
            .max(100),
        markedUpPercentage: Yup.number()
            .positive()
            .required('Marked up percentage required')
            .label('Marked up percentage')
            .moreThan(0)
            .max(100),
        markedUpPercentageForPsp: Yup.number()
            .positive()
            .required('Marked up percentage for PSP currency required')
            .label('Marked up percentage for PSP currency')
            .moreThan(0)
            .max(100),
    });

    const code = countryOptions?.find((country: any) => {
        return country?.value === partnerData?.users[0]?.contact?.countryCode;
    });

    useEffect(() => {
        if (supplierData && globalPointsData) {
            const editedPointsData = supplierData?.suppliers?.map((supplier) => {
                const obj = globalPointsData?.globalPointsList?.filter(
                    (point) =>
                        point?.supplier?.supplierId === supplier?.supplierId &&
                        point?.partner?.id === partnerId,
                );

                let editedData = {};
                if (obj[0]) {
                    editedData = {
                        ...supplier,
                        conversionPoints: obj[0]?.overriddenValue || obj[0]?.defaultValue,
                        defaultValue: obj[0]?.defaultValue,
                        overriddenValue: obj[0]?.overriddenValue,
                    };
                } else {
                    editedData = {
                        ...supplier,
                        conversionPoints: 0,
                        defaultValue: 0,
                        overriddenValue: 0,
                    };
                }

                return editedData;
            });

            setPointsData(editedPointsData);
        }
    }, [globalPointsData, partnerId, pointsOverrideSuccessData?.defaultValue, supplierData]);

    useEffect(() => {
        if (country) {
            const selectedCountryCode = countryOptions?.filter((countryOption: any) => {
                return countryOption?.text === country;
            });
            setSelectedCode(selectedCountryCode);
        }
    }, [country]);

    useEffect(() => {
        if (processFinishedClicked) {
            history.push('/partners');
        }
    }, [history, processFinishedClicked]);

    const validateForGlobalPoints = () => {
        return !pointsData?.some((point) => point?.defaultValue === 0);
    };

    if (partnerDataLoading) return <Loader />;
    if (!partnerData) return <Loader />;

    return (
        <>
            <Formik
                initialValues={{
                    partnerName: partnerData?.partnerName,
                    partnerId: partnerData?.partnerId,
                    partnerURL: partnerData?.partnerUrl,
                    partnerEmail: partnerData?.users[0]?.contact?.primaryEmail,
                    phoneNumber: partnerData?.users[0]?.contact?.phoneNumber,
                    countryCode: partnerData?.users[0]?.contact?.countryCode || '',
                    markedUpPercentage: partnerData?.markedUpPercentage || 0.0,
                    markedUpPercentageForCurrency:
                        partnerData?.markedUpPercentageForCurrency || 0.0,
                    markedUpPercentageForPsp: partnerData?.markedUpPercentageForPsp || 0.0,
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={({
                    partnerName,
                    partnerId,
                    partnerURL,
                    partnerEmail,
                    phoneNumber,
                    markedUpPercentageForCurrency,
                    markedUpPercentage,
                    markedUpPercentageForPsp,
                }) => {
                    if (partnerData?.users[0]?.userName) {
                        savePartner({
                            variables: {
                                userId: partnerData?.users[0]?.userId,
                                userName: partnerData?.users[0]?.userName,
                                user: {
                                    firstName: loggedInUserData?.user?.firstName,
                                    lastName: loggedInUserData?.user?.lastName,
                                    contact: {
                                        primaryEmail: partnerData?.users[0]?.contact?.primaryEmail,
                                        recoveryEmail: partnerData?.users[0]?.contact?.primaryEmail,
                                        countryCode:
                                            selectedCode[0]?.value ||
                                            partnerData?.users[0]?.contact?.countryCode,
                                        phoneNumber:
                                            phoneNumber ||
                                            partnerData?.users[0]?.contact?.phoneNumber,
                                    },
                                    partner: {
                                        partnerId: partnerId,
                                        partnerName: partnerName || partnerData?.partnerName,
                                        partnerUrl: partnerURL || partnerData?.partnerUrl,
                                        creditLimit: partnerData?.creditLimit,
                                        createdBy: loggedInUserData?.user?.userId,
                                        modifiedBy: 0,
                                        isActive: partnerData?.isActive,
                                        partnerAgreements: partnerData?.partnerAgreements?.map(
                                            (agreement) => ({
                                                id: agreement?.id,
                                                url: agreement?.url,
                                                isActive: agreement?.isActive,
                                                fileName: agreement?.fileName,
                                                fileSize: agreement?.fileSize,
                                                fileType: agreement?.fileType,
                                            }),
                                        ),
                                        markedUpPercentageForCurrency:
                                            markedUpPercentageForCurrency,
                                        markedUpPercentage: markedUpPercentage,
                                        markedUpPercentageForPsp: markedUpPercentageForPsp,
                                    },
                                },
                            },
                        });
                    }
                }}
            >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                    <div className="h-full flex flex-col  justify-evens">
                        {showToast && (
                            <Toast
                                setShowToast={setShowToast}
                                message={message}
                                width="w-8/12"
                                error={error}
                                selfDestruct={true}
                                selfDestructTimer={5000}
                            />
                        )}
                        <div className="flex flex-row">
                            <div className="flex text-lg font-poppins font-bold px-8">General</div>
                            {partnerData?.isActive && (
                                <div className="flex flex-grow py-2 px-4 justify-end sm:text-sm">
                                    <Buttons
                                        name={
                                            downloadProductsLoading || exportFileLoading ? (
                                                <NormalLoader />
                                            ) : (
                                                'Download Products'
                                            )
                                        }
                                        type="submit"
                                        buttonType="primary"
                                        id="update"
                                        size="e-small"
                                        onclick={() => {
                                            getPartnerItemsCSV();
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="grid grid-cols-8 gap-4 mt-8 px-8">
                            <div className="col-start-1 col-end-4">
                                <InputField
                                    id="partnerName"
                                    placeHolder={'Partner name'}
                                    name="Partner name"
                                    labelWidth="w-21"
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                    }}
                                    value={values.partnerName}
                                />
                                {errors.partnerName && touched.partnerName ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.partnerName}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-end-7 col-span-3">
                                <InputField
                                    id="partnerId"
                                    placeHolder="Partner Id"
                                    name="Partner ID"
                                    labelWidth="w-21"
                                    value={values.partnerId}
                                    readonly={true}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                    }}
                                />
                            </div>
                            <div className="col-start-1 col-end-4">
                                <InputField
                                    id="partnerURL"
                                    placeHolder={'Partner URL'}
                                    name="Partner URL"
                                    labelWidth="w-21"
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                    }}
                                    value={values.partnerURL}
                                />
                                {errors.partnerURL && touched.partnerURL ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.partnerURL}
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-start-4 col-span-3 mt-0.5">
                                <div className="flex flex-wrap flex-col relative lg:mb-5 ">
                                    <div className="text-gray-500 w-16 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Country
                                    </div>

                                    <Dropdown
                                        className="dropdownProfile"
                                        placeholder="Select country"
                                        id="countryCode"
                                        fluid
                                        key={'countryCode'}
                                        wrapSelection
                                        selection
                                        defaultValue={values.countryCode}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );

                                            setIsDirty(true);
                                        }}
                                        onKeyDown={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                            setIsDirty(true);
                                        }}
                                        onSearchChange={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                            setIsDirty(true);
                                        }}
                                        onKeyUp={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                            setIsDirty(true);
                                        }}
                                        options={countryOptions}
                                        pointing="top"
                                        icon={
                                            <Icon
                                                name="chevron down"
                                                className="iconChevron"
                                            ></Icon>
                                        }
                                    />
                                </div>
                                {countryError ? (
                                    <div className="text-red-500 text-xs -mt-3 md:text-sm">
                                        {'Country should not be empty'}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <hr className="px-8 border" />
                        <div className="text-lg font-poppins font-bold pt-8 px-8">
                            Contact Information
                        </div>
                        <div className="grid grid-cols-8 gap-4 mt-8 px-8">
                            <div className="col-start-1 col-end-4">
                                <InputField
                                    id="partnerEmail"
                                    placeHolder={'Email Address'}
                                    name="Email address"
                                    labelWidth="w-21"
                                    readonly
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                    }}
                                    value={values.partnerEmail}
                                />
                                {errors.partnerEmail && touched.partnerEmail ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.partnerEmail}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-start-1 col-span-1 lg:col-end-5 lg:col-span-1 ">
                                <div className="flex flex-wrap flex-col relative mt-1.5">
                                    <select
                                        className="p-4 lg:p-3  rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500 h-14"
                                        defaultValue={code?.dial_value}
                                        disabled={true}
                                        id="code"
                                        value={
                                            selectedCode
                                                ? selectedCode[0]?.dial_value
                                                : code?.dial_value
                                        }
                                    >
                                        {countryOptions.map((country: any, index: any) => {
                                            return (
                                                <option value={country.dial_value} key={index}>
                                                    {country.dial_value}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className=" col-start-3 col-span-4  lg:col-end-7 lg:col-span-2">
                                <InputField
                                    id="phoneNumber"
                                    placeHolder="Phone Number"
                                    labelWidth="w-32"
                                    name="Phone Number"
                                    value={values?.phoneNumber}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                    }}
                                />
                                {errors.phoneNumber && touched.phoneNumber ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.phoneNumber}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {addPartnerError && (
                            <div className="flex flex-1 flex-col align-middle items-center justify-center px-8 lg:mt-32 md:mt-28 sm:mt-16">
                                <div className="h-full align-middle my-2">
                                    <Images
                                        width="w-16"
                                        height="h-16"
                                        src="/images/icons/error-svg.svg"
                                        alt="success"
                                    />
                                </div>
                                <div className="h-full align-middle my-4 text-center font-poppins text-base text-red-500">
                                    Oops something went wrong
                                </div>
                                <div
                                    role="presentation"
                                    className="h-full align-middle my-4 text-center font-poppins text-base text-purple-500 underline cursor-pointer"
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >
                                    Try again
                                </div>
                            </div>
                        )}

                        <hr className="px-8 border mt-5" />

                        <>
                            <div className="text-lg font-poppins font-bold px-8 mt-8">
                                Marked-up Price Calculation
                            </div>
                            <div className="grid grid-cols-8 gap-4 mt-8 px-8 mb-40">
                                <div className="col-start-1 col-span-3 flex flex-col">
                                    <div className="flex gap-2">
                                        <InputField
                                            id="markedUpPercentageForCurrency"
                                            placeHolder={'0'}
                                            name="Marked-up Percentage (DCB Currency)"
                                            labelWidth="w-21"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setIsDirty(true);
                                            }}
                                            value={values.markedUpPercentageForCurrency}
                                            type="number"
                                            min="0"
                                        />
                                        <div className="col-span-1 my-auto font-poppins text-2xl">{`%`}</div>
                                    </div>
                                    {errors.markedUpPercentageForCurrency &&
                                    touched.markedUpPercentageForCurrency ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {errors.markedUpPercentageForCurrency}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-start-4 col-span-3 flex flex-col">
                                    <div className="flex gap-2">
                                        <InputField
                                            id="markedUpPercentage"
                                            placeHolder={'0'}
                                            name="Marked-up Percentage (Points)"
                                            labelWidth="w-21"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setIsDirty(true);
                                            }}
                                            value={values.markedUpPercentage}
                                            type="number"
                                            min="0"
                                        />
                                        <div className="col-span-1 my-auto font-poppins text-2xl">{`%`}</div>
                                    </div>
                                    {errors.markedUpPercentage && touched.markedUpPercentage ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {errors.markedUpPercentage}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-start-1 col-span-3 flex flex-col">
                                    <div className="flex gap-2">
                                        <InputField
                                            id="markedUpPercentageForPsp"
                                            placeHolder={'0'}
                                            name="Marked-up Percentage (PSP Currency)"
                                            labelWidth="w-21"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setIsDirty(true);
                                            }}
                                            value={values.markedUpPercentageForPsp}
                                            type="number"
                                            min="0"
                                        />
                                        <div className="col-span-1 my-auto font-poppins text-2xl">{`%`}</div>
                                    </div>
                                    {errors.markedUpPercentageForPsp &&
                                    touched.markedUpPercentageForPsp ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {errors.markedUpPercentageForPsp}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </>
                        <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0 z-20">
                            <Buttons
                                name="Cancel"
                                type="button"
                                buttonType="secondary-border-black"
                                id="cancel-button"
                                size="e-small"
                                other="mr-3"
                                onclick={() => {
                                    history.push('/partners');
                                }}
                            />
                            <Buttons
                                name={dbLoading ? <NormalLoader /> : 'Save'}
                                type="submit"
                                buttonType="primary"
                                id="save-button"
                                size="e-small"
                                onclick={() => {
                                    handleSubmit();
                                }}
                            />
                        </div>
                        <SuccessMessageModal
                            showSuccessModal={showAddNewPartnerSuccess}
                            setShowSuccessModal={setShowAddNewPartnerSuccess}
                            successModalTitle={PARTNER_ADD_SUCCESS_TITLE}
                            successModalBody={PARTNER_EDIT_SUCCESS}
                            setProcessFinishedClicked={setProcessFinishedClicked}
                        />
                        <UnsaveChangesWarning
                            contentText={PRODUCT_ADD_EXIT_CONFIRM_MESSAGE}
                            messageTitle={PRODUCT_ADD_EXIT_CONFIRM_TITLE}
                            when={isDirty || window.onbeforeunload ? true : false}
                            navigate={(path) => history.push(path)}
                            shouldBlockNavigation={(location) => {
                                if (isDirty) {
                                    return true;
                                }
                                return false;
                            }}
                            displayIcon={true}
                            displayIconName={'clarity_error-standard-line-svg.svg'}
                        />
                    </div>
                )}
            </Formik>
        </>
    );
}
