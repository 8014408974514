/* eslint-disable @typescript-eslint/no-unused-vars */

import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Dropdown } from 'semantic-ui-react';
import {
    UPDATE_ADMIN_PROFILE,
    FETCH_ADMIN_ONE_PROFILE,
} from '../../../queries/AdminProfileQueries';
import {
    createUser,
    getAllUserDetails,
    getRoles,
    resetPasswordAction,
} from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { countryOptions } from '../../../utils/countries';
import Loader from '../../../utils/loader';
import UserAddedSuccessModal from '../../templates/modals/UserAddedSuccessModal';
import { Buttons } from '../../ui/atoms/Button';
import { InputField } from '../../ui/atoms/InputField';
import NormalLoader from '../../../utils/normalLoader';

export default function AddNewUserScreen() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [keyClockUpdated, setKeyClockUpdateCompleted] = useState(false);
    const dispatch = useDispatch();
    const [country, setCountry] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [role, setRole] = useState('');
    const [roleError, setRoleError] = useState(false);
    const [dbLoading, setDbLoading] = useState(false);

    const [countryError, setCountryError] = useState(false);

    const [showAddNewUserSuccess, setShowAddNewUserSuccess] = useState(false);

    useEffect(() => {
        dispatch(getRoles());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllUserDetails());
    }, [dispatch]);

    const [updateUser, { loading: queryLoading, error: queryError }] = useMutation(
        UPDATE_ADMIN_PROFILE,
        {
            refetchQueries: [FETCH_ADMIN_ONE_PROFILE],
            onCompleted() {
                setDbLoading(false);
            },
        },
    );

    const keyCloakUserDetails = useSelector((state: RootState) => state.auth.usersList);

    const roles = useSelector((state: RootState) => state.auth.roles);
    const currentTimeInMille = new Date().valueOf();
    const isNewUserEmailSent = useSelector((state: RootState) => state.auth.isEmailSent);

    useEffect(() => {
        if (country) setCountryError(false);
        if (role) setRoleError(false);
    }, [country, role]);

    let selectedCode;

    if (country) {
        selectedCode = countryOptions?.filter((countryOption: any) => {
            return countryOption?.text === country;
        });
    }
    const validationSchema = Yup.object({
        firstName: Yup.string().trim().required('First name is required'),
        lastName: Yup.string().trim().required('Last name is required'),
        email: Yup.string()
            .trim()
            .email('Email must be a valid email')
            .required('Email address is required'),
        phoneNumber: Yup.string()
            .required('Phone number cannot be blank')
            .min(9, 'Min digits should be 9')
            .matches(/^[0-9]+$/, 'Phone number must be number')
            .max(15, 'Must be exactly 10 digits'),
    });
    return (
        <Formik
            initialValues={{
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={({ firstName, lastName, phoneNumber, email }) => {
                setLoading(true);
                dispatch(createUser(currentTimeInMille, email));
                dispatch(resetPasswordAction(email));
                setCountryCode(selectedCode[0]?.value);
                setKeyClockUpdateCompleted(true);
                setCountryError(false);
                setRoleError(false);
                setDbLoading(true);
                dispatch(getAllUserDetails());
                setTimeout(() => {
                    const userDetailFromKeyClock = keyCloakUserDetails?.filter(
                        (user) => user?.email === email,
                    );

                    const userName = userDetailFromKeyClock[0]?.username;

                    if (userName) {
                        updateUser({
                            variables: {
                                userName: userName,
                                user: {
                                    firstName: firstName,
                                    lastName: lastName,
                                    profilePic: '',
                                    contact: {
                                        primaryEmail: email,
                                        recoveryEmail: email,
                                        countryCode: countryCode,
                                        phoneNumber: phoneNumber,
                                    },
                                },
                            },
                        });
                    }
                    setShowAddNewUserSuccess(true);
                }, 4000);
            }}
        >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                <div className="h-full flex flex-col  justify-evens">
                    <div className="text-lg font-poppins font-bold px-4 ">User Details</div>
                    <div className="mb-64">
                        <div className="grid grid-cols-8 gap-4 mt-5">
                            <div className="col-start-1 col-end-4">
                                <InputField
                                    id="firstName"
                                    placeHolder={'firstName'}
                                    name="First name"
                                    labelWidth="w-21"
                                    onChange={handleChange}
                                    value={values.firstName}
                                />
                                {errors.firstName && touched.firstName ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.firstName}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-end-7 col-span-3">
                                <InputField
                                    id="lastName"
                                    placeHolder="Walker"
                                    name=" Last name"
                                    labelWidth="w-21"
                                    value={values.lastName}
                                    onChange={handleChange}
                                />
                                {errors.lastName && touched.lastName ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.lastName}
                                    </div>
                                ) : null}
                            </div>
                            {isNewUserEmailSent ? setLoading(false) : null}
                            {queryLoading ? <Loader /> : null}
                            <div className="col-start-1 col-end-4 mt-1">
                                <div className="flex flex-wrap flex-col relative lg:mb-5 z-50 ">
                                    <div className="text-gray-500 w-28 text-center font-poppins text-sm lg:text-sm h-max bg-white z-50 -mb-3 ml-4 border-opacity-20">
                                        Select country
                                    </div>

                                    <Dropdown
                                        className="dropdownRed"
                                        placeholder="Select country"
                                        id="countryCode"
                                        fluid
                                        search
                                        key={'countryCode'}
                                        selection
                                        onChange={(e) => {
                                            handleChange(e);
                                            setCountry(e.target['outerText']);
                                        }}
                                        options={countryOptions}
                                    />
                                </div>
                                {countryError ? (
                                    <div className="text-red-500 text-xs -mt-3 md:text-sm">
                                        {'Country should not be empty'}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-start-1 col-span-1 lg:col-end-5 lg:col-span-1 ">
                                <div className="flex flex-wrap flex-col relative mt-3">
                                    <select
                                        className="p-4 lg:p-3  rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                        disabled={true}
                                        id="code"
                                        value={
                                            selectedCode?.length
                                                ? selectedCode[0]?.dial_value
                                                : null
                                        }
                                    >
                                        {countryOptions.map((country: any, index: any) => {
                                            return (
                                                <option value={country.dial_value} key={index}>
                                                    {country.dial_value}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className=" col-start-3 col-span-4  lg:col-end-7 lg:col-span-2 mt-0.5">
                                <InputField
                                    id="phoneNumber"
                                    placeHolder="phoneNumber"
                                    labelWidth="w-32"
                                    name="Contact number"
                                    value={values?.phoneNumber}
                                    onChange={handleChange}
                                />
                                {errors.phoneNumber && touched.phoneNumber ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.phoneNumber}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="text-lg font-poppins font-bold px-4 mt-10 ">
                            Account management
                        </div>
                        <div className="grid grid-cols-8 gap-4 mt-5">
                            <div className="col-start-1 col-end-4 -mt-1">
                                <InputField
                                    id="email"
                                    placeHolder="Lucy@gmail.com"
                                    name="Email address"
                                    labelWidth="w-28"
                                    onChange={handleChange}
                                    value={values.email}
                                />
                                {errors.email && touched.email ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.email}
                                    </div>
                                ) : null}
                            </div>

                            <UserAddedSuccessModal
                                setShowAddNewUserSuccess={setShowAddNewUserSuccess}
                                showAddNewUserSuccess={showAddNewUserSuccess}
                            />

                            <div className="col-end-7 col-span-3">
                                <div className="flex flex-wrap flex-col relative -mt-1 ">
                                    <div className="text-gray-500 w-20 text-center font-poppins text-sm lg:text-sm h-max bg-white z-50 -mb-3 ml-4 border-opacity-20">
                                        User role
                                    </div>
                                    <select
                                        className="p-4 lg:p-3   rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                        defaultValue={''}
                                        id="role"
                                        onChange={(event) => {
                                            setRole(event.target.value);
                                            handleChange('role');
                                        }}
                                        onBlur={() => handleBlur('role')}
                                    >
                                        {roles?.map((role: any) => {
                                            return (
                                                <option key={role.id} value={role.name}>
                                                    {role.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {roleError ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {'Role should not be empty'}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="-mt-10 mb-8"> {dbLoading ? <Loader /> : null}</div>
                    <div className="w-full py-4 justify-end bg-gray-100  flex px-4">
                        <Buttons
                            name="Cancel"
                            type="button"
                            buttonType="secondary-border-black"
                            id="Cancel"
                            size="e-small"
                            other="mr-3"
                            onclick={() => {
                                history.push('/users');
                            }}
                        />
                        <Buttons
                            name={
                                loading ? (
                                    <NormalLoader />
                                ) : queryLoading ? (
                                    <NormalLoader />
                                ) : (
                                    'Add new user'
                                )
                            }
                            type="submit"
                            buttonType="primary"
                            id="update"
                            size="e-small"
                            onclick={() => {
                                if (!country) setCountryError(true);
                                if (!role) setRoleError(true);
                                handleSubmit();
                            }}
                        />
                    </div>
                </div>
            )}
        </Formik>
    );
}
