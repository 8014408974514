import React, { ReactElement } from 'react';
import { SWAR_MVP_HIDE_FEATURE } from '../../../constants/config/constants';

interface Props {
    selectedTab?: number;
    isViewMode?: boolean;
    setSelectedTab?: (value: number) => void;
    reportsView?: boolean;
    productCounts?: {
        all: number;
        published: number;
        draft: number;
        onHold: number;
        trashed: number;
    };
    usersCounts?: {
        active: number;
        invitedUsers: number;
        inactive: number;
    };
    categoryCounts?: {
        all: number;
        published: number;
        unpublished: number;
        trashed: number;
    };
    categoryProductsCounts?: {
        all: number;
        selected: number;
    };
    orderCounts?: {
        all: number;
        completed: number;
        processing: number;
        cancelled: number;
        refunded: number;
        trashed: number;
    };
    supplierCounts?: {
        all: number;
        active: number;
        inActive: number;
        trashed: number;
    };
}

export default function TableTabView({
    selectedTab,
    setSelectedTab,
    productCounts,
    categoryCounts,
    orderCounts,
    usersCounts,
    isViewMode,
    categoryProductsCounts,
    reportsView,
    supplierCounts,
}: Props): ReactElement {
    return (
        <>
            {productCounts ? (
                <>
                    <div className="flex flex-row mr-8">
                        <div className={`flex mr-0`}>
                            <div
                                className={`flex relative cursor-pointer border-b-4 pr-4 py-2 ${
                                    selectedTab === 1 ? `border-purple-500` : 'border-snow-500'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(1)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg justify-start bottom-2 flex w-full ${
                                        selectedTab === 1
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    All{' '}
                                    {productCounts?.all < 10 && productCounts?.all > 0
                                        ? `(0${productCounts?.all})`
                                        : `(${productCounts?.all})`}
                                </div>
                            </div>
                            <div
                                className={`flex relative cursor-pointer border-b-4 px-6 py-2 ${
                                    selectedTab === 2 ? `border-purple-500` : 'border-snow-500'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(2)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg justify-center bottom-2 flex w-full ${
                                        selectedTab === 2
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    <div>
                                        Published{' '}
                                        {productCounts?.published < 10 &&
                                        productCounts?.published > 0
                                            ? `(0${productCounts?.published})`
                                            : `(${productCounts?.published})`}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`flex relative cursor-pointer border-b-4 px-6 py-2 ${
                                    selectedTab === 3 ? `border-purple-500` : 'border-snow-200'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(3)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg justify-center bottom-2 flex w-full ${
                                        selectedTab === 3
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    <div>
                                        Unpublished{' '}
                                        {productCounts?.draft < 10 && productCounts?.draft > 0
                                            ? `(0${productCounts?.draft})`
                                            : `(${productCounts?.draft})`}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`flex relative cursor-pointer border-b-4 px-4 py-2 ${
                                    selectedTab === 5 ? `border-purple-500` : ''
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(5)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg justify-center bottom-2 flex w-full ${
                                        selectedTab === 4
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    <div>
                                        Trashed{' '}
                                        {productCounts?.trashed < 10 && productCounts?.trashed > 0
                                            ? `(0${productCounts?.trashed})`
                                            : `(${productCounts?.trashed})`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`flex flex-grow mr-0 border-b-4 h-12 lg:h-14`}></div>
                    </div>
                </>
            ) : supplierCounts ? (
                <>
                    <div className="flex flex-row mr-8">
                        <div className={`flex mr-0`}>
                            <div
                                className={`flex relative cursor-pointer border-b-4 pr-4 py-2 ${
                                    selectedTab === 1 ? `border-purple-500` : 'border-snow-500'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(1)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg justify-start bottom-2 flex w-full ${
                                        selectedTab === 1
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    All{' '}
                                    {supplierCounts?.all < 10 && supplierCounts?.all > 0
                                        ? `(0${supplierCounts?.all})`
                                        : `(${supplierCounts?.all})`}
                                </div>
                            </div>
                            <div
                                className={`flex relative cursor-pointer border-b-4 px-6 py-2 ${
                                    selectedTab === 2 ? `border-purple-500` : 'border-snow-500'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(2)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg justify-center bottom-2 flex w-full ${
                                        selectedTab === 2
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    <div>
                                        Active{' '}
                                        {supplierCounts?.active < 10 && supplierCounts?.active > 0
                                            ? `(0${supplierCounts?.active})`
                                            : `(${supplierCounts?.active})`}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`flex relative cursor-pointer border-b-4 px-6 py-2 ${
                                    selectedTab === 3 ? `border-purple-500` : 'border-snow-200'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(3)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg justify-center bottom-2 flex w-full ${
                                        selectedTab === 3
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    <div>
                                        Inactive{' '}
                                        {supplierCounts?.inActive < 10 &&
                                        supplierCounts?.inActive > 0
                                            ? `(0${supplierCounts?.inActive})`
                                            : `(${supplierCounts?.inActive})`}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`flex relative cursor-pointer border-b-4 px-4 py-2 ${
                                    selectedTab === 4 ? `border-purple-500` : ''
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(4)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg justify-center bottom-2 flex w-full ${
                                        selectedTab === 4
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    <div>
                                        Trashed{' '}
                                        {supplierCounts?.trashed < 10 && supplierCounts?.trashed > 0
                                            ? `(0${supplierCounts?.trashed})`
                                            : `(${supplierCounts?.trashed})`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`flex flex-grow mr-0 border-b-4 h-12 lg:h-14`}></div>
                    </div>
                </>
            ) : reportsView ? (
                <>
                    <div className="flex flex-wrap items-center mt-4 mr-8">
                        <div className={`flex flex-grow w-1/2 md:w-1/2 lg:w-1/2 mt-6`}>
                            <div
                                className={`flex relative cursor-pointer w-1/6 md:w-1/6 lg:w-1/12 border-b-4 ${
                                    selectedTab === 1 ? `border-purple-500` : 'border-snow-500'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(1)}
                            >
                                <div
                                    className={`text-sm md:text-base ml-1 lg:text-lg absolute bottom-2 flex w-full ${
                                        selectedTab === 1
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    <div className=" mx-auto">Sales</div>
                                </div>
                            </div>
                            <div
                                className={`flex relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/12 border-b-4 ${
                                    selectedTab === 3 ? `border-purple-500` : 'border-snow-500'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(3)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg absolute  mx-auto bottom-2 flex w-full ${
                                        selectedTab === 3
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    <div className=" mx-auto">Cost</div>
                                </div>
                            </div>
                            <div
                                className={`flex relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/12 border-b-4 ${
                                    selectedTab === 4 ? `border-purple-500` : ''
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(4)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg absolute bottom-2 flex w-full ${
                                        selectedTab === 4
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    <div className=" mx-auto">Refunded</div>
                                </div>
                            </div>
                            <div
                                className={`flex relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/12 border-b-4 ${
                                    selectedTab === 5 ? `border-purple-500` : ''
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(5)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg  absolute bottom-2 flex w-full ${
                                        selectedTab === 5
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    <div className=" mx-auto">Stocks</div>
                                </div>
                            </div>

                            <div
                                className={`flex flex-grow w-1/2 md:w-1/2 lg:w-1/2 mr-0 border-b-4 mt-6`}
                            ></div>
                        </div>
                    </div>
                </>
            ) : orderCounts ? (
                <>
                    <div className="flex flex-wrap items-center mt-4 mr-8">
                        <div className={`flex flex-grow w-1/2 md:w-1/2 lg:w-1/2 mt-6`}>
                            <div
                                className={`flex relative cursor-pointer w-1/6 md:w-1/6 lg:w-1/12 border-b-4 ${
                                    selectedTab === 1 ? `border-purple-500` : 'border-snow-500'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(1)}
                            >
                                <div
                                    className={`text-sm md:text-base ml-1 lg:text-lg absolute bottom-2 flex w-full ${
                                        selectedTab === 1
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    All{' '}
                                    {orderCounts?.all < 10 && orderCounts?.all > 0
                                        ? `(0${orderCounts?.all})`
                                        : `(${orderCounts?.all})`}
                                </div>
                            </div>
                            <div
                                className={`flex relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/6 border-b-4 ${
                                    selectedTab === 2 ? `border-purple-500` : 'border-snow-500'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(2)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg absolute ml-1 bottom-2 flex justify-center w-full ${
                                        selectedTab === 2
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    Completed{' '}
                                    {orderCounts?.completed < 10 && orderCounts?.completed > 0
                                        ? `(0${orderCounts?.completed})`
                                        : `(${orderCounts?.completed})`}
                                </div>
                            </div>
                            <div
                                className={`flex relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/6 border-b-4 ${
                                    selectedTab === 3 ? `border-purple-500` : 'border-snow-500'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(3)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg absolute ml-1 bottom-2 flex justify-center w-full ${
                                        selectedTab === 3
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    Processing{' '}
                                    {orderCounts?.processing < 10 && orderCounts?.processing > 0
                                        ? `(0${orderCounts?.processing})`
                                        : `(${orderCounts?.processing})`}
                                </div>
                            </div>
                            <div
                                className={`flex relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/6 border-b-4 ${
                                    selectedTab === 4 ? `border-purple-500` : ''
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(4)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg absolute bottom-2 flex justify-center w-full ${
                                        selectedTab === 4
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    <div className="text-center ml-1">
                                        Cancelled{' '}
                                        {orderCounts?.cancelled < 10 && orderCounts?.cancelled > 0
                                            ? `(0${orderCounts?.cancelled})`
                                            : `(${orderCounts?.cancelled})`}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`flex relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/6 border-b-4 ${
                                    selectedTab === 5 ? `border-purple-500` : ''
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(5)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg  absolute bottom-2 flex justify-center w-full ${
                                        selectedTab === 5
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    <div className="text-center ml-1">
                                        Refunded{' '}
                                        {orderCounts?.refunded < 10 && orderCounts?.refunded > 0
                                            ? `(0${orderCounts?.refunded})`
                                            : `(${orderCounts?.refunded})`}
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`flex flex-grow w-1/2 md:w-1/2 lg:w-1/2 mr-0 border-b-4 mt-6`}
                            ></div>
                        </div>
                    </div>
                </>
            ) : usersCounts ? (
                <>
                    <div className="flex flex-wrap items-center mt-4 mr-8">
                        <div className={`flex flex-grow w-1/2 md:w-1/2 lg:w-1/2 mt-6`}>
                            <div
                                className={`flex relative cursor-pointer w-1/6 md:w-1/6 lg:w-1/12 border-b-4 ${
                                    selectedTab === 1 ? `border-purple-500` : 'border-snow-500'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(1)}
                            >
                                <div
                                    className={`text-sm md:text-base ml-1 lg:text-lg absolute bottom-2 flex w-full ${
                                        selectedTab === 1
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    Active{' '}
                                    {usersCounts?.active < 10 && usersCounts?.active > 0
                                        ? `(0${usersCounts?.active})`
                                        : `(${usersCounts?.active})`}
                                </div>
                            </div>
                            <div
                                className={`flex relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/6 border-b-4 ${
                                    selectedTab === 2 ? `border-purple-500` : 'border-snow-500'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(2)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg absolute ml-1 bottom-2 flex w-full ${
                                        selectedTab === 2
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    Invited Users{' '}
                                    {usersCounts?.invitedUsers < 10 && usersCounts?.invitedUsers > 0
                                        ? `(0${usersCounts?.invitedUsers})`
                                        : `(${usersCounts?.invitedUsers})`}
                                </div>
                            </div>
                            {!SWAR_MVP_HIDE_FEATURE ? (
                                <div
                                    className={`flex relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/6 border-b-4 ${
                                        selectedTab === 3 ? `border-purple-500` : 'border-snow-500'
                                    }`}
                                    role="presentation"
                                    onClick={() => setSelectedTab(3)}
                                >
                                    <div
                                        className={`text-sm md:text-base lg:text-lg absolute ml-1 bottom-2 flex w-full ${
                                            selectedTab === 3
                                                ? `text-purple-500 font-semibold`
                                                : 'text-gray-500'
                                        }`}
                                    >
                                        Inactive{' '}
                                        {usersCounts?.inactive < 10 && usersCounts?.inactive > 0
                                            ? `(0${usersCounts?.inactive})`
                                            : `(${usersCounts?.inactive})`}
                                    </div>
                                </div>
                            ) : null}

                            <div
                                className={`flex flex-grow w-1/2 md:w-1/2 lg:w-1/2 mr-0 border-b-4 mt-6`}
                            ></div>
                        </div>
                    </div>
                </>
            ) : categoryProductsCounts ? (
                <>
                    {!isViewMode ? (
                        <div className="flex flex-wrap items-center mt-4 mr-8">
                            <div className={`flex flex-grow w-1/2 md:w-1/2 lg:w-1/2 mt-6`}>
                                <div
                                    className={`flex relative cursor-pointer w-1/6 md:w-1/6 lg:w-1/12 border-b-4 ${
                                        selectedTab === 1 ? `border-purple-500` : 'border-snow-500'
                                    }`}
                                    role="presentation"
                                    onClick={() => setSelectedTab(1)}
                                >
                                    <div
                                        className={`text-sm md:text-base ml-1 lg:text-lg absolute bottom-2 flex w-full ${
                                            selectedTab === 1
                                                ? `text-purple-500 font-semibold`
                                                : 'text-gray-500'
                                        }`}
                                    >
                                        All{' '}
                                        {categoryProductsCounts?.all < 10 &&
                                        categoryProductsCounts?.all > 0
                                            ? `(0${categoryProductsCounts?.all})`
                                            : `(${categoryProductsCounts?.all})`}
                                    </div>
                                </div>
                                <div
                                    id="selected-products-tab"
                                    className={`flex relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/6 border-b-4 ${
                                        selectedTab === 2 ? `border-purple-500` : 'border-snow-500'
                                    }`}
                                    role="presentation"
                                    onClick={() => setSelectedTab(2)}
                                >
                                    <div
                                        className={`text-sm md:text-base lg:text-lg absolute ml-1 bottom-2 flex w-full ${
                                            selectedTab === 2
                                                ? `text-purple-500 font-semibold`
                                                : 'text-gray-500'
                                        }`}
                                    >
                                        Selected products{' '}
                                        {categoryProductsCounts?.selected < 10 &&
                                        categoryProductsCounts?.selected > 0
                                            ? `(0${categoryProductsCounts?.selected})`
                                            : `(${categoryProductsCounts?.selected})`}
                                    </div>
                                </div>

                                <div
                                    className={`flex flex-grow w-1/2 md:w-1/2 lg:w-1/2 mr-0 border-b-4 mt-6`}
                                ></div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-wrap items-center mt-4 mr-8">
                            <div className={`flex flex-grow w-1/2 md:w-1/2 lg:w-1/2 mt-6`}>
                                <div
                                    className={`flex relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/6 border-b-4 ${
                                        selectedTab === 1 ? `border-purple-500` : 'border-snow-500'
                                    }`}
                                    role="presentation"
                                    onClick={() => setSelectedTab(1)}
                                >
                                    <div
                                        className={`text-sm md:text-base lg:text-lg absolute ml-1 bottom-2 flex w-full ${
                                            selectedTab === 1
                                                ? `text-purple-500 font-semibold`
                                                : 'text-gray-500'
                                        }`}
                                    >
                                        Selected products{' '}
                                        {categoryProductsCounts?.selected < 10 &&
                                        categoryProductsCounts?.selected > 0
                                            ? `(0${categoryProductsCounts?.selected})`
                                            : `(${categoryProductsCounts?.selected})`}
                                    </div>
                                </div>

                                <div
                                    className={`flex flex-grow w-1/2 md:w-1/2 lg:w-1/2 mr-0 border-b-4 mt-6`}
                                ></div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div className="flex flex-wrap items-center mt-4 mr-8">
                        <div className={`flex flex-grow w-1/2 md:w-1/2 lg:w-1/2 mt-6`}>
                            <div
                                className={`flex relative cursor-pointer border-b-4 pr-4 py-2 ${
                                    selectedTab === 1 ? `border-purple-500` : 'border-snow-500'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(1)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg justify-start bottom-2 flex w-full ${
                                        selectedTab === 1
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    All{' '}
                                    {categoryCounts?.all < 10 && categoryCounts?.all > 0
                                        ? `(0${categoryCounts?.all})`
                                        : `(${categoryCounts?.all})`}
                                </div>
                            </div>

                            <div
                                className={`flex relative cursor-pointer border-b-4 px-6 py-2 ${
                                    selectedTab === 2 ? `border-purple-500` : 'border-snow-200'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(2)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg justify-center bottom-2 flex w-full ${
                                        selectedTab === 2
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    Active{' '}
                                    {categoryCounts?.published < 10 && categoryCounts?.published > 0
                                        ? `(0${categoryCounts?.published})`
                                        : `(${categoryCounts?.published})`}
                                </div>
                            </div>

                            <div
                                className={`flex relative cursor-pointer border-b-4 px-6 py-2 ${
                                    selectedTab === 3 ? `border-purple-500` : 'border-snow-200'
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(3)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg justify-center bottom-2 flex w-full ${
                                        selectedTab === 3
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    Inactive{' '}
                                    {categoryCounts?.unpublished < 10 &&
                                    categoryCounts?.unpublished > 0
                                        ? `(0${categoryCounts?.unpublished})`
                                        : `(${categoryCounts?.unpublished})`}
                                </div>
                            </div>

                            <div
                                className={`flex relative cursor-pointer border-b-4 px-4 py-2 ${
                                    selectedTab === 5 ? `border-purple-500` : ''
                                }`}
                                role="presentation"
                                onClick={() => setSelectedTab(5)}
                            >
                                <div
                                    className={`text-sm md:text-base lg:text-lg justify-center bottom-2 flex w-full ${
                                        selectedTab === 5
                                            ? `text-purple-500 font-semibold`
                                            : 'text-gray-500'
                                    }`}
                                >
                                    <div>
                                        Trashed{' '}
                                        {categoryCounts?.trashed < 10 && categoryCounts?.trashed > 0
                                            ? `(0${categoryCounts?.trashed})`
                                            : `(${categoryCounts?.trashed})`}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`flex flex-grow w-1/2 md:w-1/2 lg:w-1/2 mr-0 border-b-4 mt-6`}
                            ></div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
