/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import '../../../styles/dropdown.css';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_SUPPLIERS, FETCH_SUPPLIERS } from '../../../queries/SupplierQueries';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import Loader from '../../../utils/loader';
import * as BsIcons from 'react-icons/bs';
import { pagination } from '../../../constants/config/constants';
import moment from 'moment';
import { Buttons } from '../../ui/atoms/Button';
import DataTable from '../../ui/organisms/DataTable';
import SearchBar from '../../ui/molecules/SearchBar';
import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import {
    NOT_REGISTERED,
    NOT_REGISTERED_WARNING_BODY,
    NOT_REGISTERED_WARNING_TITLE,
    REGISTERED,
} from '../../../constants/profile';
import { useHistory } from 'react-router-dom';
import {
    ALREADY_DELETED_WARNING_BODY,
    ALREADY_DELETED_WARNING_TITLE,
    DELETE_SUPPLIER_BODY,
    DELETE_SUPPLIER_TITLE,
    getSupplierProductsWarningMessage,
    INSTANT_EDIT,
    INSTANT_RESTORE,
    INSTANT_TRASH,
    INSTANT_VIEW,
    RESTORE_SUPPLIER_BODY,
    RESTORE_SUPPLIER_TITLE,
} from '../../../constants/supplier';
import Toast from '../../ui/atoms/Toast';
import { ItemStatus } from '../../../enums/item';
import ConfirmationModalWithMessage from '../../templates/modals/ConfirmationModalWithMessage';
import TableTabView from '../productScreens/TableTabView';
import { useDispatch } from 'react-redux';
import { localSaveCreatedSupplier } from '../../../redux/rootActions';

export default function SupplierScreen() {
    const [completeQuery, setCompleteQuery] = useState(false);
    const [success, setSuccess] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [suppliersData, setSuppliersData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [warningModalBody, setWarningModalBody] = useState('');
    const [warningModalTitle, setWarningModalTitle] = useState('');
    const [instantMenuItems, setInstantMenuItems] = useState([]);
    const [warningModalOkayClicked, setWarningModalOkayClicked] = useState(false);
    const [clickedSupplier, setClickedSupplier] = useState(null);
    const [suppliersDataInitial, setSupplierDataInitial] = useState([]);
    const [suppliersDataUnMutated, setSupplierDataUnMutaed] = useState([]);
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageSize, setPageSize] = useState(pagination?.pageSize);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [confirmationMessageTitle, setConfirmationMessageTitle] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [supplierCount, setSupplierCount] = useState({
        all: 0,
        active: 0,
        inActive: 0,
        trashed: 0,
    });
    const dispatch = useDispatch();

    const { data: supplierData, loading } = useQuery(FETCH_SUPPLIERS, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setSuppliersData(supplierData?.suppliers);
            setSupplierDataUnMutaed(supplierData?.suppliers);
            setSupplierDataInitial(supplierData?.suppliers);
            const productCounts = {
                all:
                    supplierData?.suppliers?.filter(
                        (suppliersDataItem) => !suppliersDataItem.isDeleted,
                    ).length <= 0
                        ? 0
                        : supplierData?.suppliers?.filter(
                              (suppliersDataItem) => !suppliersDataItem.isDeleted,
                          ).length,
                active: supplierData?.suppliers?.filter(
                    (suppliersDataItem) => suppliersDataItem.isActive,
                )?.length,
                inActive: supplierData?.suppliers?.filter(
                    (suppliersDataItem) =>
                        !suppliersDataItem.isActive && !suppliersDataItem.isDeleted,
                )?.length,
                trashed: supplierData?.suppliers?.filter(
                    (suppliersDataItem) =>
                        !suppliersDataItem.isActive && suppliersDataItem.isDeleted,
                )?.length,
            };

            setSupplierCount(productCounts);
            setCompleteQuery(true);
        },
    });

    const [selectedTab, setSelectedTab] = useState(1);

    const history = useHistory();

    const [saveSupplier, { loading: queryLoading, error: queryError }] = useMutation(
        ADD_SUPPLIERS,
        {
            refetchQueries: [FETCH_SUPPLIERS],
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                setShowConfirmationModal(false);
                setShowWarningModal(false);
                setMessage(`Successfully updated supplier ${data?.saveSupplier?.supplierName}`);
                setShowToast(true);
                setError(false);
                setTimeout(() => {
                    setShowToast(false);
                }, 1800);
            },
            onError: () => {
                setMessage(queryError?.message || 'Oops something went wrong');
                setShowToast(true);
                setError(true);
            },
        },
    );

    const filteredSuppliers = () => {
        if (selectedTab == 1) {
            return suppliersDataInitial?.filter(
                (suppliersDataItem) => !suppliersDataItem.isDeleted,
            );
        } else if (selectedTab == 2) {
            return suppliersDataInitial?.filter((suppliersDataItem) => suppliersDataItem.isActive);
        } else if (selectedTab == 3) {
            return suppliersDataInitial?.filter(
                (suppliersDataItem) => !suppliersDataItem.isActive && !suppliersDataItem.isDeleted,
            );
        } else {
            return suppliersDataInitial?.filter(
                (suppliersDataItem) => !suppliersDataItem.isActive && suppliersDataItem.isDeleted,
            );
        }
    };

    const handleSupplier = (supplierInfo) => {
        const isDeleted = selectedTab === 4 ? false : true;

        if (supplierInfo && supplierInfo?.job) {
            const supplierDataWithJob = {
                supplier: {
                    id: supplierInfo?.id,
                    supplierId: supplierInfo?.supplierId,
                    supplierName: supplierInfo?.supplierName,
                    isActive: false,
                    endpointUrl: supplierInfo?.endpointUrl,
                    creditLimit: supplierInfo?.creditLimit,
                    createdBy: supplierInfo?.createdBy,
                    isDeleted: isDeleted,
                    contact: {
                        contactId: supplierInfo?.contact?.contactId,
                        email: supplierInfo?.contact?.email,
                        contactNumber: supplierInfo?.contact?.contactNumber,
                        countryCode: supplierInfo?.contact?.countryCode,
                    },
                    configuration: {
                        id: supplierInfo?.configuration?.id,
                        name: supplierInfo?.configuration?.name,
                        config: supplierInfo?.configuration?.config,
                        supplierType: supplierInfo?.configuration?.supplierType,
                    },
                    job: {
                        id: supplierInfo?.job?.id,
                        jobId: supplierInfo?.job?.jobId,
                        jobName: supplierInfo?.job?.jobName,
                        className: supplierInfo?.job?.className,
                        cronDefinition: supplierInfo?.job?.cronDefinition,
                        description: supplierInfo?.job?.description,
                        jobType: supplierInfo?.job?.jobType,
                        schedulerMethodType: supplierInfo?.job?.schedulerMethodType,
                        isActive: supplierInfo?.job?.isActive,
                        lastTriggerTime: supplierInfo?.job?.lastTriggerTime,
                        executionTime: supplierInfo?.job?.executionTime,
                        lastTriggerStatus: supplierInfo?.job?.lastTriggerStatus,
                    },
                },
            };

            saveSupplier({
                variables: supplierDataWithJob,
            });
        } else {
            const supplierData = {
                supplier: {
                    id: supplierInfo?.id,
                    supplierId: supplierInfo?.supplierId,
                    supplierName: supplierInfo?.supplierName,
                    isActive: false,
                    endpointUrl: supplierInfo?.endpointUrl,
                    creditLimit: supplierInfo?.creditLimit,
                    createdBy: supplierInfo?.createdBy,
                    isDeleted: isDeleted,
                    contact: {
                        contactId: supplierInfo?.contact?.contactId,
                        email: supplierInfo?.contact?.email,
                        contactNumber: supplierInfo?.contact?.contactNumber,
                        countryCode: supplierInfo?.contact?.countryCode,
                    },
                    configuration: {
                        id: supplierInfo?.configuration?.id,
                        name: supplierInfo?.configuration?.name,
                        config: supplierInfo?.configuration?.config,
                        supplierType: supplierInfo?.configuration?.supplierType,
                    },
                },
            };

            saveSupplier({
                variables: supplierData,
            });
        }
    };

    useEffect(() => {
        const instantMenuList = [];

        instantMenuList.push({
            text: 'View',
            value: INSTANT_VIEW,
        });

        if (selectedTab === 4) {
            instantMenuList.push({
                text: 'Restore',
                value: INSTANT_RESTORE,
            });
        } else {
            instantMenuList.push(
                {
                    text: 'Edit',
                    value: INSTANT_EDIT,
                },
                {
                    text: 'Trash',
                    value: INSTANT_TRASH,
                },
            );
        }

        setInstantMenuItems(instantMenuList);
    }, [setInstantMenuItems, selectedTab]);

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const isRegistered = localStorage.getItem('isRegistered');

    useEffect(() => {
        if (warningModalOkayClicked && warningModalTitle !== ALREADY_DELETED_WARNING_TITLE) {
            history.push('/adminProfile');
        } else {
            setShowWarningModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, warningModalOkayClicked]);

    useEffect(() => {
        if (isRegistered === NOT_REGISTERED) {
            setWarningModalBody(NOT_REGISTERED_WARNING_BODY);
            setWarningModalTitle(NOT_REGISTERED_WARNING_TITLE);
            setShowWarningModal(true);
        } else if (
            selectedMenuItem === INSTANT_VIEW &&
            clickedSupplier &&
            isRegistered === REGISTERED
        ) {
            history.push({ pathname: '/suppliers-view', state: { supplier: clickedSupplier } });
        } else if (
            selectedMenuItem === INSTANT_EDIT &&
            clickedSupplier &&
            isRegistered === REGISTERED
        ) {
            localStorage.setItem('supplier_id', clickedSupplier?.id);
            localStorage.setItem('supplier_supplierId', clickedSupplier?.supplierId);
            localStorage.setItem('selectedSupplier', clickedSupplier);
            dispatch(localSaveCreatedSupplier(clickedSupplier));
            history.push({ pathname: '/suppliers-edit', state: { supplier: clickedSupplier } });
        } else if (
            selectedMenuItem === INSTANT_TRASH &&
            clickedSupplier &&
            isRegistered === REGISTERED
        ) {
            setSelectedMenuItem('');
            if (!clickedSupplier.isDeleted) {
                setConfirmationMessageTitle(DELETE_SUPPLIER_TITLE);
                if (clickedSupplier?.itemCount && clickedSupplier.itemCount > 0) {
                    setConfirmationMessage(
                        getSupplierProductsWarningMessage(clickedSupplier.itemCount),
                    );
                } else {
                    setConfirmationMessage(DELETE_SUPPLIER_BODY);
                }
                setShowConfirmationModal(true);
            } else {
                setWarningModalTitle(ALREADY_DELETED_WARNING_TITLE);
                setWarningModalBody(ALREADY_DELETED_WARNING_BODY);
                setShowWarningModal(true);
            }
        } else if (
            selectedMenuItem === INSTANT_RESTORE &&
            clickedSupplier &&
            isRegistered === REGISTERED
        ) {
            setConfirmationMessageTitle(RESTORE_SUPPLIER_TITLE);
            setConfirmationMessage(RESTORE_SUPPLIER_BODY);
            setShowConfirmationModal(true);
            setSelectedMenuItem('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMenuItem, clickedSupplier]);

    useEffect(() => {
        if (isRegistered === NOT_REGISTERED) {
            setWarningModalBody(NOT_REGISTERED_WARNING_BODY);
            setWarningModalTitle(NOT_REGISTERED_WARNING_TITLE);
            setShowWarningModal(true);
        }
    }, [isRegistered]);

    const startDate = new Date();
    const dateString = moment(startDate).format('YYYY-MM-DD');

    const supplierNames = suppliersDataUnMutated?.map((supplier) => supplier?.supplierName);
    const supplierIds = suppliersDataUnMutated?.map((supplier) => supplier?.supplierId);
    const suppliersDataArray = supplierNames.concat(supplierIds);

    useEffect(() => {
        const noOfPages = Math.ceil(suppliersDataInitial?.length / pageSize);
        const pageIndexFilteredData = suppliersDataInitial.slice(
            pageIndex * pageSize,
            pageIndex * pageSize + pageSize,
        );
        setPageCount(noOfPages);
        setSuppliersData(suppliersDataInitial);
        setFilteredData(suppliersDataInitial); //future need
    }, [
        setSuppliersData,
        suppliersDataInitial,
        pageCount,
        setPageCount,
        pageIndex,
        pageSize,
        setFilteredData,
    ]);
    useEffect(() => {
        if (searchTerm?.length > 0) {
            const supplierFilteredData = filteredSuppliers();
            const searchResult = supplierFilteredData?.filter((data) => {
                return (
                    data?.supplierName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    data?.supplierId.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });

            if (searchResult?.length) {
                setSuppliersData(searchResult);
            } else if (!searchResult?.length) {
                setSuppliersData(null);
            }
        } else {
            setSuppliersData(filteredSuppliers());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        setSuppliersData,
        suppliersDataInitial,
        searchTerm,
        filteredData,
        suppliersDataUnMutated,
        selectedTab,
    ]);

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                setSuccess(false);
            }, 2000);
        }
    }, [success]);

    useEffect(() => {
        if (searchTerm?.length === 0) {
            setSuppliersData(filteredSuppliers());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Supplier ID',
                accessor: 'supplierId',
            },
            {
                Header: 'Supplier name ',
                accessor: 'supplierName',
            },

            {
                id: 'productCount',
                Header: 'Product count',
                accessor: 'productCount',
            },

            {
                id: 'supplierType',
                Header: 'Supplier type',
                accessor: 'configuration',
                Cell: function showCount({ value }) {
                    return (
                        <span>
                            {value?.supplierType === 'CSV'
                                ? 'CSV'
                                : value?.supplierType === 'ALLSERV'
                                ? 'ALLSERV'
                                : 'API'}
                        </span>
                    );
                },
            },

            {
                Header: 'Created date',
                accessor: 'createdDate',
                Cell: function date({ value }) {
                    return <span>{value ? value.slice(0, 16) + '(UTC)' : null}</span>;
                },
            },
            {
                Header: 'Status',
                accessor: 'isActive',
                Cell: function date({ value }) {
                    const status = value ? 'Active' : 'Inactive';
                    return (
                        <span
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                status === 'Active'
                                    ? 'bg-green-100 text-green-600'
                                    : status === 'Inactive'
                                    ? 'bg-red-100 text-red-600'
                                    : ''
                            }`}
                        >
                            {status}
                        </span>
                    );
                },
            },
            {
                id: 'actionColumn',
                accessor: '',
                disableSortBy: true,
                width: 5,
                Cell: (
                    <div className="cursor__pointer">
                        <button
                            value="menu cursor-pointer"
                            className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none lg:ml-2 text-xl"
                        >
                            <BsIcons.BsThreeDots className="mx-auto" />
                        </button>
                    </div>
                ),
            },
        ],
        [],
    );

    return (
        <div className="border-2 border-gray-300 rounded-lg mr-7 mb-10">
            <WarningMessageModal
                showWarningModal={showWarningModal}
                setShowWarningModal={setShowWarningModal}
                warningModalBody={warningModalBody}
                warningModalTitle={warningModalTitle}
                setProcessFinishedClicked={setWarningModalOkayClicked}
            />
            <ConfirmationModalWithMessage
                showConfirmationModal={showConfirmationModal}
                setShowConfirmationModal={setShowConfirmationModal}
                message={confirmationMessage}
                messageTitle={confirmationMessageTitle}
                setYesBtnClick={() => handleSupplier(clickedSupplier)}
                setNoBtnClick={() => {
                    setShowConfirmationModal(false);
                    setSelectedMenuItem('');
                }}
                isProcessing={queryLoading}
            />
            {success ? (
                <Toast
                    setShowToast={setSuccess}
                    message={'Successfully created a supplier'}
                    width="w-10/12"
                    margin="ml-1"
                    error={false}
                />
            ) : null}
            {showToast && (
                <Toast
                    setShowToast={setShowToast}
                    message={message}
                    error={error}
                    margin="ml-1"
                    width="w-10/12"
                />
            )}
            <div className={`w-full ml-4 pt-4`}>
                <TableTabView
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    supplierCounts={supplierCount}
                />
            </div>
            <div className="flex p-4">
                <div className="flex w-full">
                    <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3">
                        <SearchBar
                            id="supplier-list-search-field"
                            options={suppliersDataArray}
                            handleChange={(e) => handleChange(e)}
                            placeHolder={'Search supplier by name or ID'}
                        />
                    </div>
                </div>

                <Buttons
                    name="Add new supplier"
                    type="submit"
                    buttonType="primary"
                    id="add-supplier-button"
                    size="e-small"
                    padding="py-2"
                    onclick={() => {
                        history.push('/suppliers/general-add');
                    }}
                />
            </div>

            {loading ? (
                <div className="w-full">
                    <div className="m-auto flex flex-col ">
                        <div className="m-auto p-10">
                            <Loader />
                        </div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                </div>
            ) : (
                <div className="w-full">
                    {suppliersData?.length ? (
                        <DataTable
                            columns={columns}
                            data={suppliersData}
                            // pageCount={pageCount}
                            setSelectedItems={setSelectedProducts}
                            // setPageIndex={setPageIndex}
                            // setDefaultPageSize={setPageSize} //future need
                            setExposeMenuClickedItem={setClickedSupplier}
                            menuItems={instantMenuItems}
                            setSelectedMenuItem={setSelectedMenuItem}
                            checkbox={false}
                            isInitialLoad={isInitialLoad}
                            setIsInitialLoad={setIsInitialLoad}
                        />
                    ) : (
                        <div className="m-auto flex flex-col ">
                            <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                {columns?.map((column, i) => {
                                    return (
                                        <div
                                            className="text-gray-600 font-poppins font-semibold flex ml-12"
                                            key={i}
                                        >
                                            {column?.Header}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="m-auto p-10">
                                Your search {`"${searchTerm}"`} did not match any results
                            </div>
                            <div className="p-10 bg-purple-100"></div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
