/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from 'react';
import { Modal } from 'react-responsive-modal';
import { useHistory } from 'react-router';
import { Buttons } from '../../ui/atoms/Button';
import { Images } from '../../ui/atoms/Images';

interface Props {
    showAddNewUserSuccess: boolean;
    setShowAddNewUserSuccess: (value: boolean) => void;
}

export default function UserAddedSuccessModal({
    showAddNewUserSuccess,
    setShowAddNewUserSuccess,
}: Props): ReactElement {
    const history = useHistory();

    return (
        <Modal
            open={showAddNewUserSuccess}
            onClose={() => {
                setShowAddNewUserSuccess(false);
            }}
            center={true}
            styles={{ modal: { borderRadius: 8, maxWidth: '706px', width: '80%' } }}
            focusTrapped={false}
        >
            <>
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-poppins  font-semibold flex m-auto">
                        User Added successFully
                    </h3>
                </div>

                <div className="relative p-6  flex flex-col">
                    <div className="flex m-auto mb-4">
                        <Images
                            width="w-16"
                            height="h-16"
                            src="/images/icons/success.png"
                            alt="success"
                        />
                    </div>
                    <div className="text-gray-400 text-lg flex mb-3 m-auto">
                        Successfully added a user
                    </div>
                </div>

                <div className="flex flex-row mt-6 justify-end m-4">
                    <Buttons
                        name="Okay"
                        type="submit"
                        buttonType="primary"
                        id="okay"
                        size="small"
                        onclick={() => {
                            setShowAddNewUserSuccess(false);
                            history.push('/users');
                        }}
                    />
                </div>
            </>
        </Modal>
    );
}
