/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    BASE_URL_AGREEMENT_UPLOAD_AWS_S3,
    PARTNER_EDIT_WARNING_BODY,
    PARTNER_EDIT_WARNING_TITLE,
} from '../../../../constants/partner';
import { GET_PARTNER_DATA, UPDATE_ADMIN_PROFILE } from '../../../../queries/AdminProfileQueries';
import { FETCH_PARTNER_DETAILS_BY_ID } from '../../../../queries/PartnerQueries';
import { listAllS3Objects } from '../../../../helpers/S3OperationsHelper.helper';
import SuccessMessageModal from '../../../templates/modals/SuccessMessageModal';
import { PARTNER_ADD_SUCCESS_TITLE, PARTNER_EDIT_SUCCESS } from '../../../../constants/partner';
import Loader from '../../../../utils/loader';
import NormalLoader from '../../../../utils/normalLoader';
import UploadDocumentComponent from '../../../templates/modals/UploadDocumentComponent';
import { Buttons } from '../../../ui/atoms/Button';
import DataTable from '../../../ui/organisms/DataTable';
import WarningMessageModal from '../../../templates/modals/WarningMessageModal';

export default function EditPartnerAgreement() {
    const history = useHistory();

    const [file, setFile] = useState(null);
    const [addPartnerError, setAddPartnerError] = useState(false);
    const [maxSize, setMaxSize] = useState(5);
    const [selectedItem, setSelectedItems] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [s3DirName, setS3DirName] = useState('');
    const [fileLocation, setFileLocation] = useState(null);
    const [agreementsData, setAgreementsData] = useState(null);
    const [loading, setLoading] = useState(false);
    const partnerId = localStorage.getItem('partnerId');
    const [partnerDataDetails, setPartnerDataDetails] = useState(null);
    const [showAddNewPartnerSuccess, setShowAddNewPartnerSuccess] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [processFinishedClicked, setProcessFinishedClicked] = useState(false);

    const { data: partnerData } = useQuery(GET_PARTNER_DATA, {
        fetchPolicy: 'network-only',
        variables: {
            partnerId: partnerId,
        },
    });

    function convertToMB(fileSize) {
        return `${(fileSize / (1024 * 1024)).toFixed(2)} MB`;
    }

    const { loading: partnerDataLoading } = useQuery(FETCH_PARTNER_DETAILS_BY_ID, {
        variables: { partnerId: partnerId },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setPartnerDataDetails(data?.partner);
        },
    });

    const [updatePartner, { loading: queryLoading }] = useMutation(UPDATE_ADMIN_PROFILE, {
        refetchQueries: [GET_PARTNER_DATA],
        onError() {
            setShowWarningModal(true);
            setAddPartnerError(true);
        },
        onCompleted() {
            setLoading(false);
            setShowAddNewPartnerSuccess(true);
        },
    });

    const handleUploadedFile = useCallback(() => {
        const agreements = [];
        if (fileLocation && file?.name) {
            const agreement = {
                url: fileLocation,
                isActive: true,
                fileName: file?.name,
                fileSize: file?.size,
                fileType: file?.type,
            };
            agreements.push(agreement);
            setAgreementsData(agreements);
        }

        updatePartner({
            variables: {
                userName: partnerDataDetails?.users[0]?.userName,

                user: {
                    firstName: partnerDataDetails?.users[0]?.contact?.primaryEmail?.slice(0, 9),
                    lastName: partnerDataDetails?.users[0]?.contact?.primaryEmail?.slice(0, 9),
                    contact: {
                        primaryEmail: partnerDataDetails?.users[0]?.contact?.primaryEmail,
                        recoveryEmail: partnerDataDetails?.users[0]?.contact?.primaryEmail,
                        countryCode: partnerDataDetails?.users[0]?.contact?.countryCode,
                        phoneNumber: partnerDataDetails?.users[0]?.contact?.phoneNumber,
                    },
                    partner: {
                        partnerAgreements: agreements?.length
                            ? agreements
                            : partnerData?.partnerAgreements?.map((agreement) => ({
                                  id: agreement?.id,
                                  url: agreement?.url,
                                  isActive: agreement?.isActive,
                                  fileName: agreement?.fileName,
                                  fileSize: agreement?.fileSize,
                                  fileType: agreement?.fileType,
                              })),
                    },
                },
            },
        });
    }, [fileLocation, file?.name]);

    const handleDisplayAgreement = useCallback(() => {
        if (file) {
            const agreementDataList = [];
            const agreementObj = {
                url: fileLocation,
                isActive: true,
                fileName: file?.name,
                fileSize: (file?.size * 0.001).toFixed(2),
                fileType: file?.type,
            };
            agreementDataList.push(agreementObj);
            setAgreementsData(agreementDataList);
        }
    }, [file, fileLocation]);

    useEffect(() => {
        if (partnerDataDetails?.length) {
            setS3DirName(BASE_URL_AGREEMENT_UPLOAD_AWS_S3 + partnerDataDetails?.id + '/');
        }
    }, [partnerDataDetails?.length]);

    useEffect(() => {
        if (file && file !== null) {
            handleDisplayAgreement();
        }
    }, [file, handleDisplayAgreement]);

    useEffect(() => {
        if (partnerDataDetails?.length) {
            const agreements = partnerDataDetails?.partnerAgreements;
            const agreementData = [];
            if (agreements) {
                const objectList = listAllS3Objects(
                    BASE_URL_AGREEMENT_UPLOAD_AWS_S3 + partnerDataDetails?.id + '/',
                );
                objectList.map((object) => {
                    const agreementObj = {
                        fileName: object.fileName,
                        fileSize: convertToMB(object.fileSize),
                        fileType: object.fileType,
                    };
                    agreementData.push(agreementObj);
                });
                agreements.map((agreement) => {
                    const agreementObj = {
                        fileName: 'cube',
                        fileSize: '5mb',
                        fileType: 'PDF',
                    };
                    agreementData.push(agreementObj);
                });
            }
        }
    }, [agreementsData, partnerDataDetails]);

    useEffect(() => {
        if (processFinishedClicked) {
            history.push('/partners');
        }
    }, [history, processFinishedClicked]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Name',
                accessor: 'fileName',
            },
            {
                Header: 'File type',
                accessor: 'fileType',
            },
            {
                Header: 'File size',
                accessor: 'fileSize',
                Cell: function size({ value }) {
                    return <span>{convertToMB(value)}</span>;
                },
            },
        ],
        [],
    );

    if (partnerDataLoading) return <Loader />;

    return (
        <>
            <div className="h-full flex flex-col justify-evenly">
                <div className="text-lg font-poppins font-bold px-8">Agreements</div>
                <div className="font-poppins px-8 pt-6 pb-4">
                    Upload partner agreement documents
                </div>
                <div className="pl-8 pr-4 pt-2 h-64">
                    <UploadDocumentComponent
                        isEdit={false}
                        setFile={setFile}
                        maxSize={maxSize}
                        uploadDocTypeHint={
                            'You can upload your agreement here in pdf or docx format.'
                        }
                        s3DirName={s3DirName}
                        setFileLocation={setFileLocation}
                    />
                </div>
                <hr className="pl-8 pr-16 border mt-14 border-gray-200" />
                <div className="flex flex-col justify-evenly ml-8 mt-8 mb-24 mr-4 border-2 border-gray-200 rounded-md">
                    <div className="font-poppins text-lg font-bold px-8 py-4 mr-16">
                        Uploaded documents
                    </div>
                    <div className="rounded-md">
                        {partnerData?.partner?.partnerAgreements?.length ||
                        agreementsData?.length ? (
                            <DataTable
                                columns={columns}
                                data={
                                    agreementsData?.length
                                        ? agreementsData
                                        : partnerData?.partner?.partnerAgreements
                                }
                                pageCount={1}
                                setSelectedItems={setSelectedItems}
                                setPageIndex={setPageIndex}
                                setDefaultPageSize={setPageSize}
                                hidePagination={true}
                                hideSelection={true}
                            />
                        ) : (
                            <div className="m-auto flex flex-col mb-10 ">
                                <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                    {columns?.map((column, i) => {
                                        return (
                                            <div
                                                className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                key={i}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10">No data found.</div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0">
                    <Buttons
                        name="Cancel"
                        type="button"
                        buttonType="secondary-border-black"
                        id="cancel-button"
                        size="e-small"
                        other="mr-3"
                        onclick={() => {
                            history.push('/partners');
                        }}
                    />
                    <Buttons
                        name={queryLoading ? <NormalLoader /> : 'Save'}
                        type="submit"
                        buttonType="primary"
                        id="save-button"
                        size="e-small"
                        onclick={() => {
                            handleUploadedFile();
                        }}
                    />
                </div>
                <SuccessMessageModal
                    showSuccessModal={showAddNewPartnerSuccess}
                    setShowSuccessModal={setShowAddNewPartnerSuccess}
                    successModalTitle={PARTNER_ADD_SUCCESS_TITLE}
                    successModalBody={PARTNER_EDIT_SUCCESS}
                    setProcessFinishedClicked={setProcessFinishedClicked}
                />
                <WarningMessageModal
                    showWarningModal={showWarningModal}
                    setShowWarningModal={setShowWarningModal}
                    warningModalBody={PARTNER_EDIT_WARNING_BODY}
                    warningModalTitle={PARTNER_EDIT_WARNING_TITLE}
                />
            </div>
        </>
    );
}
