/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLazyQuery, useQuery } from '@apollo/client';
import AWS from 'aws-sdk';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Icon } from 'semantic-ui-react';
import { SORT_ASC } from '../../../../constants/common';
import { awsSDKConfig, awsSDKS3Params } from '../../../../constants/config/constants';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import { ItemStatus } from '../../../../enums/item';
import { EXPORT_PARTNER_ITEMS } from '../../../../queries/ItemQueries';
import { FETCH_PARTNER_DETAILS_BY_ID } from '../../../../queries/PartnerQueries';
import { savePartnerName } from '../../../../redux/rootActions';
import { countryOptions } from '../../../../utils/countries';
import Loader from '../../../../utils/loader';
import NormalLoader from '../../../../utils/normalLoader';
import { Buttons } from '../../../ui/atoms/Button';
import { InputField } from '../../../ui/atoms/InputField';
import Toast from '../../../ui/atoms/Toast';

export default function ViewPartnerGeneral() {
    const partnerId = localStorage.getItem('partnerId');
    const [partnerData, setPartnerData] = useState(null);
    const [downloadProductsLoading, setDownloadProductsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [error, setError] = useState(false);

    const dispatch = useDispatch();
    const { loading: partnerDataLoading } = useQuery(FETCH_PARTNER_DETAILS_BY_ID, {
        variables: { partnerId: partnerId },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setPartnerData(data?.partner);
            dispatch(savePartnerName(data?.partner?.partnerName));
        },
    });

    const handleDownload = (key) => {
        setDownloadProductsLoading(true);
        const s3 = new AWS.S3(awsSDKConfig);

        const params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: key,
        };

        function downloadBlob(blob, name = `${key}.csv`) {
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = name;
            document.body.appendChild(link);
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                }),
            );
            document.body.removeChild(link);
        }

        s3.getObject(params, (err, data) => {
            if (err) {
                console.error(err, err.stack);
            } else {
                const csvBlob = new Blob([data.Body.toString()], {
                    type: 'text/csv;charset=utf-8;',
                });
                const fileName = key?.split('/')[1];
                downloadBlob(csvBlob, `${fileName}`);
                setDownloadProductsLoading(false);
            }
        });
    };

    const [getPartnerItemsCSV, { loading: exportFileLoading }] = useLazyQuery(
        EXPORT_PARTNER_ITEMS,
        {
            context: { clientName: ClientsEnum.STORE },
            variables: {
                filter: {
                    partner: partnerId ? partnerId : null,
                    itemStatus: ItemStatus.PUBLISHED,
                },
                offset: 0,
                limit: null,
                order: SORT_ASC,
            },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                handleDownload(data?.exportItems?.filePath);
            },
        },
    );

    const code = countryOptions?.find((country: any) => {
        return country?.value === partnerData?.users[0]?.contact?.countryCode;
    });

    if (partnerDataLoading) return <Loader />;
    if (!partnerData?.users[0]?.contact?.countryCode) return <Loader />;

    return (
        <div className="h-full flex flex-col  justify-evens">
            {showToast && (
                <Toast
                    setShowToast={setShowToast}
                    message={message}
                    width="w-8/12"
                    error={error}
                    selfDestruct={true}
                    selfDestructTimer={5000}
                />
            )}
            <div className="flex flex-row">
                <div className="flex text-lg font-poppins font-bold px-8">General</div>
                {partnerData?.isActive && (
                    <div className="flex flex-grow py-2 px-4 justify-end sm:text-sm">
                        <Buttons
                            name={
                                downloadProductsLoading || exportFileLoading ? (
                                    <NormalLoader />
                                ) : (
                                    'Download Products'
                                )
                            }
                            type="submit"
                            buttonType="primary"
                            id="update"
                            size="e-small"
                            onclick={() => {
                                getPartnerItemsCSV();
                            }}
                        />
                    </div>
                )}
            </div>
            <div className="grid grid-cols-8 gap-4 mt-8 px-8">
                <div className="col-start-1 col-end-4">
                    <InputField
                        id="partnerName"
                        placeHolder={'Partner name'}
                        name="Partner name"
                        labelWidth="w-21"
                        value={partnerData?.partnerName}
                        readonly={true}
                    />
                </div>

                <div className="col-end-7 col-span-3">
                    <InputField
                        id="partnerId"
                        placeHolder="Partner Id"
                        name="Partner ID"
                        labelWidth="w-21"
                        value={partnerData?.partnerId}
                        readonly={true}
                    />
                </div>
                <div className="col-start-1 col-end-4">
                    <InputField
                        id="partnerURL"
                        placeHolder={'Partner URL'}
                        name="Partner URL"
                        labelWidth="w-21"
                        value={partnerData?.partnerUrl}
                        readonly={true}
                    />
                </div>
                <div className="col-start-4 col-span-3 mt-0.5">
                    <div className="flex flex-wrap flex-col relative lg:mb-5 z-50 ">
                        <div className="text-gray-500 w-16 text-center font-poppins text-sm lg:text-sm h-max bg-white z-50 -mb-3 ml-4 border-opacity-20">
                            Country
                        </div>

                        <Dropdown
                            className="dropdownProfile"
                            placeholder="Select country"
                            id="countryCode"
                            fluid
                            defaultValue={
                                partnerData?.users[0]?.contact?.countryCode
                                    ? partnerData?.users[0]?.contact?.countryCode
                                    : null
                            }
                            search
                            key={'countryCode'}
                            selection
                            disabled={true}
                            options={countryOptions}
                            pointing="top"
                            icon={<Icon name="chevron down" className="iconChevron"></Icon>}
                        />
                    </div>
                </div>
            </div>
            <hr className="px-8 border" />
            <div className="text-lg font-poppins font-bold pt-8 px-8">Contact Information</div>
            <div className="grid grid-cols-8 gap-4 mt-8 px-8">
                <div className="col-start-1 col-end-4">
                    <InputField
                        id="partnerEmail"
                        placeHolder={'Email Address'}
                        name="Email Address"
                        labelWidth="w-21"
                        value={partnerData?.users[0]?.contact?.primaryEmail}
                        readonly={true}
                    />
                </div>

                <div className="col-start-1 col-span-1 lg:col-end-5 lg:col-span-1 ">
                    <div className="flex flex-wrap flex-col relative mt-1.5">
                        <select
                            className="p-4 lg:p-3.5  rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                            disabled={true}
                            id="code"
                            value={code?.dial_value}
                        >
                            {countryOptions.map((country: any, index: any) => {
                                return (
                                    <option value={country.dial_value} key={index}>
                                        {country.dial_value}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>

                <div className=" col-start-3 col-span-4  lg:col-end-7 lg:col-span-2">
                    <InputField
                        id="partnerPhone"
                        placeHolder="partnerPhone"
                        labelWidth="w-32"
                        name="Phone Number"
                        value={partnerData?.users[0]?.contact?.phoneNumber}
                        readonly={true}
                    />
                </div>
            </div>
            <hr className="px-8 border mt-5" />
            <>
                <div className="text-lg font-poppins font-bold px-8 mt-8">
                    Marked-up Price Calculation
                </div>
                <div className="grid grid-cols-8 gap-4 mt-8 px-8 mb-40">
                    <div className="col-start-1 col-span-3 flex flex-col">
                        <div className="flex gap-2">
                            <InputField
                                id="markedUpPercentageCurrency"
                                placeHolder={'0'}
                                name="Marked-up Percentage (DCB Currency)"
                                labelWidth="w-21"
                                value={partnerData?.markedUpPercentageForCurrency}
                                readonly={true}
                            />
                            <div className="col-span-1 my-auto font-poppins text-2xl">{`%`}</div>
                        </div>
                    </div>
                    <div className="col-start-4 col-span-3 flex flex-col">
                        <div className="flex gap-2">
                            <InputField
                                id="markedUpPercentage"
                                placeHolder={'0'}
                                name="Marked-up Percentage (Points)"
                                labelWidth="w-21"
                                value={partnerData?.markedUpPercentage}
                                readonly={true}
                            />
                            <div className="col-span-1 my-auto font-poppins text-2xl">{`%`}</div>
                        </div>
                    </div>
                    <div className="col-start-1 col-span-3 flex flex-col">
                        <div className="flex gap-2">
                            <InputField
                                id="markedUpPercentagePsp"
                                placeHolder={'0'}
                                name="Marked-up Percentage (PSP Currency)"
                                labelWidth="w-21"
                                value={partnerData?.markedUpPercentageForPsp}
                                readonly={true}
                            />
                            <div className="col-span-1 my-auto font-poppins text-2xl">{`%`}</div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
}
