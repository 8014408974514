import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { SORT_ASC } from '../../../../constants/common';
import { pagination } from '../../../../constants/config/constants';
import { ALLSERV, API, CSV, CSV_VOUCHERS } from '../../../../constants/supplier';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import { ItemStatus } from '../../../../enums/item';
import { FETCH_PRODUCTS } from '../../../../queries/ItemQueries';
import DataTable from '../../../ui/organisms/DataTable';
import SearchBar from '../../../ui/molecules/SearchBar';

interface Props {
    selectedTab: number;
    selectedItems: any;
    setAvaialbleItemsCount: (value) => void;
    addCheckedItems: (value) => void;
    setCheckedRowIds?: (value) => void;
}

export default function AvailableItemsListView({
    selectedTab,
    selectedItems,
    setAvaialbleItemsCount,
    addCheckedItems,
    setCheckedRowIds,
}: Props) {
    const [productData, setProductData] = useState([]);
    const [productInitialData, setProductInitialData] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [initialCheckedItems, setInitialCheckedItems] = useState({});
    const [searchClicked, setSearchClicked] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const [fetchItemData, { loading }] = useLazyQuery(FETCH_PRODUCTS, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted: (data) => {
            setProductData(
                data?.items?.items?.filter((item) => item?.itemStatus === ItemStatus?.PUBLISHED),
            );
            setProductInitialData(
                data?.items?.items?.filter((item) => item?.itemStatus === ItemStatus?.PUBLISHED),
            );
            setTotalItems(data?.items?.totalItems);
            setAvaialbleItemsCount(data?.items?.totalItems);
        },
    });

    const availableItemColumns = useMemo(
        () => [
            {
                Header: 'Product ID',
                accessor: 'itemId',
            },
            {
                Header: 'Product Name',
                accessor: 'itemName',
            },
            {
                Header: 'Product Origin',
                id: 'product.orderStrategy',
                accessor: (item) => {
                    if (item.product?.orderStrategy === CSV_VOUCHERS) {
                        return CSV;
                    } else if (item.product?.orderStrategy.startsWith('ALLSERV')) {
                        return ALLSERV;
                    } else {
                        return API;
                    }
                },
            },
            {
                Header: 'Product Type',
                accessor: 'product.productType',
            },
            {
                Header: 'Content Type',
                accessor: 'contentType',
            },
            {
                Header: 'Supplier',
                accessor: 'supplier.supplierName',
            },
            {
                Header: 'Stocks Status',
                accessor: 'isStockAvailable',
                Cell: function showBadge({ value }) {
                    const status = value ? 'In Stock' : 'Out of Stock';
                    return (
                        <span
                            style={{
                                minWidth:
                                    status === 'In Stock'
                                        ? '72px'
                                        : status === 'Out of Stock' && '96px',
                            }}
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                status === 'In Stock'
                                    ? 'bg-yellow-100 text-yellow-600'
                                    : status === 'Out of Stock'
                                    ? 'bg-red-100 text-red-600'
                                    : ''
                            }`}
                        >
                            {status}
                        </span>
                    );
                },
            },
        ],
        [],
    );

    const productNames = productInitialData
        ?.map((product) => product?.itemName)
        .concat(productData?.map((product) => product?.itemId));

    useEffect(() => {
        setCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, pageSize]);

    useEffect(() => {
        if (searchClicked && searchTerm) {
            fetchItemData({
                variables: {
                    filter: {
                        itemStatus: ItemStatus.PUBLISHED,
                        itemType: null,
                        suppliers: null,
                        partners: null,
                        categories: null,
                    },
                    offset: currentPage - 1 || 0,
                    limit: Math.ceil(pageSize) || 10000,
                    sort: 'itemName',
                    order: SORT_ASC,
                    searchText: searchClicked && searchTerm?.length > 3 ? searchTerm : null,
                },
            });
            if (searchClicked && searchTerm?.length > 3) {
                setCurrentPage(1);
            }
        } else {
            fetchItemData({
                variables: {
                    filter: {
                        itemStatus: ItemStatus.PUBLISHED,
                        itemType: null,
                        suppliers: null,
                        partners: null,
                        categories: null,
                    },
                    offset: currentPage - 1 || 0,
                    limit: Math.ceil(pageSize) || 10000,
                    sort: 'itemName',
                    order: SORT_ASC,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, pageSize, searchClicked, searchTerm]);

    useEffect(() => {
        const checkedList = initialCheckedItems;
        if (selectedItems) {
            selectedItems?.map((rowItem) => {
                checkedList[rowItem?.id] = true;
            });
        }
        setInitialCheckedItems(checkedList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems, setInitialCheckedItems]);

    return (
        <>
            <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3 text-gray-500 px-4 pb-4">
                <SearchBar
                    id="add-new-category-search-field"
                    options={productNames}
                    handleChange={(e) => {
                        setSearchTerm(e.target.value);
                        setSearchClicked(true);
                    }}
                    placeHolder={'Search Product by Name or ID'}
                />
            </div>
            <div className="w-full">
                {productData?.length ? (
                    <DataTable
                        columns={availableItemColumns}
                        data={productData}
                        pageCount={pageCount}
                        setPageIndex={setPageIndex} //future need
                        setDefaultPageSize={setPageSize}
                        radioBtn={false}
                        serverSidePagination={true}
                        currentPage={currentPage}
                        loading={loading}
                        pageSizes={pageSize}
                        setCurrentPage={setCurrentPage}
                        totalItems={totalItems}
                        isCategoryProductsView={true}
                        checkbox={true}
                        checkboxHeader={true}
                        initialSelectedItems={initialCheckedItems}
                        setCheckedItems={addCheckedItems}
                        setCheckedRowIds={setCheckedRowIds}
                    />
                ) : (
                    <div className="m-auto flex flex-col ">
                        <div className="py-4 px-5 bg-purple-100 justify-between flex">
                            {availableItemColumns?.map((column, i) => {
                                return (
                                    <div
                                        className="text-gray-600 font-poppins font-semibold flex ml-12"
                                        key={i}
                                    >
                                        {column?.Header}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="m-auto p-10">No data found.</div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                )}
            </div>
        </>
    );
}
