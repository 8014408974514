/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router';
import Confirmation from './ConfirmationModalWithMessage';
import { Location } from 'history';

interface Props {
    contentText: string;
    messageTitle: string;
    when?: boolean | undefined;
    navigate: (path: string) => void;
    shouldBlockNavigation: (location: Location) => boolean;
    displayIcon?: boolean;
    displayIconName?: string;
    yesButtonName?: string;
    noButtonName?: string;
    onlyOkButton?: boolean;
}

const UnsaveChangesWarning = ({
    contentText,
    messageTitle,
    when,
    navigate,
    shouldBlockNavigation,
    displayIcon,
    displayIconName,
    noButtonName,
    yesButtonName,
    onlyOkButton,
}: Props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);

    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const closeModal = () => {
        setModalVisible(false);
    };
    const handleBlockedNavigation = (nextLocation: Location<unknown>): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };
    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            // Navigate to the previous blocked location with your navigate function
            navigate(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation]);

    useEffect(() => {
        window.onbeforeunload = when ? () => true : null;
        return () => {
            window.onbeforeunload = null;
        };
    }, [when]);

    return (
        <>
            <Prompt when={when} message={(location) => handleBlockedNavigation(location)} />
            {/* Your own alert/dialog/modal component */}
            <Confirmation
                message={contentText}
                messageTitle={messageTitle}
                setShowConfirmationModal={setModalVisible}
                showConfirmationModal={modalVisible}
                setNoBtnClick={closeModal}
                setYesBtnClick={handleConfirmNavigationClick}
                displayIcon={displayIcon}
                displayIconName={displayIconName}
                noButtonName={noButtonName}
                yesButtonName={yesButtonName}
                onlyOkButton={onlyOkButton}
            />
        </>
    );
};

export default UnsaveChangesWarning;
