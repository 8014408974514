import { gql } from '@apollo/client';

export const FETCH_PARTNER_DETAILS = gql`
    query {
        partners {
            id
            partnerId
            partnerName
            partnerUrl
            creditLimit
            markedUpPercentageForCurrency
            markedUpPercentage
            markedUpPercentageForPsp
            isActive
            isDeleted
            createdBy
            createdDate
            modifiedBy
            modifiedDate
            localCurrency
            catalog {
                catalogId
                catalogName
                createdBy
                createdDate
            }
            users {
                userId
                userName
                firstName
                lastName
                contact {
                    contactId
                    primaryEmail
                    phoneNumber
                    countryCode
                }
            }
        }
    }
`;

export const FETCH_PARTNER_DETAILS_FOR_FILTER = gql`
    query {
        partners {
            id
            partnerId
            partnerName
            isActive
            isDeleted
        }
    }
`;

export const FETCH_PARTNER_DETAILS_FOR_PARTNER_CONVERSIONS = gql`
    query {
        partners {
            id
            partnerId
            partnerName
            isActive
            markedUpPercentage
            pointsConversionRate
            markedUpPrice
        }
    }
`;

export const FETCH_PARTNER_DETAILS_BY_ID = gql`
    query ($partnerId: ID!) {
        partner(partnerId: $partnerId) {
            id
            partnerId
            partnerName
            partnerUrl
            creditLimit
            markedUpPercentageForCurrency
            markedUpPercentage
            markedUpPercentageForPsp
            isActive
            createdBy
            createdDate
            modifiedBy
            modifiedDate
            catalog {
                catalogId
                catalogName
                createdBy
                createdDate
            }
            partnerAgreements {
                id
                url
                fileName
                fileSize
                fileType
                isActive
            }
            users {
                userId
                userName
                contact {
                    primaryEmail
                    phoneNumber
                    countryCode
                }
            }
        }
    }
`;

export const FETCH_CATALOG_CATEGORIES_BY_PARTNER = gql`
    query ($partnerId: ID!) {
        categoryCatalogAdmin(partnerId: $partnerId) {
            id
            categoryId
            categoryName
        }
    }
`;

export const FETCH_ITEMS_BY_PARTNER = gql`
    query ($partnerId: ID!) {
        itemsByPartner(partnerId: $partnerId) {
            id
            itemId
            itemName
            isStockAvailable
        }
    }
`;

export const FETCH_PARTNER_CURRENCY_PREFERENCES = gql`
    query ($partnerId: ID!) {
        partnerCurrencyPreferences(partnerId: $partnerId) {
            id
            sourceCurrency
            targetCurrency
            conversionRate
            overridePartner
        }
    }
`;

export const FETCH_PARTNER_TARGET_CURRENCY_PREFERENCES = gql`
    query ($partnerId: ID!) {
        partnerTargetCurrencyPreferences(partnerId: $partnerId)
    }
`;

export const FETCH_CATALOG_BY_ID = gql`
    query ($catalogId: ID!) {
        catalog(catalogId: $catalogId) {
            catalogId
            categoryOrder
            itemOrder
            partner {
                partnerName
            }
        }
    }
`;

export const UPDATE_CATALOG_CATEGORY_ORDER = gql`
    mutation ($catalogInput: CatalogInput!) {
        updateCatalog(catalogInput: $catalogInput) {
            catalogId
            categoryOrder
        }
    }
`;

export const UPDATE_CATALOG_ITEM_ORDER = gql`
    mutation ($catalogInput: CatalogInput!) {
        updateCatalog(catalogInput: $catalogInput) {
            catalogId
            itemOrder
        }
    }
`;

export const UPDATE_PARTNER_TARGET_CURRENCY_PREFERENCES = gql`
    mutation ($partnerId: ID!, $currencies: [String]) {
        savePartnerCurrencyPreferences(partnerId: $partnerId, currencies: $currencies) {
            id
            sourceCurrency
            targetCurrency
            conversionRate
        }
    }
`;

export const UPDATE_PARTNER_CURRENCY_CONVERSION_RATE = gql`
    mutation ($partnerId: ID!, $currencyPreference: PartnerCurrencyPreferenceInput) {
        updatePartnerCurrencyConversionRate(
            partnerId: $partnerId
            currencyPreference: $currencyPreference
        ) {
            id
            conversionRate
        }
    }
`;

export const UPDATE_PARTNER_LOCAL_CURRENCY_CONVERSION_RATE = gql`
    mutation ($partnerId: ID!, $localCurrencyConversion: PartnerLocalCurrencyConversionInput) {
        updatePartnerLocalCurrencyConversionRate(
            partnerId: $partnerId
            localCurrencyConversion: $localCurrencyConversion
        )
    }
`;

export const FETCH_PARTNER_LOCAL_CURRENCY_CONVERSION_RATE = gql`
    query ($partnerId: ID!) {
        partnerLocalCurrencyConversionRate(partnerId: $partnerId) {
            localCurrency
            conversionRate
        }
    }
`;
