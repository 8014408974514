import { gql } from '@apollo/client';

export const FETCH_SUPPLIERS = gql`
    query {
        suppliers {
            id
            supplierId
            supplierName
            creditLimit
            createdBy
            isActive
            isDeleted
            createdDate
            endpointUrl
            productCount
            itemCount
            configuration {
                id
                name
                config
                supplierType
            }
            contact {
                contactId
                email
                contactNumber
                countryCode
            }
            job {
                id
                jobId
                jobName
                className
                cronDefinition
                description
                jobType
                schedulerMethodType
                isActive
                createdDate
                lastTriggerTime
                executionTime
                lastTriggerStatus
            }
        }
    }
`;

export const FETCH_SUPPLIERS_DROPDOWN = gql`
    query {
        suppliers {
            id
            supplierId
            supplierName
            isActive
            isDeleted
        }
    }
`;

export const FETCH_SUPPLIER_BY_ID = gql`
    query ($supplierId: ID!) {
        supplier(supplierId: $supplierId) {
            id
            supplierId
            supplierName
            creditLimit
            createdBy
            isActive
            isDeleted
            createdDate
            endpointUrl
            configuration {
                id
                name
                supplierType
                config
            }
            job {
                id
                jobId
                jobName
                className
                cronDefinition
                description
                jobType
                schedulerMethodType
                isActive
                createdDate
                lastTriggerTime
                executionTime
                lastTriggerStatus
            }
            contact {
                contactId
                email
                contactNumber
                countryCode
            }
        }
    }
`;

export const FETCH_SUPPLIER_CONFIGURATIONS = gql`
    query {
        supplierConfigurations {
            id
            name
            supplierType
            config
            createdDate
        }
    }
`;

export const ADD_SUPPLIERS = gql`
    mutation ($supplier: SupplierInput!) {
        saveSupplier(supplier: $supplier) {
            id
            supplierId
            supplierName
            configuration {
                id
                name
                supplierType
                config
            }
            contact {
                contactId
                email
                contactNumber
                countryCode
            }
            job {
                id
                jobId
                jobName
                className
                cronDefinition
                description
                jobType
                schedulerMethodType
                isActive
                createdDate
                lastTriggerTime
                executionTime
                lastTriggerStatus
            }
        }
    }
`;

export const DELETE_PRODUCT = gql`
    mutation ($productId: ID!) {
        deleteProductByID(productId: $productId) {
            itemsUnpublishedTotal
            voucherDeletedTotal
        }
    }
`;

export const DELETE_PRODUCTS = gql`
    mutation ($productIds: [ID!]) {
        deleteProductByIDs(productIds: $productIds) {
            itemsUnpublishedTotal
            voucherDeletedTotal
        }
    }
`;

export const DELETE_VOUCHER = gql`
    mutation ($voucherId: ID!) {
        deleteVoucherByID(voucherId: $voucherId) {
            id
            voucherCode
            voucherExpiry
        }
    }
`;

export const DELETE_VOUCHERS = gql`
    mutation ($voucherIds: [ID!]) {
        deleteVoucherByIDs(voucherIds: $voucherIds) {
            id
            voucherCode
            voucherExpiry
        }
    }
`;

export const DELETE_BATCH = gql`
    mutation ($fileId: ID!) {
        deleteProductsByFileId(fileId: $fileId) {
            itemsUnpublishedTotal
            voucherDeletedTotal
        }
    }
`;

export const DELETE_BATCHES = gql`
    mutation ($fileIds: [ID!]) {
        deleteProductsByFileIds(fileIds: $fileIds) {
            itemsUnpublishedTotal
            voucherDeletedTotal
        }
    }
`;

export const UPDATE_UNIPIN_TOPUP_PRODUCTS = gql`
    mutation ($supplierId: ID!) {
        updateUniPinTopUpProducts(supplierId: $supplierId) {
            id
            manufacturerId
            manufacturerName
            manufacturerBrandId
            manufacturerBrandName
            manufacturerBrandProductFormatId
            manufacturerBrandProductFormatDesc
            manufacturerBrandProductFields
            productId
            productName
            productVvssku
            currency
            currencySymbol
            unitPrice
            sellPrice
            productRedemptionInstructions
            productImageUrl
            createdDate
            modifiedDate
        }
    }
`;

export const UPDATE_UNIPIN_VOUCHER_PRODUCTS = gql`
    mutation ($supplierId: ID!) {
        updateUniPinVoucherProducts(supplierId: $supplierId) {
            id
            manufacturerId
            manufacturerName
            manufacturerBrandId
            manufacturerBrandName
            manufacturerBrandProductFormatId
            manufacturerBrandProductFormatDesc
            manufacturerBrandProductFields
            productId
            productName
            productVvssku
            currency
            currencySymbol
            unitPrice
            sellPrice
            productRedemptionInstructions
            productImageUrl
            createdDate
            modifiedDate
        }
    }
`;
