import { SWAR_MVP_HIDE_FEATURE } from '../../constants/config/constants';
import LogOutModel from './modals/LogOutModal';

export default function NavBar({ props }) {
    return (
        <div className="w-full">
            <div className="flex flex-col border-b-2 border-gray-300 ">
                <div className="text-black font-semibold font-poppins text-xl flex justify-between p-5 -mt-1">
                    <div id={props?.toLowerCase()}>{props}</div>
                    <div className="flex flex-row relative mx-1 items-center">
                        {SWAR_MVP_HIDE_FEATURE ? null : (
                            <>
                                <div className="mx-3 hover:bg-gray-200 rounded-full">
                                    <img
                                        className="w-full mt-1"
                                        src="/images/icons/navBarInfo.svg"
                                        alt="question"
                                    />
                                </div>
                                <div className="mx-3 hover:bg-gray-200  rounded-full">
                                    <img
                                        className="w-full"
                                        src="/images/icons/navBarNoti.svg"
                                        alt="notification"
                                    />
                                </div>
                            </>
                        )}
                        <LogOutModel />
                    </div>
                </div>
            </div>
        </div>
    );
}
