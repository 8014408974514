/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */

import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Buttons } from '../../ui/atoms/Button';
import { InputField } from '../../ui/atoms/InputField';

import { useHistory } from 'react-router';
import UploadCategoryImage from './UploadCategoryImage';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import {
    ADD_CATEGORIES,
    FETCH_CATEGORIES,
    FETCH_CATEGORIES_BY_ID,
} from '../../../queries/CategoryQueries';
import {
    ERROR_EDIT_CATEGORY,
    ERROR_EMPTY_FIELDS,
    ERROR_MESSAGE_CATALOG,
    SUCCESS_MESSAGE_UPDATE_CATEGORY,
} from '../../../constants/category';
import Loader from '../../../utils/loader';
import Toast from '../../ui/atoms/Toast';
import { FETCH_CATALOG } from '../../../queries/catalogQueries';
import Multiselect from 'multiselect-react-dropdown';
import NormalLoader from '../../../utils/normalLoader';
import UnsaveChangesWarning from '../../templates/modals/UnsaveChangesWarning';
import {
    PRODUCT_ADD_EXIT_CONFIRM_MESSAGE,
    PRODUCT_ADD_EXIT_CONFIRM_TITLE,
} from '../../../constants/product';
import { useDispatch } from 'react-redux';
import { saveCategoryName } from '../../../redux/rootActions';
import { ItemStatus } from '../../../enums/item';
import { Tooltip } from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import * as Yup from 'yup';
import CategoryItemSelectionView from './categoryItems/CategoryItemSelectionView';

export default function EditCategory() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [thumbNail, setThumbNail] = useState('');
    const [showToolTip, setShowToolTip] = useState(false);
    const [coverImage, setCoverImage] = useState('');
    const [catalogError, setCatalogError] = useState(false);
    const [descriptionLong, setDescriptionLong] = useState('');
    const [selectedCatalogIds, setSelectedCatalogIds] = useState([]);
    const [selectedProductCatalogIds, setSelectedProductCatalogs] = useState([]);
    const [catalogData, setCatalogData] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [categoriesCatalog, setCategoriesCatalog] = useState([]);
    const [previousSelectedItems, setPreviousSelectedItems] = useState([]);
    const [defaultParent, setDefaultParent] = useState([]);
    const [selectedCatalogList, setSelectedCatalogList] = useState([]);
    const [selectedItemsCatalogs, setSelectedItemsCatalogs] = useState([]);
    const [itemsCatalogsList, setItemsCatalogsList] = useState([]);
    const [remainingCatalogsList, setRemainingCatalogsList] = useState([]);
    const [removeCatalogsClicked, setRemoveCatalogsClicked] = useState<boolean>(false);
    const [itemCatalogs, setItemCatalogs] = useState([]);
    const [initialItemCatalogs, setInitialItemCatalogs] = useState([]);

    const selectedCategoryToEdit = localStorage.getItem('edit-category');

    const validationSchema = Yup.object({
        categoryName: Yup.string().trim().required('Category title is missing'),
        categoryDescription: Yup.string().trim().required('Category description is missing'),
    });

    const { loading: catalogLoading } = useQuery(FETCH_CATALOG, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setCatalogData(data?.catalogs?.filter((cat) => cat?.partner?.isActive === true));
        },
    });

    const { data: categoriesQueryData, loading: allCategoriesLoading } = useQuery(
        FETCH_CATEGORIES,
        {
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: () => {
                setCategoriesData(categoriesQueryData?.categories);
            },
        },
    );

    const [fetchCategoryById, { data: categoryQueryData, loading }] = useLazyQuery(
        FETCH_CATEGORIES_BY_ID,
        {
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: () => {
                dispatch(saveCategoryName(categoryQueryData?.category?.categoryName));
                setCoverImage(categoryQueryData?.category?.categoryCoverImage);
                setThumbNail(categoryQueryData?.category?.categoryImage);
                setDescriptionLong(categoryQueryData?.category?.categoryDescription);
            },
        },
    );

    const [saveCategory, { loading: queryLoading }] = useMutation(ADD_CATEGORIES, {
        context: { clientName: ClientsEnum.STORE },
        refetchQueries: [FETCH_CATEGORIES],
        fetchPolicy: 'network-only',
        onCompleted: () => {
            setIsDirty(false);
            setMessage(SUCCESS_MESSAGE_UPDATE_CATEGORY);
            setError(false);
            setShowToast(true);
            setTimeout(() => {
                history.push('/products/categories');
            }, 1800);
        },
        onError: () => {
            setMessage(ERROR_EDIT_CATEGORY);
            setShowToast(true);
            setError(true);
        },
    });

    const activeCategories = categoriesData?.filter((category) => {
        return category?.isDeleted === false;
    });

    const categoriesDropDownList = activeCategories?.map((category) => ({
        text: category.categoryName,
        value: category.categoryId,
    }));

    const selectedCategoryId = categoriesData?.find((category) => {
        if (selectedCategory) {
            return category.categoryId === selectedCategory;
        }
    });

    const category = categoryQueryData?.category;

    const catalogsListMultiple = catalogData?.map((catalog) => ({
        cat: catalog.catalogId,
        key: `${catalog.partner.partnerName} Catalog`,
    }));

    const defaultCataloDetails = catalogData?.filter((catalog: any) => {
        return catalog.catalogId === category?.catalog?.catalogId.toString();
    });

    const defaultCatalogsList = defaultCataloDetails?.map((catalog) => ({
        text: catalog.partner.partnerName,
        value: catalog.catalogId,
    }));

    useEffect(() => {
        const itemsList = category?.itemCatalogCategory?.map(
            (categoryItem) => categoryItem?.item?.id,
        );
        const itemsSet = new Set(itemsList);
        const uniqueItemIds = [...itemsSet];
        const itemCatalogs = [];
        uniqueItemIds?.forEach((itemId) => {
            const itemCategoryList = category?.itemCatalogCategory?.filter(
                (categoryId) => categoryId?.item?.id === itemId,
            );
            let catalogsList = itemCategoryList?.map((categoryItem) => categoryItem?.catalog);
            if (removeCatalogsClicked) {
                if (remainingCatalogsList && remainingCatalogsList?.length > 0) {
                    catalogsList = catalogsList?.filter((catalog) =>
                        remainingCatalogsList?.includes(catalog?.catalogId),
                    );
                } else {
                    catalogsList = [];
                }
            }

            itemCatalogs?.push({
                itemId: itemId,
                catalogs: catalogsList,
            });
        });
        setItemsCatalogsList(itemCatalogs);
    }, [category?.itemCatalogCategory, remainingCatalogsList]);

    const theme = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    color: 'white',
                    backgroundColor: '#9f7aea',
                },
                arrow: {
                    color: '#9f7aea',
                },
            },
        },
    });

    const onSelectCatalogs = (selectedList) => {
        let itemCatalogsTemp = [];
        selectedList.forEach((selectedItem) => {
            if (
                !selectedCatalogIds.some(
                    (selectedCatalogId) => selectedCatalogId == selectedItem.cat,
                )
            ) {
                itemCatalogsTemp = itemCatalogs.map((itemCatalog) => ({
                    ...itemCatalog,
                    catalogs: [
                        ...itemCatalog.catalogs,
                        { value: selectedItem.cat, label: selectedItem.key },
                    ],
                }));

                setItemCatalogs(
                    itemCatalogs.map((itemCatalog) => ({
                        ...itemCatalog,
                        catalogs: initialItemCatalogs.some(
                            (initialItemCatalog) => initialItemCatalog.itemId == itemCatalog.itemId,
                        )
                            ? [...itemCatalog.catalogs]
                            : [
                                  ...itemCatalog.catalogs,
                                  { value: selectedItem.cat, label: selectedItem.key },
                              ],
                    })),
                );
            }
        });

        const itemsCatalogsFinalList = [];

        itemCatalogsTemp?.forEach((itemCatalog) => {
            itemCatalog?.catalogs?.forEach((catalog) => {
                itemsCatalogsFinalList?.push({
                    itemId: itemCatalog?.itemId,
                    catalogId: catalog?.value,
                });
            });
        });
        setSelectedItemsCatalogs(itemsCatalogsFinalList);

        const selectedCatalogId = selectedList?.map((list) => list?.cat);
        setIsDirty(true);
        setSelectedCatalogIds(selectedCatalogId);
        setCatalogError(false);
        setSelectedProductCatalogs(selectedCatalogId);
    };

    const onRemoveCatalogs = (selectedList) => {
        setIsDirty(true);
        setRemoveCatalogsClicked(true);
        const catalogList = [...selectedCatalogIds];
        const selectedProdCatalogs = [...selectedProductCatalogIds];
        const remainingCatalogIds = selectedList?.map((list) => list?.cat);

        const onListCatalogs = catalogList?.length
            ? catalogList?.filter((catalog) =>
                  remainingCatalogIds?.some((cat) => {
                      return cat === catalog;
                  }),
              )
            : [];

        const onListCatalogsProd = selectedProdCatalogs?.length
            ? selectedProdCatalogs?.filter((catalog) =>
                  remainingCatalogIds?.some((cat) => {
                      return cat === catalog;
                  }),
              )
            : [];

        let itemCatalogsTemp = [];
        selectedCatalogIds.forEach((selectedCatalogId) => {
            if (!selectedList.some((selectedItem) => selectedItem.cat == selectedCatalogId)) {
                itemCatalogsTemp = itemCatalogs.map((itemCatalog) => ({
                    ...itemCatalog,
                    catalogs: [
                        ...itemCatalog.catalogs.filter(
                            (catalog) => catalog.value !== selectedCatalogId,
                        ),
                    ],
                }));
                setItemCatalogs(itemCatalogsTemp);
            }
        });

        const itemsCatalogsFinalList = [];

        itemCatalogsTemp?.forEach((itemCatalog) => {
            itemCatalog?.catalogs?.forEach((catalog) => {
                itemsCatalogsFinalList?.push({
                    itemId: itemCatalog?.itemId,
                    catalogId: catalog?.value,
                });
            });
        });

        setSelectedItemsCatalogs(itemsCatalogsFinalList);
        setRemainingCatalogsList(onListCatalogs);
        setSelectedCatalogIds(onListCatalogs);
        setSelectedProductCatalogs(onListCatalogsProd);
    };

    useEffect(() => {
        if (selectedCatalogIds) {
            const selectedCatalogs = catalogData?.filter((catalog) =>
                selectedCatalogIds?.includes(catalog?.catalogId),
            );
            const resultantCatalogs = selectedCatalogs?.map((catalog) => ({
                value: catalog?.catalogId,
                label: catalog?.catalogName,
            }));
            setSelectedCatalogList(resultantCatalogs);
        }
    }, [selectedCatalogIds, catalogData, setSelectedCatalogList]);

    useEffect(() => {
        const defaultParentCategory = categoriesData?.filter((option: any) => {
            return option.id === category?.parentCategory?.toString();
        });
        const defaultParentCategoryOutput = defaultParentCategory?.map((category) => ({
            text: category.categoryName,
            value: category.categoryId,
        }));
        setDefaultParent(defaultParentCategoryOutput);
    }, [categoriesData, category]);

    useEffect(() => {
        if (selectedCategoryToEdit) {
            fetchCategoryById({ variables: { categoryId: selectedCategoryToEdit } });
        }
    }, [selectedCategoryToEdit, fetchCategoryById]);

    useEffect(() => {
        if (!catalogData?.length) {
            setSelectedCatalogIds(null);
        }
    }, [catalogData?.length]);

    useEffect(() => {
        const dataArray = [];

        category?.itemCatalogCategory?.forEach((data) => {
            if (data?.category?.id === category?.id) {
                const object = {
                    catalogId: data?.catalog?.catalogId,
                };
                dataArray?.push(object);
            }
        });
        const set = new Set(dataArray);
        const uniqueSetValues = [...set];
        const catalogsList = catalogData?.map((catalog) => ({
            cat: catalog.catalogId,
            key: `${catalog.partner.partnerName} Catalog`,
        }));
        const filteredCatalog = catalogsList?.filter((cat) =>
            uniqueSetValues?.some((cate) => cat?.cat === cate?.catalogId),
        );
        const filteredCatalogIds = filteredCatalog?.map((filteredCatalog) => filteredCatalog?.cat);
        setCategoriesCatalog(filteredCatalog);
        setSelectedCatalogIds(filteredCatalogIds);
    }, [catalogData, category?.itemCatalogCategory, setSelectedCatalogIds]);

    useEffect(() => {
        const catalogCategoryItems = [];
        categoryQueryData?.category?.itemCatalogCategory?.forEach((data) => {
            if (
                (data?.category?.id === category?.id &&
                    data?.item?.itemStatus === ItemStatus.PUBLISHED) ||
                data?.item?.itemStatus === ItemStatus.UNPUBLISHED
            ) {
                catalogCategoryItems?.push(data?.item);
            }
        });

        if (categoryQueryData?.category?.itemOrder) {
            const orderedProductIdsList = categoryQueryData?.category?.itemOrder
                ?.split(',')
                .map(Number);
            const ordered = orderedProductIdsList?.reduce((r, v, i) => ((r[v] = i), r), {});
            const orderedProductList = catalogCategoryItems
                ?.slice()
                .sort((a, b) => ordered[a['id']] - ordered[b['id']]);
            setPreviousSelectedItems(orderedProductList);
        } else {
            setPreviousSelectedItems(catalogCategoryItems);
        }
    }, [categoryQueryData?.category]);

    useEffect(() => {
        const emptyCatalogs = selectedItemsCatalogs?.filter(
            (catalogItem) => !catalogItem?.catalogs && catalogItem?.catalogs?.length === 0,
        );
        if (!selectedItemsCatalogs?.length && clicked && emptyCatalogs?.length) {
            setError(true);
            setMessage('Product catalogs should not be empty');
            setShowToast(true);

            setClicked(false);

            setTimeout(() => {
                setShowToast(false);
                setError(true);
            }, 4000);
        } else if (emptyCatalogs?.length === 0) {
            setError(false);
        }
    }, [selectedItemsCatalogs, clicked, setError]);

    if (loading) return <Loader />;
    if (category?.parentCategory && !defaultParent[0]?.value) return <Loader />;
    if (category?.catalog?.catalogId.toString() && !defaultCatalogsList[0]?.value)
        return <Loader />;
    if (allCategoriesLoading) return <Loader />;
    if (catalogLoading) return <Loader />;

    return (
        <Formik
            initialValues={{
                categoryName: category?.categoryName,
                categoryDescription: category?.categoryDescription,
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={({ categoryName }) => {
                if (descriptionLong && categoryName) {
                    if (catalogError === false) {
                        const itemsCatalogsFinalList = [];

                        itemCatalogs?.forEach((itemCatalog) => {
                            itemCatalog?.catalogs?.forEach((catalog) => {
                                itemsCatalogsFinalList?.push({
                                    itemId: itemCatalog?.itemId,
                                    catalogId: catalog?.value,
                                });
                            });
                        });

                        saveCategory({
                            variables: {
                                category: {
                                    id: category?.id,
                                    categoryId: category?.categoryId,
                                    itemOrder: itemsCatalogsFinalList
                                        ?.map((item) => {
                                            return item?.itemId;
                                        })
                                        .join(','),
                                    parentCategory:
                                        selectedCategoryId?.categoryId ||
                                        category?.parentCategory ||
                                        null,
                                    categoryName: categoryName || category?.categoryName,
                                    categoryDescription:
                                        descriptionLong || category?.categoryDescription,
                                    categoryImage: thumbNail || category?.categoryImage,
                                    categoryCoverImage: coverImage || category?.categoryCoverImage,
                                    categoryIcon: thumbNail || category?.categoryIcon,
                                    isActive: true,
                                    isDeleted: false,
                                    itemCatalogCategory: itemsCatalogsFinalList?.map((item) => ({
                                        catalogId: item?.catalogId,
                                        itemId: item?.itemId,
                                    })),
                                },
                            },
                        });
                    } else {
                        setError(true);
                        setShowToast(true);
                        setMessage(ERROR_MESSAGE_CATALOG);
                    }
                } else {
                    setError(true);
                    setShowToast(true);
                    setMessage(ERROR_EMPTY_FIELDS);
                }
            }}
        >
            {({ handleSubmit, handleChange, values, errors, touched }) => (
                <>
                    <UnsaveChangesWarning
                        contentText={PRODUCT_ADD_EXIT_CONFIRM_MESSAGE}
                        messageTitle={PRODUCT_ADD_EXIT_CONFIRM_TITLE}
                        when={isDirty || window.onbeforeunload ? true : false}
                        navigate={(path) => history.push(path)}
                        shouldBlockNavigation={() => {
                            if (isDirty) {
                                return true;
                            }
                            return false;
                        }}
                        displayIcon={true}
                        displayIconName={'clarity_error-standard-line-svg.svg'}
                    />
                    {showToast && (
                        <Toast
                            setShowToast={setShowToast}
                            message={message}
                            width="w-10/12"
                            margin="ml-1"
                            error={error}
                        />
                    )}
                    <div className="pr-44 -ml-4">
                        <div className="grid grid-cols-8 gap-4">
                            <div className="col-start-1 col-end-5 mt-3 px-4">
                                <InputField
                                    id="categoryName"
                                    placeHolder="Enter Category title"
                                    name="Category title"
                                    labelWidth="w-25"
                                    maxLength={255}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                    }}
                                    value={values.categoryName}
                                />
                                <div className="w-full py-0.5 justify-end  flex  pl-4">
                                    <div className="font-poppins text-gray-400 mr-5">
                                        255 characters limited
                                    </div>
                                </div>
                                {errors.categoryName && touched.categoryName ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.categoryName}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-start-1 col-end-9 mt-5 flex flex-wrap relative px-4">
                                <div
                                    className={`text-gray-500  w-58 text-center font-poppins text-sm lg:text-sm h-max bg-white z-40 -mb-3 ml-4 border-opacity-20`}
                                >
                                    Category Description (Detailed)
                                </div>
                                <textarea
                                    className="p-4 z-0 min-w-full rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                    id="categoryDescription"
                                    name="categoryDescription"
                                    rows={3}
                                    cols={138}
                                    draggable={false}
                                    maxLength={300}
                                    value={values.categoryDescription}
                                    onInput={handleChange('categoryDescription')}
                                    onChange={(e) => {
                                        setDescriptionLong(e.target.value);
                                        setIsDirty(true);
                                    }}
                                    placeholder="Enter category Description"
                                />
                                <div className="w-full py-0.5 justify-end  flex  pl-4">
                                    <div className="font-poppins text-gray-400 mr-5">
                                        300 characters limited
                                    </div>
                                </div>
                                {errors.categoryDescription && touched.categoryDescription ? (
                                    <div className="text-red-500 text-xs -mt-4 md:text-sm">
                                        {errors.categoryDescription}
                                    </div>
                                ) : null}
                                {errors.categoryDescription && touched.categoryDescription
                                    ? window.scrollTo({ top: 0, behavior: 'smooth' })
                                    : null}
                            </div>

                            <div className="col-start-1 col-end-5 ">
                                <div className="flex order-1 flex-wrap flex-col relative mb-2 px-4">
                                    <div className="text-gray-500 w-44 text-center font-poppins z-30 text-sm lg:text-sm h-max bg-white -mb-3 ml-4 border-opacity-20">
                                        Select partner catalog
                                    </div>
                                    <div className="z-20">
                                        <Multiselect
                                            displayValue="key"
                                            style={{
                                                chips: {
                                                    background: '#c084fc',
                                                },
                                                multiselectContainer: {
                                                    color: 'black',
                                                },
                                                searchBox: {
                                                    border: '1px solid black',
                                                    'border-radius': '10px',
                                                    padding: '10px',
                                                },
                                            }}
                                            selectedValues={categoriesCatalog}
                                            options={catalogsListMultiple}
                                            avoidHighlightFirstOption={true}
                                            onSelect={onSelectCatalogs}
                                            onRemove={onRemoveCatalogs}
                                            showCheckbox
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="lg:col-start-1 lg:col-end-5 ">
                                <div className="flex flex-wrap flex-col relative mt-3  px-4">
                                    <div className="text-gray-500 w-30 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Parent category
                                    </div>
                                    <select
                                        className="p-4 lg:p-3  rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                        id="code"
                                        onChange={(e) => {
                                            setSelectedCategory(e.target.value);
                                            setIsDirty(true);
                                        }}
                                        onBlur={(e) => {
                                            setSelectedCategory(e.target.value);
                                        }}
                                        defaultValue={
                                            defaultParent[0]?.value ? defaultParent[0]?.value : 0
                                        }
                                    >
                                        <option value={0} key={1} disabled>
                                            None
                                        </option>
                                        {categoriesDropDownList?.map((option: any, index) => {
                                            return (
                                                <option value={option.value} key={index}>
                                                    {option.text}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-5 flex-col xl:flex-row pl-4 pr-64">
                            <div className="w-full">
                                <div className="flex my-3 mb-4">
                                    <div className="mr-1 font-poppins">
                                        Category thumbnail(optional)
                                    </div>
                                    <div className="text-gray-400">(Max 100X 100)</div>
                                </div>

                                <UploadCategoryImage
                                    width={'w-5/6'}
                                    height={thumbNail ? `h-80` : 'h-80'}
                                    isEdit={true}
                                    inputId="thumbnail"
                                    isThumbNail={true}
                                    imageProp={thumbNail}
                                    setImage={setThumbNail}
                                    isThumbnailImage={true}
                                />

                                <div className="text-gray-400  flex my-5">
                                    What can I upload?
                                    <div
                                        className=" py-0.5   pl-4"
                                        onMouseOver={() => setShowToolTip(true)}
                                        onMouseLeave={() => setShowToolTip(false)}
                                    >
                                        <img
                                            className={`w-5 h-6 ${
                                                showToolTip ? 'hidden' : 'block'
                                            }`}
                                            src="/images/icons/question2.png"
                                            alt="question"
                                            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => setShowToolTip(true)}
                                            onKeyDown={() => setShowToolTip(true)}
                                        />
                                        {showToolTip ? (
                                            <MuiThemeProvider theme={theme}>
                                                <Tooltip
                                                    key={2}
                                                    title={'Upload (PNG,JPEG,JPG)'}
                                                    arrow
                                                    placement="right-start"
                                                >
                                                    <img
                                                        className="w-5 h-6"
                                                        src="/images/icons/question2.png"
                                                        alt="question"
                                                    />
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <div className="flex my-3">
                                    <div className="mr-1 font-poppins">
                                        Category cover image (optional)
                                    </div>
                                    <div className="text-gray-400"> (Max 2048 X 2048)</div>
                                </div>

                                <UploadCategoryImage
                                    width={'w-5/6'}
                                    height={coverImage ? `h-80` : 'h-80'}
                                    isEdit={true}
                                    inputId="coverImage"
                                    imageProp={coverImage}
                                    setImage={setCoverImage}
                                    isThumbnailImage={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="border-2 border-gray-300 rounded-lg mr-5">
                        <CategoryItemSelectionView
                            selectedCatalogs={selectedCatalogList}
                            setSelectedItemsCatalogs={setSelectedItemsCatalogs}
                            previousSelectedItems={previousSelectedItems}
                            setPreviousSelectedItems={setPreviousSelectedItems}
                            previousSelectedItemCatalogs={itemsCatalogsList}
                            itemCatalogs={itemCatalogs}
                            setItemCatalogs={setItemCatalogs}
                            initialItemCatalogs={initialItemCatalogs}
                            setInitialItemCatalogs={setInitialItemCatalogs}
                            activeCatalogs={catalogsListMultiple.map(
                                (activeCatalog) => activeCatalog.cat,
                            )}
                        />
                    </div>
                    {catalogError ? (
                        <div className="text-red-500">Catalogs should not be empty</div>
                    ) : null}
                    <div className="w-full py-4 justify-end bg-gray-100 flex mt-3 pr-8">
                        <Buttons
                            name="Cancel"
                            type="button"
                            buttonType="secondary-border-black"
                            id="Cancel"
                            size="e-small"
                            other="mr-3"
                            onclick={() => {
                                history.push('/products/categories');
                            }}
                        />
                        <Buttons
                            name={queryLoading ? <NormalLoader /> : 'Update'}
                            type="submit"
                            buttonType="primary"
                            id="update"
                            size="e-small"
                            disabled={!(selectedItemsCatalogs?.length > 0) ? true : false}
                            onclick={() => {
                                setIsDirty(false);
                                setClicked(true);
                                handleSubmit();
                            }}
                        />
                    </div>
                </>
            )}
        </Formik>
    );
}
