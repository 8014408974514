export enum ItemStatus {
    PUBLISHED = 'PUBLISHED',
    UNPUBLISHED = 'UNPUBLISHED',
    DELETED = 'DELETED',
}

export enum ItemType {
    VIRTUAL = 'VIRTUAL',
    PHYSICAL = 'PHYSICAL',
}
