/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Dropdown } from 'semantic-ui-react';
import { USER_TYPE_EXISTING, USER_TYPE_INVITED } from '../../../constants/users';
import { FETCH_ADMIN_ONE_PROFILE } from '../../../queries/AdminProfileQueries';
import { getUserDetails, getActiveSessions } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { getCookie } from '../../../utils/cookiesService';
import { countryOptions } from '../../../utils/countries';
import Loader from '../../../utils/loader';
import { Buttons } from '../../ui/atoms/Button';
import { InputField } from '../../ui/atoms/InputField';
import ActivityTable from '../profileScreens/ActivityLogTable';

export default function ViewOneUserScreen() {
    const [country, setCountry] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const selectedUserType = localStorage.getItem('user_type');
    const clickedUser = localStorage.getItem('view-user');
    const clickedUserExitingEmail = localStorage.getItem('current_user_email');
    const clickedUserExisted = localStorage.getItem('current_user_username');

    const { data, loading } = useQuery(FETCH_ADMIN_ONE_PROFILE, {
        variables: { userName: clickedUser },
        onCompleted: () => {
            setFirstName(data?.user?.firstName);
            setLastName(data?.user?.lastName);
            setNumber(data?.user?.contact?.phoneNumber);
            setEmail(data?.user?.contact?.primaryEmail);
            setCountry(data?.user?.contact?.countryCode);
        },
    });

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getUserDetails(clickedUserExitingEmail));
    }, [dispatch, clickedUser, clickedUserExitingEmail]);

    const currentInvitedUser = useSelector((state: RootState) => state.auth.fetchedUser);

    const currentUser = data?.user;
    const code = countryOptions?.find((country: any) => {
        return country?.value === currentUser?.contact?.countryCode;
    });

    const countryFlag = countryOptions?.filter((countryDetail) => {
        return countryDetail?.value === country;
    });

    if (!countryFlag[0]?.value && selectedUserType === USER_TYPE_EXISTING) {
        return <Loader />;
    } else if (currentInvitedUser?.length === 0 && selectedUserType === USER_TYPE_INVITED) {
        return <Loader />;
    }

    if (loading) return <Loader />;

    return (
        <div className="flex flex-col h-screen justify-between w-full">
            <div className="grid grid-cols-8 gap-4">
                <div className="text-lg font-poppins font-bold px-4 ">User details</div>
                <div className="col-start-1 col-end-4">
                    <InputField
                        readonly={true}
                        id="firstName"
                        placeHolder={firstName}
                        name="First name"
                        labelWidth="w-21"
                        value={
                            selectedUserType === USER_TYPE_EXISTING
                                ? firstName
                                : currentInvitedUser[0]?.firstName
                        }
                    />
                </div>

                <div className="col-end-7 col-span-3">
                    <InputField
                        readonly={true}
                        id="lastName"
                        placeHolder="Walker"
                        name=" Last name"
                        labelWidth="w-21"
                        value={
                            selectedUserType === USER_TYPE_EXISTING
                                ? lastName
                                : currentInvitedUser[0]?.lastName
                        }
                    />
                </div>

                <div className="col-start-1 col-end-4 mt-2">
                    <div className="flex flex-wrap flex-col relative">
                        <div className="text-gray-500 w-28 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                            Select country
                        </div>

                        <Dropdown
                            className="dropdownRed"
                            placeholder="Country"
                            id="countryCode"
                            disabled={true}
                            fluid
                            key={'countryCode'}
                            search
                            selection
                            defaultValue={countryFlag[0]?.value}
                            options={countryOptions}
                        />
                    </div>
                </div>

                <div className="col-start-1 col-span-1 lg:col-end-5 lg:col-span-1 ">
                    <div className="flex flex-wrap flex-col relative mt-3">
                        <select
                            className="p-4 lg:p-3  rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                            defaultValue={code?.dial_value}
                            disabled={true}
                            id="code"
                            value={code?.dial_value}
                        >
                            {countryOptions.map((country: any, index: any) => {
                                return (
                                    <option
                                        value={country.dial_value}
                                        key={index}
                                        className="text-gray-400"
                                    >
                                        {country.dial_value}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>

                <div className=" col-start-3 col-span-4  lg:col-end-7 lg:col-span-2 mt-0.5">
                    <InputField
                        readonly={true}
                        id="phoneNumber"
                        placeHolder="phoneNumber"
                        labelWidth="w-32"
                        name="Contact number"
                        value={number}
                    />
                </div>
                <div className="col-start-1 col-end-4">
                    <InputField
                        readonly={true}
                        id="email"
                        placeHolder="Lucy"
                        name="Email address"
                        labelWidth="w-30"
                        value={
                            selectedUserType === USER_TYPE_EXISTING
                                ? email
                                : currentInvitedUser[0]?.email
                        }
                    />
                </div>

                <div className="col-end-7 col-span-3">
                    <InputField
                        readonly={true}
                        id="role"
                        placeHolder="Walker"
                        name="User role"
                        labelWidth="w-23"
                        value={'Admin'}
                    />
                </div>
            </div>

            <div className="mr-8 my-10">
                <ActivityTable username={clickedUser} />
            </div>
            <div className="w-full py-4 -mt-10 justify-end bg-gray-100 flex pr-10">
                <Buttons
                    name="Close"
                    type="submit"
                    buttonType="primary"
                    id="update"
                    size="e-small"
                    onclick={() => {
                        history.push('/users');
                    }}
                />
            </div>
        </div>
    );
}
