/**
 * Accepts an array of data of any type, given that they have the fields
 * `isActive` and `isDeleted`. Then sorts these data into 3 arrays depending
 * on their status.
 *
 * @param items array of data
 * @returns an array of arrays containing sorted data
 */
export const sortDataByStatus = (data: any[]) => {
    const activeData = [];
    const inactiveData = [];
    const trashedData = [];

    data.forEach((record) => {
        if (record.isActive) {
            activeData.push(record);
        } else if (!record.isActive && !record.isDeleted) {
            inactiveData.push(record);
        } else if (!record.isActive && record.isDeleted) {
            trashedData.push(record);
        }
    });

    return [activeData, inactiveData, trashedData];
};

/**
 * Converts sorted data into objects that can be passed to the FilterDropDownReports component,
 * including data and data separators.
 * @param dataType type of the data
 * @param data data object
 * @returns formatted object
 */
export const convertDataToFilterDropdownOptions = (
    dataType: 'supplier' | 'partner' | 'category',
    data: any,
) => {
    if (data.isSeparator) {
        return {
            value: '',
            text: data.text,
            isSeparator: true,
        };
    } else {
        return {
            value: data.id,
            text: data[`${dataType}Name`],
        };
    }
};
