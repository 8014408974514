import { gql } from '@apollo/client';

export const FETCH_GLOBAL_CURRENCIES = gql`
    query {
        globalCurrencyConversions {
            id
            sourceCurrency
            targetCurrency
            conversionRate
            globalCurrencyConversionReference
            updatedAt
            updatedBy
        }
    }
`;

export const UPDATE_GLOBAL_CURRENCY_BY_ID = gql`
    mutation ($globalCurrencyConversionInput: GlobalCurrencyConversionInput!) {
        updateGlobalCurrencyConversion(
            globalCurrencyConversionInput: $globalCurrencyConversionInput
        ) {
            id
            sourceCurrency
            targetCurrency
            conversionRate
            globalCurrencyConversionReference
            updatedAt
            updatedBy
        }
    }
`;

export const UPDATE_GLOBAL_CURRENCY_BY_SOURCE_AND_TARGET_CURRENCIES = gql`
    mutation ($globalCurrencyConversionInput: GlobalCurrencyConversionSecondInput!) {
        updateGlobalCurrencyConversionBySourceAndTargetCurrencies(
            globalCurrencyConversionInput: $globalCurrencyConversionInput
        ) {
            id
            sourceCurrency
            targetCurrency
            conversionRate
            globalCurrencyConversionReference
            updatedAt
            updatedBy
        }
    }
`;
