import React from 'react';

interface Props {
    text: string;
    count: number;
    selected: boolean;
}

const TextWithCount = ({ text, count, selected }: Props) => {
    return (
        <div
            className={`text-sm md:text-base lg:text-lg justify-start bottom-2 flex w-full ${
                selected ? `text-purple-500 font-semibold` : 'text-gray-500'
            }`}
        >
            {`${text} `} {count < 10 && count > 0 ? `(0${count})` : `(${count})`}
        </div>
    );
};

export default TextWithCount;
