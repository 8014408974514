import React, { ReactElement, useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import jwt from 'jwt-decode';
import { adminClientLogin, updateNewUserPassword } from '../../../redux/rootActions';
import passwordGenerator from '../../../service/encryption/passwordGenerator';
import { getCookie } from '../../../utils/cookiesService';
import { Buttons } from '../../ui/atoms/Button';
import { Images } from '../../ui/atoms/Images';
import { RootState } from '../../../redux/rootReducer';

interface Props {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    setPasswordUpdateSuccess: (value: boolean) => void;
}

export default function ChangePasswordModel({
    showModal,
    setShowModal,
    setPasswordUpdateSuccess,
}: Props): ReactElement {
    const dispatch = useDispatch();

    const isPasswordChangeSuccess = useSelector(
        (state: RootState) => state.auth.isNewUserPasswordSuccess,
    );

    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [repeatPassword, setRepeatPassword] = useState('');

    const authToken = getCookie('access_token');

    let authTokenDetails;

    if (authToken) {
        authTokenDetails = jwt(authToken);
    }

    useEffect(() => {
        dispatch(adminClientLogin());
    }, [dispatch]);

    useEffect(() => {
        if (isPasswordChangeSuccess) {
            setPasswordUpdateSuccess(true);
            setShowModal(false);
        }
    }, [isPasswordChangeSuccess, setPasswordUpdateSuccess, setShowModal]);

    const validationSchema = Yup.object({
        password: Yup.string()
            .required('Please enter your password')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{12,})/,
                'Your password should contain at least twelve characters with upper and lower case letters, numbers and symbols like !@#$%^',
            ),
        repeatPassword: Yup.string()
            .required('Please confirm your password')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });

    useEffect(() => {
        const generatedPassword = passwordGenerator();
        setPassword(generatedPassword);
    }, []);

    const passwordShow = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Modal
            open={showModal}
            onClose={() => {
                setShowModal(false);
            }}
            center={true}
            styles={{ modal: { borderRadius: 8, maxWidth: '706px', width: '80%' } }}
            focusTrapped={false}
        >
            <>
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-poppins  font-semibold flex m-auto">
                        Change password
                    </h3>
                </div>
                <Formik
                    initialValues={{
                        password: password,
                        repeatPassword: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async ({ password }) => {
                        dispatch(updateNewUserPassword(password, authTokenDetails?.sub));
                    }}
                >
                    {({ values, handleChange, handleSubmit, errors, touched }) => (
                        <div className="flex flex-col flex-wrap mt-5 md:mt-1 mx-10">
                            <div className="flex justify-center p-4">
                                <Images
                                    height="h-15"
                                    width="w-15"
                                    src="/images/icons/change-password.svg"
                                    alt="Change Password"
                                />
                            </div>
                            <div className="flex justify-center text-gray-500 text-base font-poppins font-normal mt-4">
                                A new strong password has been generated for you.
                            </div>
                            <div className="flex flex-col mt-4 mb-1 px-24">
                                <div className="text-gray-500 text-sm  w-max font-poppins  h-max bg-white z-30 ml-4 px-1">
                                    Enter your password
                                </div>

                                <div className="relative">
                                    <input
                                        className="p-4 md:p:3  w-full rounded-xl outline-none -mt-3 font-poppins bg-gray-1f00  border-2 border-gray-400  hover:border-purple-500"
                                        id="password"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Admin123"
                                        onChange={handleChange('password')}
                                        value={values.password}
                                    />
                                    {errors.password && touched ? (
                                        <div
                                            className="text-red-500 text-xs mt-1 md:text-sm"
                                            id="enterPassword_error"
                                        >
                                            {errors.password}
                                        </div>
                                    ) : null}

                                    <div className="absolute inset-y-0 right-0 pr-3 pt-1 flex text-base item-center">
                                        <img
                                            src="/images/icons/eye 1.png"
                                            className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                showPassword ? 'block' : 'hidden'
                                            }`}
                                            onClick={passwordShow}
                                            alt="show-password"
                                            role="presentation"
                                            draggable="false"
                                        />

                                        <img
                                            src="/images/icons/eye-hide.png"
                                            className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                showPassword ? 'hidden' : 'block'
                                            }`}
                                            alt="hide-password"
                                            role="presentation"
                                            onClick={passwordShow}
                                            draggable="false"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col mt-5 mb-6 px-24">
                                <div className="text-gray-500 text-sm  w-max font-poppins  h-max bg-white z-30 ml-4 px-1">
                                    Repeat your password
                                </div>

                                <div className="relative">
                                    <input
                                        className="p-4 md:p:3  w-full rounded-xl outline-none -mt-3 font-poppins bg-gray-1f00  border-2 border-gray-400  hover:border-purple-500"
                                        id="repeatPassword"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder=""
                                        onChange={(e) => {
                                            setRepeatPassword(e.target.value);
                                            handleChange(e);
                                        }}
                                        value={values.repeatPassword}
                                    />
                                    {errors.repeatPassword && touched ? (
                                        <div
                                            className="text-red-500 text-xs mt-1 md:text-sm"
                                            id="confirmPassword_error"
                                        >
                                            {errors.repeatPassword}
                                        </div>
                                    ) : null}

                                    <div className="absolute inset-y-0 right-0 pr-3 pt-1 flex text-base item-center">
                                        <img
                                            src="/images/icons/eye 1.png"
                                            className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                showPassword ? 'block' : 'hidden'
                                            }`}
                                            onClick={passwordShow}
                                            alt="show-password"
                                            role="presentation"
                                            draggable="false"
                                        />

                                        <img
                                            src="/images/icons/eye-hide.png"
                                            className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                showPassword ? 'hidden' : 'block'
                                            }`}
                                            alt="hide-password"
                                            role="presentation"
                                            onClick={passwordShow}
                                            draggable="false"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="pt-5 flex align-middle justify-end xl:mb-10">
                                <Buttons
                                    name="Cancel"
                                    type="submit"
                                    buttonType="secondary-main-grey"
                                    id="cancel"
                                    size="small"
                                    onclick={() => {
                                        setShowModal(false);
                                    }}
                                />
                                <Buttons
                                    name="Update"
                                    type="submit"
                                    buttonType="primary"
                                    id="resetPassword"
                                    size="small"
                                    padding="py-2"
                                    disabled={!repeatPassword}
                                    onclick={() => handleSubmit()}
                                />
                            </div>
                        </div>
                    )}
                </Formik>
            </>
        </Modal>
    );
}
