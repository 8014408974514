import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createUser, getRoles, resetPasswordAction } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { Buttons } from '../../ui/atoms/Button';
import { InputField } from '../../ui/atoms/InputField';

export default function InviteUserModal() {
    const dispatch = useDispatch();
    const [userRole, setUserRole] = useState('');
    const [loading, setLoading] = useState(false);

    const currentTimeInMille = new Date().valueOf();
    const history = useHistory();

    useEffect(() => {
        dispatch(getRoles());
    }, [dispatch]);

    const roles = useSelector((state: RootState) => state.auth.roles);

    const isNewUserEmailSent = useSelector((state: RootState) => state.auth.isEmailSent);

    useEffect(() => {
        if (isNewUserEmailSent) {
            window.location.reload();
            history.push(`/adminProfile/dashboard`);
        }
    }, [isNewUserEmailSent, history]);

    const sendNewUSerEmailErrorMessage = useSelector(
        (state: RootState) => state.auth.sendNewUSerEmailErrorMessage,
    );

    const sendInvite = () => {
        if (isNewUserEmailSent) {
            return <div className="text-green-500 text-center text-xl mt-1 ">Email SENT !!!</div>;
        }
    };

    const validationSchema = Yup.object({
        email: Yup.string().required('Email cannot be blank'),
    });

    return (
        <div>
            <div>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-2/3 my-6 mx-auto max-w-3xl">
                        <Formik
                            initialValues={{
                                email: '',
                                role: userRole,
                            }}
                            validationSchema={validationSchema}
                            onSubmit={async ({ email }) => {
                                setLoading(true);
                                dispatch(createUser(currentTimeInMille, email));
                                dispatch(resetPasswordAction(email));
                                setLoading(false);
                                sendInvite();
                            }}
                        >
                            {({ values, handleChange, handleSubmit, handleBlur }) => (
                                <form
                                    onSubmit={(event) => {
                                        event.preventDefault();
                                        handleSubmit();
                                    }}
                                >
                                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                        <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                            <h3 className="text-xl font-poppins  font-semibold flex m-auto">
                                                Invite New User
                                            </h3>
                                        </div>

                                        <div className="relative p-6  flex flex-col">
                                            <div className="flex flex-wrap flex-col mb-3 lg:mb-5 px-4">
                                                <div className="flex flex-wrap flex-col lg:mb-5">
                                                    <InputField
                                                        placeHolder="Lucyadmin@swarmio.com"
                                                        name="New email address"
                                                        id="email"
                                                        onChange={handleChange('email')}
                                                        value={values.email}
                                                    />
                                                </div>
                                                <div className="flex flex-wrap flex-col relative mt-3">
                                                    <div className="text-gray-500 text-sm  w-max font-poppins  h-max bg-white z-30 ml-4">
                                                        User Role
                                                    </div>
                                                    <select
                                                        className="p-4 lg:p-3 -mt-2  rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                                        defaultValue={''}
                                                        id="role"
                                                        onChange={(event) => {
                                                            setUserRole(event.target.value);
                                                            handleChange('role');
                                                        }}
                                                        onBlur={() => handleBlur('role')}
                                                    >
                                                        {roles?.map((role: any) => {
                                                            return (
                                                                <option
                                                                    key={role.id}
                                                                    value={role.name}
                                                                >
                                                                    {role.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        {isNewUserEmailSent ? (
                                            <div className="text-green-500 text-center text-xl mt-1 ">
                                                EMAIL SENT !!!
                                            </div>
                                        ) : null}

                                        {sendNewUSerEmailErrorMessage ? (
                                            <div className="text-red-500 text-center text-xl mt-1 ">
                                                {sendNewUSerEmailErrorMessage}
                                            </div>
                                        ) : null}

                                        {loading ? (
                                            <div className="text-red-500 text-center text-xl mt-1 ">
                                                <p>loading .....</p>
                                            </div>
                                        ) : null}

                                        <div className="flex flex-row mt-6 justify-end m-4">
                                            <Buttons
                                                name="Cancel"
                                                type="button"
                                                buttonType="secondary-border-black"
                                                id="Change email address"
                                                size="small"
                                                other="mr-3"
                                                onclick={() =>
                                                    history.push('/adminProfile/dashboard')
                                                }
                                            />
                                            <Buttons
                                                name="Invite"
                                                type="submit"
                                                buttonType="primary"
                                                id="Change email address"
                                                size="small"
                                                padding="p-1"
                                            />
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
