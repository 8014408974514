import { Formik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { InputField } from '../../ui/atoms/InputField';
import ViewEditCurrencyPrices from './ViewEditCurrencyPrices';

interface Props {
    selectedPartner: any;
    setEditedId?: any;
}
export default function PointsQuickEditView({ selectedPartner }: Props) {
    const price = useSelector((state: RootState) => state.auth.price);
    const cPriceAdd = localStorage.getItem('cPrice');
    const currency = localStorage.getItem('currency');
    const [openCurrencyModal, setOpenCurrencyModal] = useState(false);

    const handleShowCurrencyPrice = () => {
        setOpenCurrencyModal(true);
    };

    return (
        <>
            <div className="w-full">
                <ViewEditCurrencyPrices
                    openModal={openCurrencyModal}
                    setOpenModal={setOpenCurrencyModal}
                    partner={selectedPartner}
                    costPrice={price || Number(cPriceAdd)}
                    currency={currency}
                />
            </div>
            <div className="w-full">
                <Formik
                    initialValues={{
                        PID: selectedPartner?.pid,
                        partnerName: selectedPartner?.partnerName,
                        markupPrice:
                            (Math.round(selectedPartner?.markupPrice * 100) / 100).toFixed(2) ||
                            price,
                        conversionPoints: selectedPartner?.pointsConversionRate,
                        markedUpPercentage: selectedPartner?.markedUpPercentage,
                    }}
                    onSubmit={undefined}
                >
                    {({ values }) => (
                        <>
                            <div className="flex flex-row p-4 w-full">
                                <div className="flex flex-row w-full">
                                    <div className="flex  w-full fill-current  rounded-lg p-2">
                                        <InputField
                                            id="PID"
                                            name="PID"
                                            readonly
                                            value={values.PID}
                                            bgColourGray={true}
                                        />
                                        <div className="ml-2 mt-5">
                                            <img
                                                src="/images/icons/lock.png"
                                                className={`text-gray-700 cursor-pointer w-4 h-5 block`}
                                                alt="show-password"
                                                role="presentation"
                                                draggable="false"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex  w-full ml-3  fill-current  rounded-lg p-2">
                                        <InputField
                                            id="partnerName"
                                            readonly
                                            name="Partner name"
                                            bgColourGray={true}
                                            value={values.partnerName}
                                        />
                                        <div className="ml-4 mt-5">
                                            <img
                                                src="/images/icons/lock.png"
                                                className={`text-gray-700 cursor-pointer w-4 h-5 block`}
                                                alt="show-password"
                                                role="presentation"
                                                draggable="false"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row p-4 w-full">
                                <div className="flex flex-row w-full">
                                    <div className="flex  w-full fill-current  rounded-lg p-2">
                                        <InputField
                                            id="markedUpPercentage"
                                            value={values.markedUpPercentage}
                                            name="Marked up percentage(%)"
                                            readonly
                                            bgColourGray={true}
                                        />
                                        <div className="ml-2 mt-5">
                                            <img
                                                src="/images/icons/lock.png"
                                                className={`text-gray-700 cursor-pointer w-4 h-5 block`}
                                                alt="show-password"
                                                role="presentation"
                                                draggable="false"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-full ml-3 fill-current rounded-lg p-2 items-center">
                                        {/* <InputField
                                            id="markupPrice"
                                            value={values.markupPrice}
                                            name="Marked up price(USD)"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setMarkupPrice(e.target.value);
                                                setIsDirty(true);
                                                setIsDirtyMarkedUpPrice(true);
                                            }}
                                            type="number"
                                            bgColourGray={false}
                                            min="0"
                                        /> */}
                                        <button
                                            role="link"
                                            className="font-poppins text-purple-500 font-semibold hover:underline cursor-pointer ml-2"
                                            key={values?.PID}
                                            onClick={handleShowCurrencyPrice}
                                        >
                                            View prices
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        </>
    );
}
