import { useEffect, useState } from 'react';
import IconButton from '../atoms/IconButton';
import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import {
    GLOBAL_CURRENCY_EDIT_ERROR_BODY,
    GLOBAL_CURRENCY_EDIT_ERROR_TITLE,
} from '../../../constants/globalCurrencyConversions';

interface Props {
    initialValue: any;
    row: any;
    updateValue: (id, value) => void;
    isDisabled?: boolean;
    isOverride?: boolean;
    setSelectedGlobalCurrency?: (value) => void;
    id?: string;
}

export default function EditableCell({
    initialValue,
    row,
    updateValue,
    isDisabled,
    isOverride,
    setSelectedGlobalCurrency,
    id,
}: Props) {
    const [value, setValue] = useState(initialValue);
    const [valueChanges, setValueChanges] = useState<boolean>(false);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

    const onChange = (e) => {
        setValueChanges(true);
        setValue(e.target.value);
    };

    const handleClickSave = () => {
        if (setSelectedGlobalCurrency) {
            setSelectedGlobalCurrency(row?.original);
        }

        updateValue(row?.original?.id || row?.index, value);
        setValueChanges(false);
    };
    const handleClickClose = () => {
        setValue(initialValue ?? '');
        setValueChanges(false);
    };

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <div>
            <div className="w-full flex flex-row">
                <div className="flex w-28">
                    <input
                        id={id || ''}
                        className={`p-2 w-full border-2 focus:border-purple-400 outline-none rounded-lg ${
                            isOverride ? 'border-red-400' : ''
                        }`}
                        value={value}
                        onChange={onChange}
                        type="number"
                        placeholder={value ?? 'Not Set'}
                        disabled={isDisabled ?? false}
                    />
                </div>
                {valueChanges && (
                    <>
                        <div className="flex">
                            <IconButton
                                id={id ? `${id}-save-button` : 'save-button'}
                                disabled={false}
                                iconUrl="/images/icons/check-lg.svg"
                                onClick={() => {
                                    if (value <= 0) {
                                        setShowWarningModal(true);
                                    } else {
                                        handleClickSave();
                                    }
                                }}
                            />
                        </div>
                        <div className="flex ml-2">
                            <IconButton
                                id={id ? `${id}-cancel-button` : 'cancel-button'}
                                disabled={false}
                                iconUrl="/images/icons/x-lg.svg"
                                onClick={() => {
                                    handleClickClose();
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
            <WarningMessageModal
                showWarningModal={showWarningModal}
                setShowWarningModal={setShowWarningModal}
                warningModalBody={GLOBAL_CURRENCY_EDIT_ERROR_BODY}
                warningModalTitle={GLOBAL_CURRENCY_EDIT_ERROR_TITLE}
            />
        </div>
    );
}
