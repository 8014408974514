import React, { ReactElement } from 'react';
import { Modal } from 'react-responsive-modal';
import { useMutation, useQuery } from '@apollo/client';
import {
    FETCH_ADMIN_ONE_PROFILE,
    SEND_VERIFICATION_EMAIL,
} from '../../../queries/AdminProfileQueries';
import { Buttons } from '../../ui/atoms/Button';
import { Images } from '../../ui/atoms/Images';
import { getCookie } from '../../../utils/cookiesService';
import jwt from 'jwt-decode';

interface Props {
    showVerifyModal: boolean;
    setShowVerifyModal: (value: boolean) => void;
}

export default function VerifyEmailAddressRequestModal({
    showVerifyModal,
    setShowVerifyModal,
}: Props): ReactElement {
    const token = getCookie('access_token');
    const tokenDetails: any = token ? jwt(token) : null;
    const { data, loading } = useQuery(FETCH_ADMIN_ONE_PROFILE, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        variables: { userName: tokenDetails?.preferred_username },
        skip: !token,
    });

    const [sendEmailVerification, { error: emailError }] = useMutation(SEND_VERIFICATION_EMAIL, {
        refetchQueries: [FETCH_ADMIN_ONE_PROFILE],
    });

    const currentUser = data?.user;

    if (loading) return <p>Loading please wait...</p>;

    return (
        <Modal
            open={showVerifyModal}
            onClose={() => {
                setShowVerifyModal(false);
            }}
            center={true}
            styles={{ modal: { borderRadius: 8, maxWidth: '806px', width: '80%' } }}
            focusTrapped={false}
        >
            <>
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-poppins  font-semibold flex m-auto">
                        Verify admin email address
                    </h3>
                </div>

                <div className="relative p-6  flex flex-col">
                    <div className="flex m-auto mb-4">
                        <Images
                            width="w-16"
                            height="h-16"
                            src="/images/icons/verify.png"
                            alt="Email"
                        />
                    </div>
                    <div className="text-gray-400 text-lg flex mb-3 m-auto">
                        There is a pending change of the admin email to
                        <div className="mx-2 underline text-purple-500 ">
                            {currentUser?.contact?.primaryEmail}
                        </div>
                    </div>
                    <div className="text-gray-400 text-lg flex mb-3 m-auto">
                        Click on the link in your email inbox to complete verification.
                    </div>
                    <div className="text-gray-400 text-lg flex mb-3 m-auto">
                        Can’t find the email?
                        <div
                            className=" mx-2 underline text-purple-500 "
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                sendEmailVerification({
                                    variables: { email: currentUser?.contact?.primaryEmail },
                                });
                                if (!emailError) {
                                    setShowVerifyModal(false);
                                }
                            }}
                            onKeyDown={() => {
                                sendEmailVerification({
                                    variables: { email: currentUser?.contact?.primaryEmail },
                                });
                            }}
                        >
                            Resend
                        </div>
                    </div>
                </div>

                <div className="flex flex-row mt-6 justify-end m-4">
                    <Buttons
                        name="Okay"
                        type="submit"
                        buttonType="primary"
                        id="Change email address"
                        size="small"
                        onclick={() => {
                            setShowVerifyModal(false);
                        }}
                    />
                </div>
            </>
        </Modal>
    );
}
