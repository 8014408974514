import { useHistory } from 'react-router-dom';

export default function ValidUserLogin() {
    const history = useHistory();

    setTimeout(() => {
        history.push('/');
    }, 4000);

    return (
        <>
            <div className="container h-screen w-screen">
                <div
                    className="flex bg-cover bg-center   h-full w-full  md:h-screen md:w-screen  bg-no-repeat"
                    style={{ backgroundImage: `url(/images/background.svg)` }}
                >
                    <div className="container flex  bg-white flex-wrap flex-col max-w-2xl sm:m-auto  md:rounded-md md:mt-36 lg:mt-20 xl:mt-28 2xl:mt-auto ">
                        <div className="mb-7 md:mb-8 min-h-6 lg:mx-12 max-w-2xl">
                            <img
                                className="w-28 mx-auto  mt-14"
                                src="/images/favicon.png"
                                alt=""
                            ></img>

                            <div className=" flex flex-col flex-wrap justify-center -mt-4">
                                <div className="m-auto font-extrabold lg:text-2xl font-poppins sm:text-xl  justify-center mb-4">
                                    {'Sorry, unable to login'}
                                </div>
                                <img
                                    className=" mx-auto my-2 mb-4"
                                    src="/images/icons/lockSVG.svg"
                                    alt=""
                                />

                                <div className="m-auto  text-gray-400 font-poppins flex flex-col text-xs md:text-sm mb-8 md:mb-1 px-16">
                                    Entered login details are incorrect or permissions were not
                                </div>
                                <div className="m-auto  text-gray-400 font-poppins flex flex-col text-xs md:text-sm mb-8 md:mb-3 px-16">
                                    granted, please contact the admin.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
