export const countryOptions = [
    { text: 'Afghanistan', flag: 'af', value: 'AF', dial_value: '+93' },
    { text: 'Åland Islands', flag: 'ax', value: 'AX', dial_value: '+358' },
    { text: 'Albania', flag: 'al', value: 'AL', dial_value: '+355' },
    { text: 'Algeria', flag: 'dz', value: 'DZ', dial_value: '+213' },
    { text: 'American Samoa', flag: 'as', value: 'AS', dial_value: '+1684' },
    { text: 'Andorra', flag: 'ad', value: 'AD', dial_value: '+376' },
    { text: 'Angola', flag: 'ao', value: 'AO', dial_value: '+244' },
    { text: 'Anguilla', flag: 'ai', value: 'AI', dial_value: '+1264' },
    { text: 'Antigua and Barbuda', flag: 'ag', value: 'AG', dial_value: '+1268' },
    { text: 'Argentina', flag: 'ar', value: 'AR', dial_value: '+54' },
    { text: 'Armenia', flag: 'am', value: 'AM', dial_value: '+374' },
    { text: 'Aruba', flag: 'aw', value: 'AW', dial_value: '+297' },
    { text: 'Australia', flag: 'au', value: 'AU', dial_value: '+61' },
    { text: 'Austria', flag: 'at', value: 'AT', dial_value: '+43' },
    { text: 'Azerbaijan', flag: 'az', value: 'AZ', dial_value: '+994' },
    { text: 'Bahamas', flag: 'bs', value: 'BS', dial_value: '+1242' },
    { text: 'Bahrain', flag: 'bh', value: 'BH', dial_value: '+973' },
    { text: 'Bangladesh', flag: 'bd', value: 'BD', dial_value: '+880' },
    { text: 'Barbados', flag: 'bb', value: 'BB', dial_value: '+1246' },
    { text: 'Belarus', flag: 'by', value: 'BY', dial_value: '+375' },
    { text: 'Belgium', flag: 'be', value: 'BE', dial_value: '+32' },
    { text: 'Belize', flag: 'bz', value: 'BZ', dial_value: '+501' },
    { text: 'Benin', flag: 'bj', value: 'BJ', dial_value: '+229' },
    { text: 'Bermuda', flag: 'bm', value: 'BM', dial_value: '+1441' },
    { text: 'Bhutan', flag: 'bt', value: 'BT', dial_value: '+975' },
    {
        text: 'Bolivia, Plurinational State of bolivia',
        flag: 'bo',
        value: 'BO',
        dial_value: '+591',
    },
    { text: 'Bosnia and Herzegovina', flag: 'ba', value: 'BA', dial_value: '+387' },
    { text: 'Botswana', flag: 'bw', value: 'BW', dial_value: '+267' },
    { text: 'Bouvet Island', flag: 'bv', value: 'BV', dial_value: '+47' },
    { text: 'Brazil', flag: 'br', value: 'BR', dial_value: '+55' },
    { text: 'British Indian Ocean Territory', flag: 'io', value: 'IO', dial_value: '+246' },
    { text: 'Brunei Darussalam', flag: 'bn', value: 'BN', dial_value: '+673' },
    { text: 'Bulgaria', flag: 'bg', value: 'BG', dial_value: '+359' },
    { text: 'Burkina Faso', flag: 'bf', value: 'BF', dial_value: '+226' },
    { text: 'Burundi', flag: 'bi', value: 'BI', dial_value: '+257' },
    { text: 'Cambodia', flag: 'kh', value: 'KH', dial_value: '+855' },
    { text: 'Cameroon', flag: 'cm', value: 'CM', dial_value: '+237' },
    { text: 'Canada', flag: 'ca', value: 'CA', dial_value: '+1' },
    { text: 'Cape verde', flag: 'cv', value: 'CV', dial_value: '+238' },
    { text: 'Cayman Islands', flag: 'ky', value: 'KY', dial_value: '+345' },
    { text: 'Central African Republic', flag: 'cf', value: 'CF', dial_value: '+236' },
    { text: 'Chad', flag: 'td', value: 'TD', dial_value: '+235' },
    { text: 'Chile', flag: 'cl', value: 'CL', dial_value: '+56' },
    { text: 'China', flag: 'cn', value: 'CN', dial_value: '+86' },
    { text: 'Christmas Island', flag: 'cx', value: 'CX', dial_value: '+61' },
    { text: 'Cocos (Keeling) Islands', flag: 'cc', value: 'CC', dial_value: '+61' },
    { text: 'Colombia', flag: 'co', value: 'CO', dial_value: '+57' },
    { text: 'Comoros', flag: 'km', value: 'KM', dial_value: '+269' },
    { text: 'Congo', flag: 'cg', value: 'CG', dial_value: '+242' },
    {
        text: 'Congo, The Democratic Republic of the Congo',
        flag: 'cd',
        value: 'CD',
        dial_value: '+243',
    },
    { text: 'Cook Islands', flag: 'ck', value: 'CK', dial_value: '+682' },
    { text: 'Costa Rica', flag: 'cr', value: 'CR', dial_value: '+506' },
    { text: "Cote d'Ivoire", flag: 'ci', value: 'CI', dial_value: '+225' },
    { text: 'Croatia', flag: 'hr', value: 'HR', dial_value: '+385' },
    { text: 'Cuba', flag: 'cu', value: 'CU', dial_value: '+53' },
    { text: 'Cyprus', flag: 'cy', value: 'CY', dial_value: '+357' },
    { text: 'Czech Republic', flag: 'cz', value: 'CZ', dial_value: '+420' },
    { text: 'Denmark', flag: 'dk', value: 'DK', dial_value: '+45' },
    { text: 'Djibouti', flag: 'dj', value: 'DJ', dial_value: '+253' },
    { text: 'Dominica', flag: 'dm', value: 'DM', dial_value: '+1767' },
    { text: 'Dominican Republic', flag: 'do', value: 'DO', dial_value: '+1849' },
    { text: 'Ecuador', flag: 'ec', value: 'EC', dial_value: '+593' },
    { text: 'Egypt', flag: 'eg', value: 'EG', dial_value: '+20' },
    { text: 'El Salvador', flag: 'sv', value: 'SV', dial_value: '+503' },
    { text: 'Equatorial guinea', flag: 'gq', value: 'GQ', dial_value: '+240' },
    { text: 'Eritrea', flag: 'er', value: 'ER', dial_value: '+291' },
    { text: 'Estonia', flag: 'ee', value: 'EE', dial_value: '+372' },
    { text: 'Ethiopia', flag: 'et', value: 'ET', dial_value: '+251' },
    { text: 'Falkland Islands (Malvinas)', flag: 'fk', value: 'FK', dial_value: '+500' },
    { text: 'Faroe Islands', flag: 'fo', value: 'FO', dial_value: '+298' },
    { text: 'Fiji', flag: 'fj', value: 'FJ', dial_value: '+679' },
    { text: 'Finland', flag: 'fi', value: 'FI', dial_value: '+358' },
    { text: 'France', flag: 'fr', value: 'FR', dial_value: '+33' },
    { text: 'French guiana', flag: 'gf', value: 'GF', dial_value: '+594' },
    { text: 'French Polynesia', flag: 'pf', value: 'PF', dial_value: '+689' },
    { text: 'French Southern Territories', flag: 'tf', value: 'TF', dial_value: '+262' },
    { text: 'gabon', flag: 'ga', value: 'GA', dial_value: '+241' },
    { text: 'gambia', flag: 'gm', value: 'GM', dial_value: '+220' },
    { text: 'georgia', flag: 'ge', value: 'GE', dial_value: '+995' },
    { text: 'germany', flag: 'de', value: 'DE', dial_value: '+49' },
    { text: 'ghana', flag: 'gh', value: 'GH', dial_value: '+233' },
    { text: 'gibraltar', flag: 'gi', value: 'GI', dial_value: '+350' },
    { text: 'greece', flag: 'gr', value: 'GR', dial_value: '+30' },
    { text: 'greenland', flag: 'gl', value: 'GL', dial_value: '+299' },
    { text: 'grenada', flag: 'gd', value: 'GD', dial_value: '+1473' },
    { text: 'guadeloupe', flag: 'gp', value: 'GP', dial_value: '+590' },
    { text: 'guam', flag: 'gu', value: 'GU', dial_value: '+1671' },
    { text: 'guatemala', flag: 'gt', value: 'GT', dial_value: '+502' },
    { text: 'guernsey', flag: 'gg', value: 'GG', dial_value: '+44' },
    { text: 'guinea', flag: 'gn', value: 'GN', dial_value: '+224' },
    { text: 'guinea-Bissau', flag: 'gw', value: 'GW', dial_value: '+245' },
    { text: 'guyana', flag: 'gy', value: 'GY', dial_value: '+592' },
    { text: 'Haiti', flag: 'ht', value: 'HT', dial_value: '+509' },
    { text: 'Heard Island and Mcdonald Islands', flag: 'hm', value: 'HM', dial_value: '+672' },
    { text: 'Holy See (vatican City State)', flag: 'va', value: 'VA', dial_value: '+379' },
    { text: 'Honduras', flag: 'hn', value: 'HN', dial_value: '+504' },
    { text: 'Hong Kong', flag: 'hk', value: 'HK', dial_value: '+852' },
    { text: 'Hungary', flag: 'hu', value: 'HU', dial_value: '+36' },
    { text: 'Iceland', flag: 'is', value: 'IS', dial_value: '+354' },
    { text: 'India', flag: 'in', value: 'IN', dial_value: '+91' },
    { text: 'Indonesia', flag: 'id', value: 'ID', dial_value: '+62' },
    { text: 'Iran, Islamic Republic of Persian gulf', flag: 'ir', value: 'IR', dial_value: '+98' },
    { text: 'Iraq', flag: 'iq', value: 'IQ', dial_value: '+964' },
    { text: 'Ireland', flag: 'ie', value: 'IE', dial_value: '+353' },
    { text: 'Isle of Man', flag: 'im', value: 'IM', dial_value: '+44' },
    { text: 'Israel', flag: 'il', value: 'IL', dial_value: '+972' },
    { text: 'Italy', flag: 'it', value: 'IT', dial_value: '+39' },
    { text: 'Jamaica', flag: 'jm', value: 'JM', dial_value: '+1876' },
    { text: 'Japan', flag: 'jp', value: 'JP', dial_value: '+81' },
    { text: 'Jersey', flag: 'je', value: 'JE', dial_value: '+44' },
    { text: 'Jordan', flag: 'jo', value: 'JO', dial_value: '+962' },
    { text: 'Kazakhstan', flag: 'kz', value: 'KZ', dial_value: '+7' },
    { text: 'Kenya', flag: 'ke', value: 'KE', dial_value: '+254' },
    { text: 'Kiribati', flag: 'ki', value: 'KI', dial_value: '+686' },
    {
        text: "Korea, Democratic People's Republic of Korea",
        flag: 'kp',
        value: 'KP',
        dial_value: '+850',
    },
    { text: 'Korea, Republic of South Korea', flag: 'kr', value: 'KR', dial_value: '+82' },
    { text: 'Kosovo', flag: 'xk', value: 'XK', dial_value: '+383' },
    { text: 'Kuwait', flag: 'kw', value: 'KW', dial_value: '+965' },
    { text: 'Kyrgyzstan', flag: 'kg', value: 'KG', dial_value: '+996' },
    { text: 'Laos', flag: 'la', value: 'LA', dial_value: '+856' },
    { text: 'Latvia', flag: 'lv', value: 'LV', dial_value: '+371' },
    { text: 'Lebanon', flag: 'lb', value: 'LB', dial_value: '+961' },
    { text: 'Lesotho', flag: 'ls', value: 'LS', dial_value: '+266' },
    { text: 'Liberia', flag: 'lr', value: 'LR', dial_value: '+231' },
    { text: 'Libyan Arab Jamahiriya', flag: 'ly', value: 'LY', dial_value: '+218' },
    { text: 'Liechtenstein', flag: 'li', value: 'LI', dial_value: '+423' },
    { text: 'Lithuania', flag: 'lt', value: 'LT', dial_value: '+370' },
    { text: 'Luxembourg', flag: 'lu', value: 'LU', dial_value: '+352' },
    { text: 'Macao', flag: 'mo', value: 'MO', dial_value: '+853' },
    { text: 'Macedonia', flag: 'mk', value: 'MK', dial_value: '+389' },
    { text: 'Madagascar', flag: 'mg', value: 'MG', dial_value: '+261' },
    { text: 'Malawi', flag: 'mw', value: 'MW', dial_value: '+265' },
    { text: 'Malaysia', flag: 'my', value: 'MY', dial_value: '+60' },
    { text: 'Maldives', flag: 'mv', value: 'MV', dial_value: '+960' },
    { text: 'Mali', flag: 'ml', value: 'ML', dial_value: '+223' },
    { text: 'Malta', flag: 'mt', value: 'MT', dial_value: '+356' },
    { text: 'Marshall Islands', flag: 'mh', value: 'MH', dial_value: '+692' },
    { text: 'Martinique', flag: 'mq', value: 'MQ', dial_value: '+596' },
    { text: 'Mauritania', flag: 'mr', value: 'MR', dial_value: '+222' },
    { text: 'Mauritius', flag: 'mu', value: 'MU', dial_value: '+230' },
    { text: 'Mayotte', flag: 'yt', value: 'YT', dial_value: '+262' },
    { text: 'Mexico', flag: 'mx', value: 'MX', dial_value: '+52' },
    {
        text: 'Micronesia, Federated States of Micronesia',
        flag: 'fm',
        value: 'FM',
        dial_value: '+691',
    },
    { text: 'Moldova', flag: 'md', value: 'MD', dial_value: '+373' },
    { text: 'Monaco', flag: 'mc', value: 'MC', dial_value: '+377' },
    { text: 'Mongolia', flag: 'mn', value: 'MN', dial_value: '+976' },
    { text: 'Montenegro', flag: 'me', value: 'ME', dial_value: '+382' },
    { text: 'Montserrat', flag: 'ms', value: 'MS', dial_value: '+1664' },
    { text: 'Morocco', flag: 'ma', value: 'MA', dial_value: '+212' },
    { text: 'Mozambique', flag: 'mz', value: 'MZ', dial_value: '+258' },
    { text: 'Myanmar', flag: 'mm', value: 'MM', dial_value: '+95' },
    { text: 'Namibia', flag: 'na', value: 'NA', dial_value: '+264' },
    { text: 'Nauru', flag: 'nr', value: 'NR', dial_value: '+674' },
    { text: 'Nepal', flag: 'np', value: 'NP', dial_value: '+977' },
    { text: 'Netherlands', flag: 'nl', value: 'NL', dial_value: '+31' },
    { text: 'Netherlands Antilles', flag: '', value: 'AN', dial_value: '+599' },
    { text: 'New Caledonia', flag: 'nc', value: 'NC', dial_value: '+687' },
    { text: 'New Zealand', flag: 'nz', value: 'NZ', dial_value: '+64' },
    { text: 'Nicaragua', flag: 'ni', value: 'NI', dial_value: '+505' },
    { text: 'Niger', flag: 'ne', value: 'NE', dial_value: '+227' },
    { text: 'Nigeria', flag: 'ng', value: 'Ng', dial_value: '+234' },
    { text: 'Niue', flag: 'nu', value: 'NU', dial_value: '+683' },
    { text: 'Norfolk Island', flag: 'nf', value: 'NF', dial_value: '+672' },
    { text: 'Northern Mariana Islands', flag: 'mp', value: 'MP', dial_value: '+1670' },
    { text: 'Norway', flag: 'no', value: 'NO', dial_value: '+47' },
    { text: 'Oman', flag: 'om', value: 'OM', dial_value: '+968' },
    { text: 'Pakistan', flag: 'pk', value: 'PK', dial_value: '+92' },
    { text: 'Palau', flag: 'pw', value: 'PW', dial_value: '+680' },
    { text: 'Palestinian Territory, Occupied', flag: 'ps', value: 'PS', dial_value: '+970' },
    { text: 'Panama', flag: 'pa', value: 'PA', dial_value: '+507' },
    { text: 'Papua New guinea', flag: 'pg', value: 'PG', dial_value: '+675' },
    { text: 'Paraguay', flag: 'py', value: 'PY', dial_value: '+595' },
    { text: 'Peru', flag: 'pe', value: 'PE', dial_value: '+51' },
    { text: 'Philippines', flag: 'ph', value: 'PH', dial_value: '+63' },
    { text: 'Pitcairn', flag: 'pn', value: 'PN', dial_value: '+64' },
    { text: 'Poland', flag: 'pl', value: 'PL', dial_value: '+48' },
    { text: 'Portugal', flag: 'pt', value: 'PT', dial_value: '+351' },
    { text: 'Puerto Rico', flag: 'pr', value: 'PR', dial_value: '+1939' },
    { text: 'Qatar', flag: 'qa', value: 'QA', dial_value: '+974' },
    { text: 'Romania', flag: 'ro', value: 'RO', dial_value: '+40' },
    { text: 'Russia', flag: 'ru', value: 'RU', dial_value: '+7' },
    { text: 'Rwanda', flag: 'rw', value: 'RW', dial_value: '+250' },
    { text: 'Reunion', flag: 're', value: 'RE', dial_value: '+262' },
    { text: 'Saint Barthelemy', flag: 'bl', value: 'BL', dial_value: '+590' },
    {
        text: 'Saint Helena, Ascension and Tristan Da Cunha',
        flag: 'sh',
        value: 'SH',
        dial_value: '+290',
    },
    { text: 'Saint Kitts and Nevis', flag: 'kn', value: 'KN', dial_value: '+1869' },
    { text: 'Saint Lucia', flag: 'lc', value: 'LC', dial_value: '+1758' },
    { text: 'Saint Pierre and Miquelon', flag: 'pm', value: 'PM', dial_value: '+508' },
    { text: 'Saint vincent and the grenadines', flag: 'vc', value: 'VC', dial_value: '+1784' },
    { text: 'Samoa', flag: 'ws', value: 'WS', dial_value: '+685' },
    { text: 'San Marino', flag: 'sm', value: 'SM', dial_value: '+378' },
    { text: 'Sao Tome and Principe', flag: 'st', value: 'ST', dial_value: '+239' },
    { text: 'Saudi Arabia', flag: 'sa', value: 'SA', dial_value: '+966' },
    { text: 'Senegal', flag: 'sn', value: 'SN', dial_value: '+221' },
    { text: 'Serbia', flag: 'rs', value: 'RS', dial_value: '+381' },
    { text: 'Seychelles', flag: 'sc', value: 'SC', dial_value: '+248' },
    { text: 'Sierra Leone', flag: 'sl', value: 'SL', dial_value: '+232' },
    { text: 'Singapore', flag: 'sg', value: 'SG', dial_value: '+65' },
    { text: 'Slovakia', flag: 'sk', value: 'SK', dial_value: '+421' },
    { text: 'Slovenia', flag: 'si', value: 'SI', dial_value: '+386' },
    { text: 'Solomon Islands', flag: 'sb', value: 'SB', dial_value: '+677' },
    { text: 'Somalia', flag: 'so', value: 'SO', dial_value: '+252' },
    { text: 'South Africa', flag: 'za', value: 'ZA', dial_value: '+27' },
    { text: 'South Sudan', flag: 'ss', value: 'SS', dial_value: '+211' },
    {
        text: 'South georgia and the South Sandwich Islands',
        flag: 'gs',
        value: 'GS',
        dial_value: '+500',
    },
    { text: 'Spain', flag: 'es', value: 'ES', dial_value: '+34' },
    { text: 'Sri Lanka', flag: 'lk', value: 'LK', dial_value: '+94' },
    { text: 'Sudan', flag: 'sd', value: 'SD', dial_value: '+249' },
    { text: 'Suritext', flag: 'sr', value: 'SR', dial_value: '+597' },
    { text: 'Svalbard and Jan Mayen', flag: 'sj', value: 'SJ', dial_value: '+47' },
    { text: 'Swaziland', flag: 'sz', value: 'SZ', dial_value: '+268' },
    { text: 'Sweden', flag: 'se', value: 'SE', dial_value: '+46' },
    { text: 'Switzerland', flag: 'ch', value: 'CH', dial_value: '+41' },
    { text: 'Syrian Arab Republic', flag: 'sy', value: 'SY', dial_value: '+963' },
    { text: 'Taiwan', flag: 'tw', value: 'TW', dial_value: '+886' },
    { text: 'Tajikistan', flag: 'tj', value: 'TJ', dial_value: '+992' },
    { text: 'Tanzania, United Republic of Tanzania', flag: 'tz', value: 'TZ', dial_value: '+255' },
    { text: 'Thailand', flag: 'th', value: 'TH', dial_value: '+66' },
    { text: 'Timor-Leste', flag: 'tl', value: 'TL', dial_value: '+670' },
    { text: 'Togo', flag: 'tg', value: 'TG', dial_value: '+228' },
    { text: 'Tokelau', flag: 'tk', value: 'TK', dial_value: '+690' },
    { text: 'Tonga', flag: 'to', value: 'TO', dial_value: '+676' },
    { text: 'Trinidad and Tobago', flag: 'tt', value: 'TT', dial_value: '+1868' },
    { text: 'Tunisia', flag: 'tn', value: 'TN', dial_value: '+216' },
    { text: 'Turkey', flag: 'tr', value: 'TR', dial_value: '+90' },
    { text: 'Turkmenistan', flag: 'tm', value: 'TM', dial_value: '+993' },
    { text: 'Turks and Caicos Islands', flag: 'tc', value: 'TC', dial_value: '+1649' },
    { text: 'Tuvalu', flag: 'tv', value: 'TV', dial_value: '+688' },
    { text: 'Uganda', flag: 'ug', value: 'UG', dial_value: '+256' },
    { text: 'Ukraine', flag: 'ua', value: 'UA', dial_value: '+380' },
    { text: 'United Arab Emirates', flag: 'ae', value: 'AE', dial_value: '+971' },
    { text: 'United Kingdom', flag: 'gb', value: 'GB', dial_value: '+44' },
    { text: 'United States', flag: 'us', value: 'US', dial_value: '+1' },
    { text: 'Uruguay', flag: 'uy', value: 'UY', dial_value: '+598' },
    { text: 'Uzbekistan', flag: 'uz', value: 'UZ', dial_value: '+998' },
    { text: 'vanuatu', flag: 'vu', value: 'VU', dial_value: '+678' },
    {
        text: 'venezuela, Bolivarian Republic of venezuela',
        flag: 've',
        value: 'VE',
        dial_value: '+58',
    },
    { text: 'Vietnam', flag: 'vn', value: 'VN', dial_value: '+84' },
    { text: 'Virgin Islands, British', flag: 'vg', value: 'VG', dial_value: '+1284' },
    { text: 'Virgin Islands, U.S.', flag: 'vi', value: 'VI', dial_value: '+1340' },
    { text: 'Wallis and Futuna', flag: 'wf', value: 'WF', dial_value: '+681' },
    { text: 'Yemen', flag: 'ye', value: 'YE', dial_value: '+967' },
    { text: 'Zambia', flag: 'zm', value: 'ZM', dial_value: '+260' },
    { text: 'Zimbabwe', flag: 'zw', value: 'ZW', dial_value: '+263' },
];
