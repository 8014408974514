import React, { useMemo, useState } from 'react';
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useTable } from 'react-table';
import { DraggableTableRow } from '../molecules/DraggableTableRow';
import { StaticTableRow } from '../molecules/StaticTableRow';

export function DraggableDataTable({ columns, data, setData }) {
    const [activeId, setActiveId] = useState();
    const items = useMemo(() => data?.map(({ id }) => id), [data]);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
    });
    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {}),
    );

    function handleDragStart(event) {
        setActiveId(event.active.id);
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = items.indexOf(active.id);
            const newIndex = items.indexOf(over.id);
            const movedArray = arrayMove(data, oldIndex, newIndex);
            setData(movedArray);
        }

        setActiveId(null);
    }

    function handleDragCancel() {
        setActiveId(null);
    }

    const selectedRow = useMemo(() => {
        if (!activeId) {
            return null;
        }
        const row = rows.find(({ original }) => original.id === activeId);
        prepareRow(row);
        return row;
    }, [activeId, rows, prepareRow]);

    return (
        <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
        >
            <section className="h-full">
                <div className="justify-between items-end overflow-x-scroll">
                    <table {...getTableProps()} className="w-full">
                        <thead className="bg-purple-100  min-w-max">
                            {headerGroups.map((headerGroup, i) => (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, j) => (
                                        <th
                                            key={j}
                                            {...column.getHeaderProps()}
                                            className="py-4 px-3 text-left text-sm text-gray-500 font-poppins min-w-max"
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            <SortableContext items={items} strategy={verticalListSortingStrategy}>
                                {rows.map((row) => {
                                    prepareRow(row);
                                    return <DraggableTableRow key={row.original.id} row={row} />;
                                })}
                            </SortableContext>
                        </tbody>
                    </table>
                    <DragOverlay>
                        {activeId && (
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <StaticTableRow row={selectedRow} />
                                </tbody>
                            </table>
                        )}
                    </DragOverlay>
                </div>
            </section>
        </DndContext>
    );
}
