import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import NavBar from '../components/templates/NavBar';
import SideBar from '../components/templates/SideBar';
import ErrorHandling from '../service/ErrorHandling';
import { getCookie } from '../utils/cookiesService';

const ScreensWithNavAndSideBar = ({ children, navProps }) => (
    <>
        <div className="container w-screen h-screen">
            <div className="flex flex-row w-screen">
                <div className="container relative bg-black w-1/3 lg:w-1/4 xl:w-1/6">
                    <div className="fixed w-1/3 lg:w-1/4 xl:w-1/6">
                        <SideBar />
                    </div>
                </div>

                <div className=" w-2/3 lg:w-3/4 xl:w-5/6">
                    <div className="fixed w-2/3 lg:w-3/4 xl:w-5/6 z-50 bg-white">
                        <NavBar props={navProps} />
                    </div>

                    <div className="pl-6 pt-32 w-full">{children}</div>
                </div>
            </div>
        </div>
    </>
);

function ProtectedRoutesWithLayout({ navProps, component: Component, ...restOfProps }) {
    const token = getCookie('access_token');
    const [noToken, setNoToken] = useState(false);

    useEffect(() => {
        if (!token && !localStorage.getItem('Refreshed')) {
            setNoToken(true);
        }
    }, [token]);
    return (
        <Route
            exact
            {...restOfProps}
            render={(props) =>
                noToken ? (
                    <Redirect to="/" />
                ) : (
                    <ScreensWithNavAndSideBar navProps={navProps}>
                        <ErrorHandling>
                            <Component {...props} />
                        </ErrorHandling>
                    </ScreensWithNavAndSideBar>
                )
            }
        />
    );
}

export default ProtectedRoutesWithLayout;
