export enum MappedProductStatus {
    MAPPED = 'MAPPED',
    UNMAPPED = 'UNMAPPED',
    ALL = 'ALL',
}

export enum MappedItemStatus {
    PUBLISHED = 'PUBLISHED',
    UNPUBLISHED = 'UNPUBLISHED',
    DELETED = 'DELETED',
}
