/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { FETCH_PRODUCT_BY_ID } from '../../../queries/ProductQueries';
import { saveSupplierName } from '../../../redux/rootActions';
import { BsTrash } from 'react-icons/bs';
import Loader from '../../../utils/loader';
import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import { DELETE_VOUCHER } from '../../../queries/SupplierQueries';
import {
    DELETE,
    DELETE_VOUCHER_BODY,
    ERROR_MESSAGE,
    SUCCESS_MESSAGE_DELETE_VOUCHER,
} from '../../../constants/supplier';
import Toast from '../../ui/atoms/Toast';
import DataTable from '../../ui/organisms/DataTable';

export default function ViewProductVouchers() {
    const [productVouchers, setProductVouchers] = useState([]);
    const [productName, setProductName] = useState('');
    const [clickedVoucher, setClickedVoucher] = useState(null);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [warningFinishedClicked, setWarningFinishedClicked] = useState(false);
    const [warningBody, setWarningBody] = useState(null);
    const [warningTitle, setWarningTitle] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [toastError, setToastError] = useState(false);

    const parameters = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(saveSupplierName(parameters['supplierName']));
    }, [dispatch, parameters]);

    useEffect(() => {
        if (warningFinishedClicked) {
            setTimeout(() => {
                setShowWarningModal(false);
            }, 1000);
        }
    }, [warningFinishedClicked]);

    const { data: itemData, loading: itemDataLoading } = useQuery(FETCH_PRODUCT_BY_ID, {
        variables: { productId: parameters['id'] },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
        onCompleted: () => {
            setProductVouchers(itemData?.product?.productVouchers);
            setProductName(itemData?.product?.productName);
        },
    });

    const [deleteVoucher, { loading: deleteVoucherLoading }] = useMutation(DELETE_VOUCHER, {
        context: { clientName: ClientsEnum.STORE },
        refetchQueries: [FETCH_PRODUCT_BY_ID],
        onCompleted: () => {
            setShowWarningModal(false);
            setMessage(SUCCESS_MESSAGE_DELETE_VOUCHER);
            setToastError(false);
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        },
        onError: () => {
            setShowWarningModal(false);
            setMessage(ERROR_MESSAGE);
            setToastError(true);
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        },
    });

    const columns = React.useMemo(
        () => [
            {
                id: 'actionColumn',
                accessor: '',

                Cell: function delteProd({ row }) {
                    return (
                        <span
                            style={{
                                cursor: row?.original?.status === 'UNSOLD' ? 'pointer' : 'default',
                                color: row?.original?.status === 'UNSOLD' ? '#a855f7' : '#95989c',
                            }}
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                if (row?.original?.status === 'UNSOLD') {
                                    setWarningTitle(DELETE);
                                    setWarningBody(DELETE_VOUCHER_BODY);
                                    setShowWarningModal(true);
                                }
                            }}
                            onKeyDown={() => {
                                if (row?.original?.status === 'UNSOLD') {
                                    setWarningTitle(DELETE);
                                    setWarningBody(DELETE_VOUCHER_BODY);
                                    setShowWarningModal(true);
                                }
                            }}
                        >
                            {row?.original?.status === 'UNSOLD' ? (
                                <BsTrash />
                            ) : (
                                <div data-tooltip="Already deleted" data-position="top right">
                                    <BsTrash />
                                </div>
                            )}
                        </span>
                    );
                },
            },
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Code',
                accessor: 'voucherCode',
            },
            {
                Header: 'Expiry date',
                accessor: 'voucherExpiry',
            },
            {
                Header: 'Voucher pin',
                accessor: 'voucherPin',
            },
            {
                Header: 'Serial',
                accessor: 'voucherSerial',
            },
        ],
        [],
    );

    return (
        <>
            <div className="flex w-full justify-between pr-10 pb-3 mb-4">
                {showToast ? (
                    <Toast
                        setShowToast={setShowToast}
                        error={toastError}
                        message={message}
                        width="w-10/12"
                        margin="ml-1"
                    />
                ) : null}
                <WarningMessageModal
                    showWarningModal={showWarningModal}
                    setShowWarningModal={setShowWarningModal}
                    cancel={true}
                    onclick={() => {
                        deleteVoucher({
                            variables: {
                                voucherId: clickedVoucher?.id,
                            },
                        });
                    }}
                    loader={deleteVoucherLoading}
                    warningModalBody={warningBody}
                    warningModalTitle={warningTitle}
                    setProcessFinishedClicked={setWarningFinishedClicked}
                />
            </div>
            <div className="w-full border-2 border-gray-300 rounded-lg mb-10 mt-9">
                <div className="flex justify-between py-3">
                    <div className="font-poppins text-lg font-bold py-2 px-2 flex">
                        Vouchers of {productName}
                    </div>
                </div>
                {itemDataLoading ? (
                    <Loader />
                ) : productVouchers?.length ? (
                    <DataTable
                        columns={columns}
                        data={productVouchers}
                        setExposeMenuClickedItem={setClickedVoucher}
                        isProductVoucherDelete={true}
                    />
                ) : (
                    <div className="m-auto flex flex-col ">
                        <div className="py-3 px-5 bg-purple-100 justify-between flex">
                            {columns?.map((column, i) => {
                                return (
                                    <div
                                        className="text-black font-poppins font-semibold flex ml-12"
                                        key={i}
                                    >
                                        {column?.Header}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="m-auto p-10">No data found.</div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                )}
            </div>
        </>
    );
}
