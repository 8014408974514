export enum DEVICE_TYPE {
    BROWSER = 'browser',
    MOBILE = 'mobile',
    TABLET = 'tablet',
    SMART_TV = 'smarttv',
    WEARABLE = 'wearable',
    CONSOLE = 'console',
    EMBEDDED = 'embedded',
}

export enum OS_TYPE {
    WINDOWS = 'Windows',
    LINUX = 'Linux',
    ANDROID = 'Android',
    MAC_OS_X = 'Mac OS X',
    IOS = 'iOS',
    WIN_PHONE = 'Windows Phone',
}
