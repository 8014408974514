import Modal from 'react-responsive-modal';
import DataTableAlter from '../../ui/organisms/DataTableAlter';
import ConfirmationModalWithMessage from './ConfirmationModalWithMessage';
import { UPDATE_GLOBAL_CURRENCY_BY_SOURCE_AND_TARGET_CURRENCIES } from '../../../queries/GlobalCurrencyQueries';
import { useMutation } from '@apollo/client';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { useState } from 'react';
import EditableCell from '../../ui/molecules/EditableCell';
import { currencyAndCountryCodes } from '../../../utils/currencies';
import React from 'react';
import {
    CONFIRMATION_UPDATE_GLOBAL_CONVERSION_RATE_MESSAGE,
    CONFIRMATION_UPDATE_GLOBAL_CONVERSION_RATE_TITLE,
} from '../../../constants/currency';
import { FETCH_PARTNER_CURRENCY_PREFERENCES } from '../../../queries/PartnerQueries';

interface Props {
    globalCurrencyUpdateModal: boolean;
    setGlobalCurrencyUpdateModal: (value: boolean) => void;
    message?: any;
    messageTitle?: any;
    data: any;
    callAfterProcessFinished: () => void;
}

export default function GlobalCurrencyUpdateModal({
    globalCurrencyUpdateModal,
    setGlobalCurrencyUpdateModal,
    message,
    messageTitle,
    data,
    callAfterProcessFinished,
}: Props) {
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [conversionRate, setConversionRate] = useState<number>(null);

    const updateConversionRate = (id, value) => {
        setConversionRate(value);
        setShowConfirmationModal(true);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Source currency',
                accessor: 'sourceCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Target currency',
                accessor: 'targetCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Conversion rate',
                accessor: 'conversionRate',
                Cell: function editConversionRate({ row, value }) {
                    return (
                        <EditableCell
                            initialValue={value}
                            row={row}
                            updateValue={updateConversionRate}
                            key={row?.original?.id}
                            isDisabled={
                                row?.original?.sourceCurrency == row?.original?.targetCurrency
                            }
                        />
                    );
                },
            },
            {
                id: 'summary',
                accessor: '',
                Cell: function displayConversionRateSummary({ row }) {
                    return row?.original?.conversionRate
                        ? `1 ${row?.original?.sourceCurrency} = ${row?.original?.conversionRate} ${row?.original?.targetCurrency}`
                        : `Currency conversion rate between ${row?.original?.sourceCurrency} and ${row?.original?.targetCurrency} is not set`;
                },
            },
        ],
        [],
    );

    const [
        updateGlobalCurrencyBySourceAndTargetCurrencies,
        { loading: updateGlobalCurrencyLoading },
    ] = useMutation(UPDATE_GLOBAL_CURRENCY_BY_SOURCE_AND_TARGET_CURRENCIES, {
        refetchQueries: [FETCH_PARTNER_CURRENCY_PREFERENCES],
        context: { clientName: ClientsEnum.UTIL },
        onCompleted() {
            if (callAfterProcessFinished) {
                callAfterProcessFinished();
            }
            setShowConfirmationModal(false);
        },
    });
    const handleUpdateCurrencyConversionRate = () => {
        updateGlobalCurrencyBySourceAndTargetCurrencies({
            variables: {
                globalCurrencyConversionInput: {
                    sourceCurrency: data?.sourceCurrency,
                    targetCurrency: data?.targetCurrency,
                    conversionRate: conversionRate,
                },
            },
        });
    };
    return (
        <div>
            <Modal
                open={globalCurrencyUpdateModal}
                onClose={() => {
                    setGlobalCurrencyUpdateModal(false);
                }}
                center={true}
                styles={{
                    modal: { borderRadius: 15, maxWidth: '1028px', width: '100%' },
                }}
                focusTrapped={false}
                closeOnOverlayClick={false}
            >
                <div className="flex justify-center ">
                    <h3 className="text-center font-bold font-poppins p-4">{messageTitle || ''}</h3>
                </div>

                <div className="flex flex-col text-center font-poppins justify-center border-t-2">
                    <div
                        data-testid="message"
                        className="px-12 py-4 font-poppins whitespace-pre-wrap"
                    >
                        {message || ''}
                    </div>
                </div>
                <div className="w-full">
                    <div className="m-auto flex flex-col">
                        <div className="rounded-md">
                            <DataTableAlter
                                columns={columns}
                                data={[data]}
                                hidePagination={true}
                                setPageIndex={0}
                                pageCount={1}
                                pageSizes={5}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex space-x-2 px-4 py-4 justify-end">
                    <button
                        className="bg-transparent  text-purple-500 rounded-md px-12 py-3 font-poppins text-center border-2 border-purple-500"
                        onClick={() => {
                            setGlobalCurrencyUpdateModal(false);
                        }}
                        onBlur={() => {
                            setGlobalCurrencyUpdateModal(false);
                        }}
                    >
                        Close
                    </button>
                </div>
                <ConfirmationModalWithMessage
                    showConfirmationModal={showConfirmationModal}
                    setShowConfirmationModal={setShowConfirmationModal}
                    message={CONFIRMATION_UPDATE_GLOBAL_CONVERSION_RATE_MESSAGE}
                    messageTitle={CONFIRMATION_UPDATE_GLOBAL_CONVERSION_RATE_TITLE}
                    setYesBtnClick={() => handleUpdateCurrencyConversionRate()}
                    setNoBtnClick={() => {
                        setShowConfirmationModal(false);
                    }}
                    isProcessing={updateGlobalCurrencyLoading}
                />
            </Modal>
        </div>
    );
}
