/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { saveSupplierName } from '../../../redux/rootActions';
import { BsTrash } from 'react-icons/bs';
import Loader from '../../../utils/loader';
import DataTable from '../../ui/organisms/DataTable';
import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import { DELETE_PRODUCT } from '../../../queries/SupplierQueries';
import {
    ALLSERV,
    API,
    CSV,
    CSV_VOUCHERS,
    DELETE,
    DELETE_PRODUCT_BODY,
    ERROR_MESSAGE,
    SUCCESS_MESSAGE_DELETE_PRODUCT,
    VOUCHER_STATUS_AVAILABLE,
} from '../../../constants/supplier';
import Toast from '../../ui/atoms/Toast';
import { FETCH_SUPPLIER_PRODUCTS_BY_ID } from '../../../queries/ItemQueries';
import { SORT_ASC } from '../../../constants/common';

export default function ViewFileProducts() {
    const [productsOfFile, setProductsOfFile] = useState([]);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [warningFinishedClicked, setWarningFinishedClicked] = useState(false);
    const [warningBody, setWarningBody] = useState(null);
    const [warningTitle, setWarningTitle] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [toastError, setToastError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [clickedProduct, setClickedProduct] = useState(null);

    const parameters = useParams();
    const dispatch = useDispatch();

    const [loadProductsByIDFile, { loading: fileDataLoading }] = useLazyQuery(
        FETCH_SUPPLIER_PRODUCTS_BY_ID,
        {
            context: { clientName: ClientsEnum.STORE },

            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
            onCompleted: (data) => {
                setProductsOfFile(data?.productsBySupplier?.products);
            },
        },
    );

    const [deleteProduct, { loading: deleteProductLoading }] = useMutation(DELETE_PRODUCT, {
        context: { clientName: ClientsEnum.STORE },
        refetchQueries: [FETCH_SUPPLIER_PRODUCTS_BY_ID],
        onCompleted: () => {
            setShowWarningModal(false);
            setShowWarningModal(false);
            setMessage(SUCCESS_MESSAGE_DELETE_PRODUCT);
            setToastError(false);
            setShowToast(true);
            setToastError(false);
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        },
        onError: () => {
            setShowWarningModal(false);
            setMessage(ERROR_MESSAGE);
            setToastError(true);
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        },
    });

    useEffect(() => {
        dispatch(saveSupplierName(parameters['supplierName']));
    }, [dispatch, parameters]);

    useEffect(() => {
        if (warningFinishedClicked) {
            setTimeout(() => {
                setShowWarningModal(false);
            }, 1000);
        }
    }, [warningFinishedClicked]);

    useEffect(() => {
        loadProductsByIDFile({
            variables: {
                filter: {
                    productStatus: 'PUBLISHED',
                    status: 'ALL',
                    csvFileId: parameters['fileId'],
                },
                offset: currentPage - 1,
                limit: 50,
                order: SORT_ASC,
                supplierId: parameters['supplierId'],
                searchText: null,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = React.useMemo(
        () => [
            {
                id: 'actionColumn',
                accessor: '',

                Cell: function delteProd({ row }) {
                    return (
                        <span
                            id={`${row?.original?.productName}-delete-button`}
                            style={{
                                cursor: 'pointer',
                                color: '#a855f7',
                            }}
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                setWarningTitle(DELETE);
                                setWarningBody(DELETE_PRODUCT_BODY);
                                setShowWarningModal(true);
                            }}
                            onKeyDown={() => {
                                setWarningTitle(DELETE);
                                setWarningBody(DELETE_PRODUCT_BODY);
                                setShowWarningModal(true);
                            }}
                        >
                            <BsTrash />
                        </span>
                    );
                },
            },
            {
                Header: 'Product name',
                accessor: 'productName',
            },
            {
                Header: 'Product Origin',
                id: 'orderStrategy',
                accessor: (product) => {
                    return product?.orderStrategy === CSV_VOUCHERS ? CSV : API;
                },
            },
            {
                Header: 'Product Type',
                accessor: 'productType',
            },
            {
                Header: 'Manufacturer',
                accessor: 'manufacturerName',
                isViewProductScreen: true,
            },
            {
                Header: 'SKU',
                accessor: 'productVvssku',
                isViewProductScreen: true,
            },
            {
                Header: 'Available stocks',
                accessor: 'availableStocks',
                Cell: function availableVoucherInventories({ row }) {
                    return row?.original?.orderStrategy === CSV_VOUCHERS ? (
                        <span id={`${row?.original?.productName}-${row?.original?.availableStock}`}>
                            {row?.original?.availableStock}
                        </span>
                    ) : (
                        <span
                            id={`${row?.original?.productName}-${row?.original?.availableStock}`}
                        >{`-`}</span>
                    );
                },
            },
            {
                Header: 'Total stocks',
                accessor: 'totalStocks',
                Cell: function voucherInventories({ row }) {
                    return row?.original?.orderStrategy === CSV_VOUCHERS ? (
                        <span id={`${row?.original?.productName}-${row?.original?.totalStock}`}>
                            {row?.original?.totalStock}
                        </span>
                    ) : (
                        <span
                            id={`${row?.original?.productName}-${row?.original?.totalStock}`}
                        >{`-`}</span>
                    );
                },
            },
            {
                Header: 'Price',
                accessor: '',
                Cell: function voucherInventories({ row }) {
                    const voucherInventories = row?.original?.voucherInventories?.filter(
                        (voucher) => {
                            return voucher?.status === VOUCHER_STATUS_AVAILABLE;
                        },
                    );
                    return (
                        <span
                            id={`${row?.original?.productName}-${
                                voucherInventories?.length ? voucherInventories[0]['unitPrice'] : 0
                            }`}
                        >
                            {voucherInventories?.length ? voucherInventories[0]['unitPrice'] : 0}
                        </span>
                    );
                },
            },
            {
                Header: 'Currency',
                accessor: 'currency',
                idDisabled: true,
            },
        ],
        [],
    );

    const allServColumns = React.useMemo(
        () => [
            {
                id: 'actionColumn',
                accessor: '',
                Cell: function deleteProduct({ row }) {
                    return (
                        <span
                            id={`${row?.original?.productName}-delete-button`}
                            style={{
                                cursor: 'pointer',
                                color: '#a855f7',
                            }}
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                setWarningTitle(DELETE);
                                setWarningBody(DELETE_PRODUCT_BODY);
                                setShowWarningModal(true);
                            }}
                            onKeyDown={() => {
                                setWarningTitle(DELETE);
                                setWarningBody(DELETE_PRODUCT_BODY);
                                setShowWarningModal(true);
                            }}
                        >
                            <BsTrash />
                        </span>
                    );
                },
            },
            {
                Header: 'Product name',
                accessor: 'productName',
            },
            {
                Header: 'Product Origin',
                id: 'orderStrategy',
                accessor: (product) => {
                    if (product?.orderStrategy === CSV_VOUCHERS) {
                        return CSV;
                    } else if (product?.orderStrategy.startsWith('ALLSERV')) {
                        return ALLSERV;
                    } else {
                        return API;
                    }
                },
            },
            {
                Header: 'Product Type',
                accessor: 'productType',
            },
            {
                Header: 'Manufacturer',
                accessor: 'manufacturerName',
                isViewProductScreen: true,
            },
            {
                Header: 'Price',
                accessor: 'unitPrice',
                Cell: function costPriceCell({ row }) {
                    return (
                        <div
                            className="text-left"
                            id={`${row?.original?.productName}-${row?.original?.unitPrice}`}
                        >
                            {row?.original?.unitPrice}
                        </div>
                    );
                },
            },
            {
                Header: 'Currency',
                accessor: 'currency',
                idDisabled: true,
            },
        ],
        [],
    );

    return (
        <>
            <div className="flex w-full justify-between pr-10 pb-3 mb-4">
                {showToast ? (
                    <Toast
                        setShowToast={setShowToast}
                        error={toastError}
                        message={message}
                        width="w-10/12"
                        margin="ml-1"
                    />
                ) : null}
                <WarningMessageModal
                    showWarningModal={showWarningModal}
                    setShowWarningModal={setShowWarningModal}
                    cancel={true}
                    onclick={() => {
                        deleteProduct({
                            variables: {
                                productId: clickedProduct?.id,
                            },
                        });
                    }}
                    loader={deleteProductLoading}
                    warningModalBody={warningBody}
                    warningModalTitle={warningTitle}
                    setProcessFinishedClicked={setWarningFinishedClicked}
                />
            </div>
            <div className="w-full border-2 border-gray-300 rounded-lg mb-10 mt-9">
                <div className="flex justify-between py-3">
                    <div className="font-poppins text-lg font-bold py-2 px-2 flex">
                        Products of CSV file
                    </div>
                </div>
                {fileDataLoading ? (
                    <Loader />
                ) : productsOfFile?.length ? (
                    <DataTable
                        columns={
                            productsOfFile && productsOfFile[0].orderStrategy?.startsWith('ALLSERV')
                                ? allServColumns
                                : columns
                        }
                        data={productsOfFile}
                        setExposeMenuClickedItem={setClickedProduct}
                        isProductVoucherDelete={true}
                        setCurrentPage={setCurrentPage}
                    />
                ) : (
                    <div className="m-auto flex flex-col ">
                        <div className="py-3 px-5 bg-purple-100 justify-between flex">
                            {columns?.map((column, i) => {
                                return (
                                    <div
                                        className="text-black font-poppins font-semibold flex ml-12"
                                        key={i}
                                    >
                                        {column?.Header}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="m-auto p-10">No data found.</div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                )}
            </div>
        </>
    );
}
