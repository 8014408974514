import {
    SAVE_POINTS_DATA_SUCCESS,
    SAVE_POINTS_DATA_FAILED,
    UPDATE_ITEM_PARTNER_CURRENCY_CONVERSION_SUCCESS_MESSAGE,
    UPDATE_ITEM_PARTNER_CURRENCY_CONVERSION_SUCCESS,
    CURRENCY_CONVERSION_RESET_SUCCESS_MESSAGE,
    CURRENCY_CONVERSION_RESET_SUCCESS,
    CURRENCY_MARKED_UP_PERCENTAGE_RESET_SUCCESS_MESSAGE,
    CURRENCY_MARKED_UP_PERCENTAGE_RESET_SUCCESS,
    POINTS_MARKED_UP_PERCENTAGE_RESET_SUCCESS_MESSAGE,
    POINTS_MARKED_UP_PERCENTAGE_RESET_SUCCESS,
    PSP_MARKED_UP_PERCENTAGE_RESET_SUCCESS_MESSAGE,
    PSP_MARKED_UP_PERCENTAGE_RESET_SUCCESS,
    UPDATE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE_SUCCESS_MESSAGE,
    UPDATE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE_SUCCESS,
} from './../../enums/redux/redux-enum';
import { getErrorMessage } from '../../utils/axiosErrorHandler';

export const setPointsUpdatedSuccess = (response) => async (dispatch: any) => {
    try {
        dispatch({ type: SAVE_POINTS_DATA_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: SAVE_POINTS_DATA_SUCCESS, payload: getErrorMessage(error) });
    }
};

export const setPointsUpdateFailed = (response) => async (dispatch: any) => {
    dispatch({ type: SAVE_POINTS_DATA_FAILED, payload: response });
};

export const setCurrencyConversionOverrideSuccessMessage = (response) => async (dispatch: any) => {
    dispatch({ type: UPDATE_ITEM_PARTNER_CURRENCY_CONVERSION_SUCCESS_MESSAGE, payload: response });
};

export const setCurrencyConversionOverrideSuccess = (response) => async (dispatch: any) => {
    dispatch({ type: UPDATE_ITEM_PARTNER_CURRENCY_CONVERSION_SUCCESS, payload: response });
};

export const setCurrencyConversionResetMessage = (response) => async (dispatch: any) => {
    dispatch({ type: CURRENCY_CONVERSION_RESET_SUCCESS_MESSAGE, payload: response });
};

export const setCurrencyConversionResetSuccess = (response) => async (dispatch: any) => {
    dispatch({ type: CURRENCY_CONVERSION_RESET_SUCCESS, payload: response });
};

export const setCurrencyMarkedUpPercentageResetMessage = (response) => async (dispatch: any) => {
    dispatch({ type: CURRENCY_MARKED_UP_PERCENTAGE_RESET_SUCCESS_MESSAGE, payload: response });
};

export const setCurrencyMarkedUpPercentageResetSuccess = (response) => async (dispatch: any) => {
    dispatch({ type: CURRENCY_MARKED_UP_PERCENTAGE_RESET_SUCCESS, payload: response });
};

export const setPointsMarkedUpPercentageResetMessage = (response) => async (dispatch: any) => {
    dispatch({ type: POINTS_MARKED_UP_PERCENTAGE_RESET_SUCCESS_MESSAGE, payload: response });
};

export const setPointsMarkedUpPercentageResetSuccess = (response) => async (dispatch: any) => {
    dispatch({ type: POINTS_MARKED_UP_PERCENTAGE_RESET_SUCCESS, payload: response });
};

export const setPspMarkedUpPercentageResetMessage = (response) => async (dispatch: any) => {
    dispatch({ type: PSP_MARKED_UP_PERCENTAGE_RESET_SUCCESS_MESSAGE, payload: response });
};

export const setPspMarkedUpPercentageResetSuccess = (response) => async (dispatch: any) => {
    dispatch({ type: PSP_MARKED_UP_PERCENTAGE_RESET_SUCCESS, payload: response });
};

export const setPspMarkedUpPercentageOverrideSuccessMessage =
    (response) => async (dispatch: any) => {
        dispatch({
            type: UPDATE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE_SUCCESS_MESSAGE,
            payload: response,
        });
    };

export const setPspMarkedUpPercentageOverrideSuccess = (response) => async (dispatch: any) => {
    dispatch({ type: UPDATE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE_SUCCESS, payload: response });
};
