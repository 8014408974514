/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { useHistory } from 'react-router';
import { Buttons } from '../../ui/atoms/Button';

interface Props {
    isModalOpen: boolean;
    setModalOpen: (value: boolean) => void;
}

export default function NoPartnerModal({ isModalOpen, setModalOpen }: Props): ReactElement {
    const history = useHistory();

    return (
        <Modal
            open={isModalOpen}
            onClose={() => {
                setModalOpen(false);
            }}
            center={true}
            styles={{ modal: { borderRadius: 8, maxWidth: '706px', width: '80%' } }}
            focusTrapped={false}
        >
            <>
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-poppins  font-semibold flex m-auto">
                        Add new product
                    </h3>
                </div>

                <div className="relative px-4 py-8  flex flex-col">
                    <div className="text-gray-400 text-lg flex mb-3 m-auto">
                        No partners are available to create a new product. Please create a partner
                        first.
                    </div>
                </div>

                <div className="flex flex-row space-x-3 mt-6 justify-end m-4">
                    <Buttons
                        name="Create new partner"
                        type="submit"
                        buttonType="primary"
                        id="okay"
                        size="small"
                        onclick={() => {
                            setModalOpen(false);
                            history.push('/partner/general-details-add');
                        }}
                    />
                    <Buttons
                        name="Close"
                        type="submit"
                        buttonType="secondary-border-black"
                        id="okay"
                        size="small"
                        onclick={() => {
                            setModalOpen(false);
                        }}
                    />
                </div>
            </>
        </Modal>
    );
}
