import { useCallback, useEffect, useState } from 'react';
import '../../../styles/dropdown.css';
import * as Yup from 'yup';
import { Dropdown } from 'semantic-ui-react';

import {
    FETCH_ADMIN_ONE_PROFILE,
    GET_LOGIN_SESSIONS_DATA,
    SAVE_ACTIVITY_LOGS,
    SEND_VERIFICATION_EMAIL,
    UPDATE_ADMIN_PROFILE,
    UPDATE_USER_LOGIN_DATA,
} from '../../../queries/AdminProfileQueries';
import { useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { useHistory } from 'react-router';
import { countryOptions } from '../../../utils/countries';
import { useDispatch, useSelector } from 'react-redux';
import {
    getActiveSessions,
    getUserDetails,
    logout,
    logoutFromAllSessions,
    uploadProfilePic,
} from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import jwt from 'jwt-decode';
import { getCookie } from '../../../utils/cookiesService';
import Loader from '../../../utils/loader';
import ActivityLogTable from './ActivityLogTable';
import ChangeEmailModal from '../../templates/modals/ChangeEmailModal';
import ChangePasswordModel from '../../templates/modals/ChangePasswordModel';
import UploadImage from '../../templates/modals/UploadProPic';
import ValidateOldPassword from '../../templates/modals/ValidateOldPasswordModal';
import VerifyEmailAddressRequestModal from '../../templates/modals/VerifyEmailAddressRequestModal';
import { Buttons } from '../../ui/atoms/Button';
import { Images } from '../../ui/atoms/Images';
import { InputField } from '../../ui/atoms/InputField';
import { LoginSession } from '../../ui/organisms/LoginSession';
import CustomizedSwitches from '../../ui/atoms/ToggleSwitch';
import Toast from '../../ui/atoms/Toast';
import {
    ADMIN_PROFILE_UNSAVED_CHANGES_TITLE,
    ADMIN_PROFILE_UPDATE_SUCCESS,
    REGISTERED,
} from '../../../constants/profile';
import UnsaveChangesWarning from '../../templates/modals/UnsaveChangesWarning';
import {
    SESSION_STATUS_ACTIVE,
    SESSION_STATUS_INACTIVE,
} from '../../../constants/userLoginSession';
import { SWAR_MVP_HIDE_FEATURE } from '../../../constants/config/constants';
import { USER_CHANGE_PASSWORD_SUCCESS_MESSAGE } from '../../../constants/users';
import { PRODUCT_ADD_EXIT_CONFIRM_MESSAGE } from '../../../constants/product';
import '../../../styles/checkbox.css';

export default function AdminProfileScreens() {
    const history = useHistory();
    const dispatch = useDispatch();

    const [edit, setEdit] = useState(false);
    const [country, setCountry] = useState('');
    const [loginDrop, setLoginDrop] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [activityLog, setActivityLog] = useState(false);
    const [verificationNotification, setVerificationNotification] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [userProfileImage, setUserProfileImage] = useState<File>(null);
    const [imageFileUrl, setImageFileUrl] = useState(null);
    const [userUpdateMutationCompleted, setUserUpdateMutationCompleted] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [loginSessionResult, setLoginSessionResult] = useState(null);
    const [logoutFromSession, setLogoutFromSession] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);
    const [passwordUpdateSuccess, setPasswordUpdateSuccess] = useState(false);
    const [selectedCode, setSelectedCode] = useState({});
    const [countryError, setCountryError] = useState(false);
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const loggedInUser = localStorage.getItem('current_user_email');
    const token = getCookie('access_token');
    const isFirstLogin = localStorage.getItem('is_first_login');
    const tokenDetails: any = token ? jwt(token) : null;
    const [isOutOfStockEmailEnable, setIsOutOfStockEmailEnable] = useState<boolean>(false);
    const [isRunningOutOfStockEmailEnable, setIsRunningOutOfStockEmailEnable] =
        useState<boolean>(false);

    const { data, loading } = useQuery(FETCH_ADMIN_ONE_PROFILE, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        variables: { userName: tokenDetails?.preferred_username },
        skip: !token,
    });

    const [updateUser, { loading: queryLoading }] = useMutation(UPDATE_ADMIN_PROFILE, {
        fetchPolicy: 'network-only',
        refetchQueries: [FETCH_ADMIN_ONE_PROFILE],
        onCompleted() {
            setError(false);
            setMessage(ADMIN_PROFILE_UPDATE_SUCCESS);
            setShowToast(true);
            setUserUpdateMutationCompleted(true);
            localStorage?.setItem('isRegistered', REGISTERED);
        },
        onError(error) {
            setError(true);
            setMessage(error.message);
            setShowToast(true);
            setIsOutOfStockEmailEnable(!isOutOfStockEmailEnable);
            setIsRunningOutOfStockEmailEnable(!isRunningOutOfStockEmailEnable);
        },
    });

    const [sendEmailVerification, { error: emailError }] = useMutation(SEND_VERIFICATION_EMAIL, {
        refetchQueries: [FETCH_ADMIN_ONE_PROFILE],
    });

    const [saveActivityLog] = useMutation(SAVE_ACTIVITY_LOGS, {
        onCompleted() {
            window.location.reload();
        },
    });

    const { data: swarUserLoginSessionsData } = useQuery(GET_LOGIN_SESSIONS_DATA, {
        fetchPolicy: 'network-only',
        variables: {
            userName: tokenDetails?.preferred_username,
            status: SESSION_STATUS_ACTIVE,
        },
        skip: !token,
    });

    const clickedUser = localStorage.getItem('current_user_email');

    const currentUser = data?.user;
    const [showModal, setShowModal] = useState(false);
    const [showValidatePasswordModal, setShowValidatePasswordModal] = useState(false);
    const loginSessions = useSelector((state: RootState) => state.auth.sessions);
    const profilePicURL = useSelector((state: RootState) => state.auth.profilePicUrl);
    const isLogOutClicked = useSelector(
        (state: RootState) => state.auth.isAdminProfileLogoutClicked,
    );
    const isLogoutFromAllSessions = useSelector((state: RootState) => state.auth.logOutAllSessions);
    const isRegistered = localStorage.getItem('isRegistered');
    useEffect(() => {
        if (isFirstLogin === 'true') {
            setEdit(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isLogOutClicked === true) {
            setIsDirty(false);
            dispatch(logout());
            history.push('/');
        }
    }, [dispatch, history, isLogOutClicked]);

    useEffect(() => {
        if (passwordUpdateSuccess) {
            setError(false);
            setMessage(USER_CHANGE_PASSWORD_SUCCESS_MESSAGE);
            setShowToast(true);

            setTimeout(() => {
                dispatch(logoutFromAllSessions(tokenDetails?.sub));
                dispatch(logout());
            }, 3000);
        }
    }, [dispatch, history, passwordUpdateSuccess, tokenDetails?.sub]);

    useEffect(() => {
        if (isLogoutFromAllSessions) {
            setPasswordUpdateSuccess(false);
            history.push('/');
        }
    }, [history, isLogoutFromAllSessions]);

    useEffect(() => {
        dispatch(getUserDetails(loggedInUser));
        dispatch(getActiveSessions(loggedInUser));
    }, [dispatch, loggedInUser]);

    useEffect(() => {
        if (!currentUser?.contact?.primaryEmailVerified) {
            setVerificationNotification(true);
        } else {
            setVerificationNotification(false);
        }
        setIsOutOfStockEmailEnable(currentUser?.isOutOfStockEmailEnable);
        setIsRunningOutOfStockEmailEnable(currentUser?.isRunningOutOfStockEmailEnable);
    }, [setVerificationNotification, currentUser]);

    const handleLoginSessionData = () => {
        const loginSessionMergedResult = [];
        loginSessions?.forEach((keyCloakSession) => {
            const matchedSession = swarUserLoginSessionsData?.userLoginSessions?.find(
                (loginSession) => loginSession.sessionId === keyCloakSession.id,
            );

            if (matchedSession) {
                loginSessionMergedResult.push({
                    ip: matchedSession?.ipAddress,
                    country: matchedSession?.location,
                    created: keyCloakSession?.start,
                    devices: matchedSession?.device,
                    deviceType: matchedSession?.deviceType,
                    id: keyCloakSession?.id,
                    isCurrent: tokenDetails?.sid === keyCloakSession?.id ? true : false,
                });
            }
        });

        return loginSessionMergedResult;
    };

    useEffect(() => {
        if (swarUserLoginSessionsData && loginSessions) {
            const loginSessionResult = handleLoginSessionData();
            setLoginSessionResult(loginSessionResult);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [swarUserLoginSessionsData, loginSessions, setLoginSessionResult]);

    const [updateUserLoginData] = useMutation(UPDATE_USER_LOGIN_DATA);

    useEffect(() => {
        if (logoutFromSession && selectedSession) {
            updateUserLoginData({
                variables: {
                    userName: currentUser?.userName,
                    userLogin: {
                        sessionId: selectedSession.id,
                        device: selectedSession.devices,
                        deviceType: selectedSession.deviceType,
                        location: selectedSession.country,
                        status: SESSION_STATUS_INACTIVE,
                    },
                },
            });
        }
    }, [currentUser?.userName, logoutFromSession, selectedSession, updateUserLoginData]);

    const handleRemoveProfilePicture = useCallback(() => {
        updateUser({
            variables: {
                userName: tokenDetails?.preferred_username,
                user: {
                    firstName: currentUser?.firstName,
                    lastName: currentUser?.lastName,
                    profilePic: '',
                    contact: {
                        primaryEmail: loggedInUser,
                        recoveryEmail: currentUser?.contact?.recoveryEmail,
                        countryCode: currentUser?.contact?.countryCode,
                        phoneNumber: currentUser?.contact?.phoneNumber,
                    },
                    isOutOfStockEmailEnable: isOutOfStockEmailEnable,
                    isRunningOutOfStockEmailEnable: isRunningOutOfStockEmailEnable,
                },
            },
        });
        dispatch(uploadProfilePic(''));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tokenDetails, loggedInUser]);

    const handleSaveActivityLog = useCallback(() => {
        if (isFirstLogin === 'true' && userUpdateMutationCompleted) {
            saveActivityLog({
                variables: {
                    userName: tokenDetails?.preferred_username,
                    activity: {
                        activityName: 'login',
                        action: 'FIRST_LOGIN',
                    },
                },
            });
            localStorage.setItem('is_first_login', 'false');
        }
    }, [
        isFirstLogin,
        tokenDetails?.preferred_username,
        saveActivityLog,
        userUpdateMutationCompleted,
    ]);

    useEffect(() => {
        handleSaveActivityLog();
    }, [handleSaveActivityLog, isFirstLogin, userUpdateMutationCompleted]);

    const handleOutOfStockCheckboxChange = (event) => {
        setIsOutOfStockEmailEnable(event.target.checked);
    };

    const handleImageInput = useCallback(
        (image: any) => {
            return (
                <>
                    {image && (
                        <>
                            <div
                                role="presentation"
                                className="font-light text-red-400 text-sm underline cursor-pointer whitespace-nowrap mr-3"
                                onClick={() => handleRemoveProfilePicture()}
                                id="remove_profile_picture"
                            >
                                Remove profile picture
                            </div>
                        </>
                    )}
                    <label
                        className="cursor-pointer underline whitespace-nowrap"
                        htmlFor={edit ? 'profileImage' : null}
                    >
                        <div className="font-light text-purple-500 text-sm">
                            Upload a profile picture
                        </div>
                    </label>
                    <input
                        className={`invisible focus:outline-none w-full pb-3 border-b-2 pl-2 focus:border-blue-900`}
                        id="profileImage"
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                            setUserProfileImage(e.target.files[0]);
                            e.target.value = null;
                        }}
                    />
                </>
            );
        },
        [edit, handleRemoveProfilePicture],
    );

    const code = countryOptions?.find((country: any) => {
        return country?.value === currentUser?.contact?.countryCode;
    });

    useEffect(() => {
        if (!selectedCode[0]?.value && !currentUser?.contact?.countryCode && isUpdate)
            setCountryError(true);
        else {
            setCountryError(false);
        }
    }, [selectedCode, isUpdate, currentUser?.contact?.countryCode]);

    useEffect(() => {
        handleImageInput(imageFileUrl);
    }, [handleImageInput, imageFileUrl]);

    useEffect(() => {
        if (country) {
            const selectedCodes = countryOptions?.filter((countryOption: any) => {
                return countryOption?.text === country;
            });
            setSelectedCode(selectedCodes);
        }
    }, [country]);

    if (queryLoading) return <Loader />;

    if (loading) return <Loader />;

    const handleEdit = () => {
        setEdit(true);
    };

    let currentUserTokenDetails;

    const loginResponse = getCookie('access_token');

    if (loginResponse) {
        currentUserTokenDetails = jwt(loginResponse);
    }

    const clientID = process.env.REACT_APP_CLIENT_ID;
    const currentUserRoles = currentUserTokenDetails?.resource_access[clientID || 'swarmio-user'];

    const handleInviteUser = () => {
        return history.push('/inviteUserModal');
    };

    const VerifyEmailReminder = (email) => {
        return (
            <>
                <div className="p-2 bg-yellow-50 flex flex-col my-5" role="alert">
                    <div className="font-semibold font-poppins text-yellow-300 mb-3 flex">
                        <img className="w-5 h-5 mr-2" src="/images/icons/infoVerify.png" alt="" />

                        <div className="mr-1">Verify your admin email address</div>
                    </div>
                    <div className="font-semibold flex mx-7 mb-3">
                        <div className=" text-gray-500 mr-2">
                            There is a pending change of the admin email to
                        </div>
                        <div className=" text-gray-500 mr-1">{email}</div>
                        <div className=" text-gray-400 mr-1">
                            Click on the link in your email inbox to complete verification.
                        </div>
                    </div>
                    <div className="font-semibold flex mx-7 mb-3">
                        <div className="text-gray-500 mr-3">Cannot find the email?</div>
                        <div
                            className=" text-purple-400 mr-1 underline"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                sendEmailVerification({
                                    variables: { email: currentUser?.contact?.primaryEmail },
                                });
                                if (!emailError) {
                                    setVerificationNotification(false);
                                }
                            }}
                            onKeyDown={() => {
                                sendEmailVerification({
                                    variables: { email: currentUser?.contact?.primaryEmail },
                                });
                            }}
                        >
                            Resend
                        </div>
                    </div>
                    <div
                        className=" text-purple-400 mr-1 underline mx-7"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            setVerificationNotification(false);
                        }}
                        onKeyDown={() => {
                            setVerificationNotification(false);
                        }}
                    >
                        Cancel
                    </div>
                </div>
            </>
        );
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().trim().required('First name is required'),
        lastName: Yup.string().trim().required('Last name is required'),
        phoneNumber: Yup.string()
            .required('Phone number cannot be blank')
            .min(7, 'The number of digits cannot be less than 7')
            .matches(/^[0-9]+$/, 'Enter a valid phone number')
            .max(15, 'The number of digits cannot exceed 15'),
    });
    return (
        <>
            {showToast && (
                <Toast
                    setShowToast={setShowToast}
                    message={message}
                    error={error}
                    width="w-10/12"
                    margin="-mt-14 ml-10"
                    selfDestruct={true}
                    selfDestructTimer={5000}
                />
            )}
            <Formik
                initialValues={{
                    firstName: currentUser?.firstName || '',
                    lastName: currentUser?.lastName || '',
                    profilePic: currentUser?.profilePic || '',
                    countryCode: currentUser?.contact?.countryCode || '',
                    primaryEmail: currentUser?.contact?.primaryEmail || '',
                    recoveryEmail: currentUser?.recoveryEmail || '',
                    phoneNumber: currentUser?.contact?.phoneNumber || '',
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={({ firstName, lastName, phoneNumber, profilePic }) => {
                    setIsDirty(false);
                    if (isUpdate && edit && !countryError) {
                        updateUser({
                            variables: {
                                userName: tokenDetails?.preferred_username,
                                user: {
                                    firstName: firstName,
                                    lastName: lastName,
                                    profilePic: profilePicURL ? profilePicURL : profilePic,
                                    contact: {
                                        primaryEmail: loggedInUser,
                                        recoveryEmail: currentUser?.contact?.recoveryEmail,
                                        countryCode: selectedCode[0]?.value
                                            ? selectedCode[0]?.value
                                            : currentUser?.contact?.countryCode,

                                        phoneNumber: phoneNumber,
                                    },
                                    isOutOfStockEmailEnable: isOutOfStockEmailEnable,
                                    isRunningOutOfStockEmailEnable: isRunningOutOfStockEmailEnable,
                                },
                            },
                        });
                        setUserProfileImage(null);
                        setEdit(false);
                    }
                }}
            >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                    <>
                        <div className="flex flex-row">
                            <UploadImage
                                isEdit={edit}
                                imageFile={userProfileImage}
                                setImageFile={setImageFileUrl}
                            />

                            <div className="mx-10 my-8">
                                <div className="font-bold font-poppins text-2xl mb-1">
                                    {`${currentUser?.firstName || 'Please update your details'} ${
                                        currentUser?.lastName || ''
                                    }`}
                                </div>

                                <div className=" font-poppins text-gray-400 text-sm flex">
                                    <p className="capitalize">{currentUserRoles?.roles?.[0]}</p>
                                </div>

                                <div
                                    className={`text-sm underline text-purple-500 ${
                                        edit ? 'hidden' : 'block'
                                    } `}
                                >
                                    <div
                                        onClick={handleEdit}
                                        id="editprofile_link"
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={handleEdit}
                                    >
                                        Edit Profile
                                    </div>
                                </div>
                                <div
                                    id="upload_profile_picture"
                                    className={`text-sm underline text-purple-500 ${
                                        edit ? 'flex' : 'hidden'
                                    }`}
                                >
                                    {handleImageInput(currentUser?.profilePic)}
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-8 gap-4 mt-16">
                            <div className="col-start-1 col-end-4">
                                <InputField
                                    readonly={!edit ? true : false}
                                    id="firstName"
                                    placeHolder="Lucy"
                                    name="First name"
                                    labelWidth="w-21"
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                    }}
                                    value={values.firstName}
                                />
                                {errors.firstName && touched.firstName ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.firstName}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-end-7 col-span-3">
                                <InputField
                                    readonly={!edit ? true : false}
                                    id="lastName"
                                    placeHolder="Walker"
                                    name=" Last name"
                                    labelWidth="w-21"
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                    }}
                                    value={values.lastName}
                                />
                                {errors.lastName && touched.lastName ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.lastName}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-start-1 col-end-4 mt-2.5">
                                <div className="flex flex-wrap flex-col relative z-30">
                                    <div className="text-gray-500 w-28 text-center font-poppins text-sm lg:text-sm h-max bg-white z-30 -mb-3 ml-4 border-opacity-20">
                                        Select country
                                    </div>

                                    <Dropdown
                                        className="dropdownProfile"
                                        placeholder="Select country"
                                        id="countryCode"
                                        name="countryCode"
                                        disabled={edit ? false : true}
                                        fluid
                                        key={'countryCode'}
                                        wrapSelection
                                        selection
                                        defaultValue={values.countryCode}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );

                                            setIsDirty(true);
                                        }}
                                        onKeyDown={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                            setIsDirty(true);
                                        }}
                                        onSearchChange={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                            setIsDirty(true);
                                        }}
                                        onKeyUp={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                            setIsDirty(true);
                                        }}
                                        options={countryOptions}
                                        pointing="top"
                                    />
                                </div>
                                {countryError ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {'Country is required'}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-start-1 col-span-1 lg:col-end-5 lg:col-span-1 mt-4">
                                <div className="flex flex-wrap flex-col relative">
                                    <select
                                        className="p-4 lg:p-3  rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500 h-14"
                                        defaultValue={code?.dial_value}
                                        disabled
                                        id="code"
                                        value={
                                            selectedCode
                                                ? selectedCode[0]?.dial_value
                                                : code?.dial_value
                                        }
                                    >
                                        {countryOptions.map((country: any, index: any) => {
                                            return (
                                                <option value={country.dial_value} key={index}>
                                                    {country.dial_value}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className=" col-start-3 col-span-4  lg:col-end-7 lg:col-span-2 mt-2">
                                <InputField
                                    readonly={!edit ? true : false}
                                    placeHolder="777125043"
                                    id="phoneNumber"
                                    labelWidth="w-52"
                                    name="Contact number"
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                    }}
                                    type="tel"
                                    value={values.phoneNumber}
                                />
                                {errors.phoneNumber && touched.phoneNumber ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.phoneNumber}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="mt-16">
                            <div className="flex flex-col">
                                <div className="font-poppins text-xl font-bold">Notification</div>
                                <div className="col-start-1 col-end-4 mt-2">
                                    <input
                                        type="checkbox"
                                        id="isOutOfStockEmailEnable"
                                        disabled={!edit}
                                        checked={isOutOfStockEmailEnable}
                                        onChange={handleOutOfStockCheckboxChange}
                                        className={` ${'checkbox'} mt-2`}
                                    />
                                    <label
                                        htmlFor="checkbox"
                                        className="font-poppins text-l text-gray-500"
                                    >
                                        Receive out of stock email notifications
                                    </label>
                                </div>
                                <div className="col-start-1 col-end-4 mt-2">
                                    <input
                                        type="checkbox"
                                        id="isRunningOutOfStockEmailEnable"
                                        disabled={!edit}
                                        checked={isRunningOutOfStockEmailEnable}
                                        onChange={(event) =>
                                            setIsRunningOutOfStockEmailEnable(event.target.checked)
                                        }
                                        className={` ${'checkbox'} mt-2`}
                                    />
                                    <label
                                        htmlFor="checkbox"
                                        className="font-poppins text-l text-gray-500"
                                    >
                                        Receive running out of stock email notifications
                                    </label>
                                </div>
                                {/* <div className="col-start-1 col-end-4 mt-2">
                                    <input
                                        type="checkbox"
                                        id="isLowStockEmailEnable"
                                        disabled={true}
                                        checked={false}
                                        onChange={}
                                        className={` ${'checkbox'}`}
                                    />
                                    <label
                                        htmlFor="checkbox"
                                        className="font-poppins text-l text-gray-500"
                                    >
                                        Receive low stock email notifications
                                    </label>
                                </div> */}
                            </div>

                            <div className="flex flex-col mt-16">
                                <div className="font-poppins text-xl font-bold">
                                    Account management
                                </div>

                                <div className="mt-3 flex">
                                    <div className="text-gray-400 text-lg flex">
                                        Your current admin email address is
                                        <div
                                            className="mx-2 underline text-purple-500 "
                                            id="current_email"
                                        >
                                            {currentUser?.contact?.primaryEmail
                                                ? currentUser?.contact?.primaryEmail
                                                : clickedUser}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <div>
                                        <Buttons
                                            name="Change email address"
                                            type="button"
                                            buttonType="secondary-main"
                                            id="Change-email-address"
                                            size="small"
                                            onclick={() => setShowEmailModal(true)}
                                        />
                                        <ChangeEmailModal
                                            showEmailModal={showEmailModal}
                                            setShowEmailModal={setShowEmailModal}
                                            setShowVerifyModal={setShowVerifyModal}
                                        />
                                        <VerifyEmailAddressRequestModal
                                            showVerifyModal={showVerifyModal}
                                            setShowVerifyModal={setShowVerifyModal}
                                        />
                                        {verificationNotification &&
                                        isRegistered !== 'NOT-REGISTERED'
                                            ? VerifyEmailReminder(
                                                  currentUser?.contact?.primaryEmail,
                                              )
                                            : null}
                                    </div>
                                </div>

                                {!SWAR_MVP_HIDE_FEATURE && (
                                    <>
                                        <div className="mt-6 flex">
                                            <div className="text-gray-400 text-lg flex">
                                                Invite new user
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                            <Buttons
                                                name="Invite-new-user"
                                                type="button"
                                                buttonType="secondary-main"
                                                id="Invite-new-user"
                                                size="small"
                                                onclick={handleInviteUser}
                                            />
                                        </div>
                                    </>
                                )}
                                <div className="mt-10 flex">
                                    <div className=" font-poppins text-xl font-bold ">Security</div>
                                </div>

                                <div className="mt-6">
                                    <div>
                                        <Buttons
                                            name="Change password"
                                            type="button"
                                            buttonType="secondary-main"
                                            id="change-password"
                                            size="small"
                                            onclick={() => {
                                                setShowValidatePasswordModal(true);
                                            }}
                                        />
                                        <ChangePasswordModel
                                            showModal={showModal}
                                            setShowModal={setShowModal}
                                            setPasswordUpdateSuccess={setPasswordUpdateSuccess}
                                        />
                                        <ValidateOldPassword
                                            showValidateModal={showValidatePasswordModal}
                                            setShowValidateModal={setShowValidatePasswordModal}
                                            setShowChangePasswordModal={setShowModal}
                                        />
                                    </div>
                                </div>
                            </div>
                            {!SWAR_MVP_HIDE_FEATURE && (
                                <div className="flex border-b-2 border-gray-300">
                                    <div className="w-18 h-6 mt-16 mx-10">
                                        <CustomizedSwitches />
                                    </div>

                                    <div className="mx-16 font-poppins mt-5">
                                        <div className="text-sm font-bold mb-3 mt-5">
                                            Two - step authentication
                                        </div>

                                        <div className="w-2/3 text-xs text-gray-400 mb-10">
                                            Add an additional layer of security to your account to
                                            protect your data. When you sign-in you will need to
                                            enter a security code to verify your identity.
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="mt-10 pb-5 border-b-2 border-gray-300">
                                <div className="mb-4 font-poppins text-black font-semibold flex justify-between">
                                    <div className="-mt-3 ml-2"> Signed in devices</div>
                                    <div
                                        className="pr-10"
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => setLoginDrop(!loginDrop)}
                                        onKeyDown={() => setLoginDrop(!loginDrop)}
                                    >
                                        <Images
                                            height="h-2 hidden md:block"
                                            width="w-3"
                                            src="/images/icons/dropdown.png"
                                            alt="Dashboard"
                                        />
                                    </div>
                                </div>
                                {loginDrop ? (
                                    <div>
                                        {loginSessionResult?.length ? (
                                            <>
                                                {loginSessionResult?.map((session) => {
                                                    return (
                                                        <>
                                                            <LoginSession
                                                                ip={session?.ip}
                                                                country={session?.country}
                                                                created={session?.created}
                                                                devices={session?.devices}
                                                                deviceType={session?.deviceType}
                                                                id={session?.id}
                                                                isCurrent={session?.isCurrent}
                                                                setLogout={setLogoutFromSession}
                                                                setSelectedSession={
                                                                    setSelectedSession
                                                                }
                                                            />
                                                        </>
                                                    );
                                                })}
                                            </>
                                        ) : null}
                                    </div>
                                ) : null}
                            </div>
                            <div className="mt-10 pb-5 border-b-2 border-gray-300">
                                <div className="mb-4 font-poppins text-black font-semibold flex justify-between">
                                    <div className="-mt-3 ml-2"> Activity log</div>
                                    <div
                                        className="pr-10"
                                        role="button"
                                        id="activity_drop"
                                        tabIndex={0}
                                        onClick={() => setActivityLog(!activityLog)}
                                        onKeyDown={() => setActivityLog(!activityLog)}
                                    >
                                        <Images
                                            height="h-2 hidden md:block"
                                            width="w-3"
                                            src="/images/icons/dropdown.png"
                                            alt="Dashboard"
                                        />
                                    </div>
                                </div>
                                {activityLog ? (
                                    <div className="mr-8">
                                        <ActivityLogTable
                                            username={tokenDetails?.preferred_username}
                                        />
                                    </div>
                                ) : null}
                            </div>

                            <div
                                className={`flex flex-row mt-20 bg-gray-200 p-4 pr-10 -ml-6 justify-end ${
                                    !edit ? 'hidden' : 'block'
                                } `}
                            >
                                <Buttons
                                    name="Cancel"
                                    type="button"
                                    buttonType="secondary-border-black"
                                    id="Cancel"
                                    size="e-small"
                                    other="mr-3"
                                    onclick={() => {
                                        localStorage.setItem('is_first_login', 'false');
                                        window.location.reload();
                                    }}
                                />
                                <Buttons
                                    name="Update"
                                    type="submit"
                                    buttonType="primary"
                                    id="update"
                                    size="e-small"
                                    onclick={() => {
                                        setIsUpdate(true);
                                        handleSubmit();
                                    }}
                                />
                            </div>
                            <UnsaveChangesWarning
                                contentText={PRODUCT_ADD_EXIT_CONFIRM_MESSAGE}
                                messageTitle={ADMIN_PROFILE_UNSAVED_CHANGES_TITLE}
                                when={isDirty}
                                navigate={(path) => history.push(path)}
                                shouldBlockNavigation={() => {
                                    if (isDirty) {
                                        return true;
                                    }
                                    return false;
                                }}
                                displayIcon={true}
                                displayIconName={'save-svg.svg'}
                            />
                        </div>
                    </>
                )}
            </Formik>
        </>
    );
}
