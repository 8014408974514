/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { pagination } from '../../../constants/config/constants';
import * as BsIcons from 'react-icons/bs';
import { FETCH_PARTNER_DETAILS } from '../../../queries/PartnerQueries';
import DataTable from '../../ui/organisms/DataTable';
import { Buttons } from '../../ui/atoms/Button';
import SearchBar from '../../ui/molecules/SearchBar';
import {
    DELETE_PARTNER_BODY,
    DELETE_PARTNER_TITLE,
    getPartnerTrashedSuccessMessage,
    INSTANT_EDIT_OPTION_EDIT_PARTNER,
    INSTANT_EDIT_OPTION_TRASH_PARTNER,
    INSTANT_EDIT_OPTION_VIEW_PARTNER,
} from '../../../constants/partner';
import { useHistory } from 'react-router-dom';
import {
    NOT_REGISTERED,
    NOT_REGISTERED_WARNING_BODY,
    NOT_REGISTERED_WARNING_TITLE,
} from '../../../constants/profile';
import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import { countryOptions } from '../../../utils/countries';
import TabViewWithCounts from '../../ui/organisms/TabViewWithCounts';
import ConfirmationModalWithMessage from '../../templates/modals/ConfirmationModalWithMessage';
import { UPDATE_ADMIN_PROFILE } from '../../../queries/AdminProfileQueries';
import Toast from '../../ui/atoms/Toast';
import Loader from '../../../utils/loader';
import { currencyAndCountryCodes } from '../../../utils/currencies';

export default function PartnerListScreen() {
    const history = useHistory();
    const [partnersData, setPartnerData] = useState([]);
    const [partnerInitialData, setPartnersInitialData] = useState([]);
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const [clickedPartner, setClickedPartner] = useState(null);
    const [instantEditMenuItems, setInstantEditMenuItems] = useState([]);
    const [selectedPartners, setSelectedPartners] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);
    const [pageSize, setPageSize] = useState(pagination?.pageSize);
    const [filteredData, setFilteredData] = useState([]);
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [warningModalBody, setWarningModalBody] = useState('');
    const [warningModalTitle, setWarningModalTitle] = useState('');
    const [warningModalOkayClicked, setWarningModalOkayClicked] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTab, setSelectedTab] = useState(1);
    const [tabItemsList, setTabItemsList] = useState([
        {
            text: 'All',
            count: 0,
            tabId: 1,
        },
        {
            text: 'Active',
            count: 0,
            tabId: 2,
        },
        {
            text: 'Inactive',
            count: 0,
            tabId: 3,
        },
        {
            text: 'Trashed',
            count: 0,
            tabId: 4,
        },
    ]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [confirmationMessageTitle, setConfirmationMessageTitle] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const { loading: partnerDataLoading, error: partnerDataFetchError } = useQuery(
        FETCH_PARTNER_DETAILS,
        {
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                setPartnerData(data?.partners);
                setPartnersInitialData(data?.partners);

                const allPartnerCount = data?.partners?.filter(
                    (partner) => !partner?.isDeleted,
                )?.length;
                const tabList = [];

                const deletedCount = data?.partners?.filter(
                    (partner) => !partner?.isActive && partner?.isDeleted,
                )?.length;

                const activeCount = data?.partners?.filter((partner) => partner?.isActive)?.length;
                const inActiveCount = data?.partners?.filter(
                    (partner) => !partner?.isActive && !partner?.isDeleted,
                )?.length;

                tabList?.push({
                    text: 'All',
                    count: allPartnerCount || 0,
                    tabId: 1,
                });

                tabList?.push({
                    text: 'Active',
                    count: activeCount || 0,
                    tabId: 2,
                });

                tabList?.push({
                    text: 'Inactive',
                    count: inActiveCount || 0,
                    tabId: 3,
                });

                tabList?.push({
                    text: 'Trashed',
                    count: deletedCount || 0,
                    tabId: 4,
                });
                setTabItemsList(tabList);
            },
        },
    );

    const [savePartner] = useMutation(UPDATE_ADMIN_PROFILE, {
        refetchQueries: [FETCH_PARTNER_DETAILS],
        onCompleted(data) {
            setShowConfirmationModal(false);
            setMessage(getPartnerTrashedSuccessMessage(data?.updateUser?.partner?.partnerName));
            setError(false);
            setShowToast(true);
        },
        onError(error) {
            setShowConfirmationModal(false);
            setMessage(getPartnerTrashedSuccessMessage(error?.message));
            setError(true);
            setShowToast(true);
        },
    });

    useEffect(() => {
        if (searchTerm?.length) {
            setSearchClicked(true);
        } else {
            setSearchClicked(false);
        }
    }, [searchTerm?.length]);

    useEffect(() => {
        // const noOfPages = Math.ceil(partnerInitialData?.length / pageSize);
        // const pageIndexFilteredData = partnerInitialData.slice(
        //     pageIndex * pageSize,
        //     pageIndex * pageSize + pageSize,
        // );
        // setPageCount(noOfPages);  //future need
        setFilteredData(partnerInitialData);
    }, [partnerInitialData]);

    useEffect(() => {
        if (searchTerm?.length > 0) {
            setSearchClicked(true);
            const partnerFilteredData = filterPatnerData();
            const searchResults = partnerFilteredData?.filter((data) => {
                return (
                    data?.partnerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    data?.partnerId.includes(searchTerm.toLowerCase())
                );
            });

            if (searchResults?.length) {
                setPartnerData(searchResults);
            } else if (!searchResults?.length) {
                setPartnerData(null);
            }
        } else {
            setPartnerData(filterPatnerData());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredData, partnerInitialData, searchTerm, setSearchClicked, selectedTab]);

    useEffect(() => {
        const instantMenuList = [];

        instantMenuList.push(
            {
                text: 'Edit',
                value: INSTANT_EDIT_OPTION_EDIT_PARTNER,
            },
            {
                text: 'View',
                value: INSTANT_EDIT_OPTION_VIEW_PARTNER,
            },
        );

        if (selectedTab !== 4) {
            instantMenuList.push({
                text: 'Trash',
                value: INSTANT_EDIT_OPTION_TRASH_PARTNER,
            });
        }

        setInstantEditMenuItems(instantMenuList);
    }, [setInstantEditMenuItems, selectedTab]);

    const isRegistered = localStorage.getItem('isRegistered');

    useEffect(() => {
        if (warningModalOkayClicked) {
            history.push('/adminProfile');
        }
    }, [history, warningModalOkayClicked]);

    useEffect(() => {
        if (isRegistered === NOT_REGISTERED) {
            setWarningModalBody(NOT_REGISTERED_WARNING_BODY);
            setWarningModalTitle(NOT_REGISTERED_WARNING_TITLE);
            setShowWarningModal(true);
        }
    }, [isRegistered]);

    useEffect(() => {
        if (selectedMenuItem === INSTANT_EDIT_OPTION_EDIT_PARTNER && clickedPartner) {
            history.push({
                pathname: '/partner/general-details-edit',
            });
            localStorage.setItem('partnerId', clickedPartner?.id);
            localStorage.setItem('partnerUUID', clickedPartner?.partnerId);
            localStorage.setItem('partnerName', clickedPartner?.partnerName);
            localStorage.setItem('catalogId', clickedPartner?.catalog?.catalogId);
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_VIEW_PARTNER && clickedPartner) {
            history.push({
                pathname: 'partner/general-details-view',
            });
            localStorage.setItem('partnerId', clickedPartner?.id);
            localStorage.setItem('partnerUUID', clickedPartner?.partnerId);
            localStorage.setItem('partnerName', clickedPartner?.partnerName);
            localStorage.setItem('catalogId', clickedPartner?.catalog?.catalogId);
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_TRASH_PARTNER && clickedPartner) {
            setSelectedMenuItem('');
            setConfirmationMessageTitle(DELETE_PARTNER_TITLE);
            setConfirmationMessage(DELETE_PARTNER_BODY);
            setShowConfirmationModal(true);
        }
    }, [selectedMenuItem, history, clickedPartner]);

    useEffect(() => {
        if (searchTerm.length === 0) {
            setPartnerData(filterPatnerData());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab]);

    const filterPatnerData = () => {
        if (selectedTab == 1) {
            return partnerInitialData?.filter((partner) => !partner?.isDeleted);
        } else if (selectedTab === 2) {
            return partnerInitialData?.filter((partner) => partner?.isActive);
        } else if (selectedTab === 3) {
            return partnerInitialData?.filter(
                (partner) => !partner?.isActive && !partner?.isDeleted,
            );
        } else {
            return partnerInitialData?.filter(
                (partner) => !partner?.isActive && partner?.isDeleted,
            );
        }
    };

    const partnerIds = filterPatnerData()?.map((partner) => partner?.partnerId);

    const partnerNames = filterPatnerData()?.map((partner) => partner?.partnerName);

    const partnerDataArray = [...partnerNames, ...partnerIds];

    const deletePartner = (partnerData) => {
        savePartner({
            variables: {
                userId: partnerData?.users[0]?.userId,
                userName: partnerData?.users[0]?.userName,
                user: {
                    firstName: partnerData?.users[0]?.firstName,
                    lastName: partnerData?.users[0]?.lastName,
                    contact: {
                        contactId: partnerData?.users[0]?.contact?.contactId,
                        primaryEmail: partnerData?.users[0]?.contact?.primaryEmail,
                        countryCode: partnerData?.users[0]?.contact?.countryCode,
                        phoneNumber: partnerData?.users[0]?.contact?.phoneNumber,
                    },
                    partner: {
                        id: partnerData?.id,
                        partnerId: partnerData?.partnerId,
                        partnerName: partnerData?.partnerName,
                        isActive: false,
                        isDeleted: true,
                    },
                },
            },
        });
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'partnerId',
            },
            {
                Header: 'Partner name ',
                accessor: 'partnerName',
            },

            {
                Header: 'Country/Region',
                accessor: 'users',
                idDisabled: true,
                Cell: function points({ value }) {
                    if (value?.length) {
                        const country = countryOptions?.find(
                            (country) => country?.value === value[0]?.contact?.countryCode,
                        );
                        return <span>{country?.text}</span>;
                    }
                },
            },
            {
                Header: 'Local currency',
                accessor: 'localCurrency',
                Cell: function localCurrency({ value }) {
                    if (!value) return null;
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <span>{value}</span>
                        </div>
                    );
                },
            },
            {
                Header: 'URL',
                idDisabled: true,
                accessor: 'partnerUrl',
            },
            {
                Header: 'Status',
                accessor: 'isActive',
                Cell: function date({ value }) {
                    const status = value ? 'Active' : 'Inactive';
                    return (
                        <span
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                status === 'Active'
                                    ? 'bg-green-100 text-green-600'
                                    : status === 'Inactive'
                                    ? 'bg-red-100 text-red-600'
                                    : ''
                            }`}
                        >
                            {status}
                        </span>
                    );
                },
            },
            {
                Header: 'Created date',
                idDisabled: true,
                accessor: 'createdDate',
                Cell: function date({ value }) {
                    return <span>{value?.slice(0, 16) + '(UTC)'}</span>;
                },
            },
            {
                id: 'actionColumn',
                accessor: '',
                disableSortBy: true,
                width: 5,
                Cell: (
                    <div className="cursor__pointer">
                        <button
                            value="menu cursor-pointer"
                            className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none lg:ml-2"
                        >
                            <BsIcons.BsThreeDots className="mx-auto" />
                        </button>
                    </div>
                ),
            },
        ],
        [],
    );

    return (
        <div className="border-2 border-gray-300 rounded-lg mr-7 mb-10">
            <ConfirmationModalWithMessage
                showConfirmationModal={showConfirmationModal}
                setShowConfirmationModal={setShowConfirmationModal}
                message={confirmationMessage}
                messageTitle={confirmationMessageTitle}
                setYesBtnClick={() => deletePartner(clickedPartner)}
                setNoBtnClick={() => {
                    setShowConfirmationModal(false);
                    setSelectedMenuItem('');
                }}
            />
            <WarningMessageModal
                showWarningModal={showWarningModal}
                setShowWarningModal={setShowWarningModal}
                warningModalBody={warningModalBody}
                warningModalTitle={warningModalTitle}
                setProcessFinishedClicked={setWarningModalOkayClicked}
            />
            {showToast && (
                <Toast
                    setShowToast={setShowToast}
                    message={message}
                    error={error}
                    margin="ml-1"
                    width="w-10/12"
                    selfDestruct={true}
                    selfDestructTimer={2000}
                />
            )}
            <div className={`w-full ml-4 pt-4`}>
                {tabItemsList?.length ? (
                    <TabViewWithCounts
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        tabItemsList={tabItemsList}
                    />
                ) : null}
            </div>
            <div className="flex p-4">
                <div className="flex w-full">
                    <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3">
                        <SearchBar
                            id="partner-list-search-field"
                            options={partnerDataArray}
                            handleChange={(e) => handleChange(e)}
                            placeHolder={'Search partner by name or id'}
                        />
                    </div>
                </div>

                <Buttons
                    name="Add new partner"
                    type="submit"
                    buttonType="primary"
                    id="add-new-partner-button"
                    size="e-small"
                    padding="py-2"
                    onclick={() => {
                        history.push('/partner/general-details-add');
                    }}
                />
            </div>
            {partnerDataLoading ? (
                <div className="w-full">
                    <div className="m-auto flex flex-col ">
                        <div className="m-auto p-10">
                            <Loader />
                        </div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                </div>
            ) : (
                <div className="w-full">
                    {partnersData?.length ? (
                        <DataTable
                            columns={columns}
                            data={partnersData}
                            pageCount={pageCount}
                            setSelectedItems={setSelectedPartners}
                            setPageIndex={setPageIndex}
                            menuItems={instantEditMenuItems}
                            setExposeMenuClickedItem={setClickedPartner}
                            setDefaultPageSize={setPageSize}
                            setSelectedMenuItem={setSelectedMenuItem}
                            checkbox={false}
                            isInitialLoad={isInitialLoad}
                            setIsInitialLoad={setIsInitialLoad}
                        />
                    ) : searchClicked && !partnersData?.length ? (
                        <div className="m-auto flex flex-col ">
                            <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                {columns?.map((column) => {
                                    return (
                                        <div
                                            className="text-black font-poppins font-semibold flex ml-12"
                                            key={column.id}
                                        >
                                            {column?.Header}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="m-auto p-10 flex">
                                Your search
                                <div className="text-purple-500 mx-2">{`"${searchTerm}"`}</div>
                                did not match any results
                            </div>
                            <div className="p-10 bg-purple-100"></div>
                        </div>
                    ) : (
                        <div className="m-auto flex flex-col ">
                            <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                {columns?.map((column, i) => {
                                    return (
                                        <div
                                            className="text-gray-600 font-poppins font-semibold flex ml-12"
                                            key={i}
                                        >
                                            {column?.Header}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="m-auto p-10">No data found.</div>
                            <div className="p-10 bg-purple-100"></div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
