import { awsSDKConfig, awsSDKS3Params } from '../constants/config/constants';
import AWS from 'aws-sdk';

export function listAllS3Objects(location) {
    const bucket = new AWS.S3(awsSDKConfig);
    const params = {
        Bucket: awsSDKS3Params?.params?.Bucket,
    };
    const headParams = {
        Bucket: awsSDKS3Params?.params?.Bucket,
        Key: '',
    };
    try {
        const result = [];
        bucket.listObjectsV2(params, function (err, data) {
            if (err) {
                console.error(err, err.stack);
            } else {
                const contents = data.Contents;
                contents.forEach(function (content) {
                    if (content.Key.includes(location)) {
                        headParams['Key'] = content.Key;
                        bucket.headObject(headParams, function (err, headObjectData) {
                            if (err) {
                                console.error(err, err.stack);
                            } else {
                                const fileData = {
                                    fileName: content.Key,
                                    fileSize: content.Size,
                                    fileType: headObjectData.ContentType,
                                };
                                result.push(fileData);
                            }
                        });
                    }
                });
            }
        });
        return result;
    } catch (error) {
        return null;
    }
}
